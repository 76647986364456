/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../core/directives/scroll-top.directive";
import * as i2 from "./scroll-top.component";
var styles_ScrollTopComponent = [".m-scroll-top[_ngcontent-%COMP%]:hover > i[_ngcontent-%COMP%]{\n\tcolor: white !important;\n}"];
var RenderType_ScrollTopComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ScrollTopComponent, data: {} });
export { RenderType_ScrollTopComponent as RenderType_ScrollTopComponent };
export function View_ScrollTopComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "m-scroll-top"], ["id", "m_scroll_top"], ["mScrollTop", ""], ["style", "background-color: #FA1339"]], null, null, null, null, null)), i0.ɵdid(1, 4341760, null, 0, i1.ScrollTopDirective, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["class", "la la-arrow-up"]], null, null, null, null, null))], null, null); }
export function View_ScrollTopComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "m-scroll-top", [], null, null, null, View_ScrollTopComponent_0, RenderType_ScrollTopComponent)), i0.ɵdid(1, 114688, null, 0, i2.ScrollTopComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScrollTopComponentNgFactory = i0.ɵccf("m-scroll-top", i2.ScrollTopComponent, View_ScrollTopComponent_Host_0, {}, {}, []);
export { ScrollTopComponentNgFactory as ScrollTopComponentNgFactory };
