var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';
import { UserService } from './user.service';
import { AuthenticationService } from '../auth/authentication.service';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./user.service";
import * as i2 from "@angular/common/http";
import * as i3 from "../auth/authentication.service";
var CompanyService = /** @class */ (function () {
    function CompanyService(userService, http, authService) {
        this.userService = userService;
        this.http = http;
        this.authService = authService;
        this.messageSource = new BehaviorSubject('error');
        this.response = new BehaviorSubject('default message');
        this.currentMessage = this.messageSource.asObservable();
        this.catchResponse = this.response.asObservable();
    }
    CompanyService.prototype.catchData = function (position) {
        this.messageSource.next(position);
    };
    CompanyService.prototype.refreshTable = function (data) {
        this.response.next(data);
    };
    // profileCompany(){
    //   return this.http.get(`${environment.apiUrl}/`);
    // }
    CompanyService.prototype.createCompany = function (data) {
        return this.http.post(environment.apiUrl + "/app/company", __assign({}, data));
    };
    CompanyService.prototype.brachList = function () {
        return this.http.get(environment.apiUrl + "/branches/list/all");
    };
    CompanyService.prototype.branchListDetails = function () {
        return this.http.get(environment.apiUrl + "/branches?filterColumn=name&filterValue=&page=1&pageSize=60&sortField=name&sortOrder=asc");
    };
    CompanyService.prototype.positionList = function () {
        return this.http.get(environment.apiUrl + "/position/list/all");
    };
    CompanyService.prototype.positionById = function (id) {
        return this.http.get(environment.apiUrl + "/position/" + id);
    };
    CompanyService.prototype.createPosition = function (data) {
        var user = this.userService.me;
        // return  console.log(user);
        return this.http.post(environment.apiUrl + "/position", __assign({}, data, { status: 'A', it_business_id: user.company[0].id }));
    };
    CompanyService.prototype.updatePosition = function (id, data) {
        var user = this.userService.me;
        // return console.log(user);
        return this.http.put(environment.apiUrl + "/position/" + id, __assign({}, data, { status: 'A', it_business_id: user.company[0].id }));
    };
    CompanyService.prototype.updateCompany = function (id, data) {
        return this.http.post(environment.apiUrl + "/company/" + id + "?_method=PUT", data);
    };
    CompanyService.prototype.getTermsAndContidtios = function () {
        return this.http.get(environment.apiUrl + "/terms/get/active");
    };
    CompanyService.prototype.getProfiles = function () {
        return this.http.get(environment.apiUrl + "/profile/list/all");
    };
    CompanyService.prototype.removePosition = function (id) {
        return this.http.delete(environment.apiUrl + "/position/" + id);
    };
    CompanyService.prototype.removeBranch = function (id) {
        return this.http.delete(environment.apiUrl + "/branches/" + id);
    };
    CompanyService.ngInjectableDef = i0.defineInjectable({ factory: function CompanyService_Factory() { return new CompanyService(i0.inject(i1.UserService), i0.inject(i2.HttpClient), i0.inject(i3.AuthenticationService)); }, token: CompanyService, providedIn: "root" });
    return CompanyService;
}());
export { CompanyService };
