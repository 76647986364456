import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';
import { UserService } from './user.service';
import { AuthenticationService } from '../auth/authentication.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private userService: UserService,
    private http: HttpClient,
    private authService: AuthenticationService,
  ) { }

  public messageSource = new BehaviorSubject('error');
  private response = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();
  catchResponse = this.response.asObservable();

  catchData(position: any) {
    this.messageSource.next(position);
  }

  refreshTable(data) {
    this.response.next(data);
  }

  // profileCompany(){
  //   return this.http.get(`${environment.apiUrl}/`);
  // }

  createCompany(data){
    return this.http.post(`${environment.apiUrl}/app/company`, {
      ...data,
    })
  }

  brachList() {
    return this.http.get(`${environment.apiUrl}/branches/list/all`);
  }

  branchListDetails(){
    return this.http.get(`${environment.apiUrl}/branches?filterColumn=name&filterValue=&page=1&pageSize=60&sortField=name&sortOrder=asc`);    
  }

  positionList(){
    return this.http.get(`${environment.apiUrl}/position/list/all`);
  }

  positionById(id){
    return this.http.get(`${environment.apiUrl}/position/${id}`);
  }

  createPosition(data) {
    const user = this.userService.me;
    // return  console.log(user);
    return this.http.post(`${environment.apiUrl}/position`, {
      ...data,
      status: 'A',
      it_business_id: user.company[0].id
    });
  }

  updatePosition(id, data) {
    const user = this.userService.me;
    // return console.log(user);
    return this.http.put(`${environment.apiUrl}/position/${id}`, {
      ...data,
      status: 'A',
      it_business_id: user.company[0].id
    });
  }

  updateCompany(id, data) {
    return this.http.post(`${environment.apiUrl}/company/${id}?_method=PUT`, data);
  }

  getTermsAndContidtios() {
    return this.http.get(`${environment.apiUrl}/terms/get/active`);
  }

  getProfiles() {
    return this.http.get(`${environment.apiUrl}/profile/list/all`);
  }

  removePosition(id) {
    return this.http.delete(`${environment.apiUrl}/position/${id}`)
  }
  
  removeBranch(id) {
    return this.http.delete(`${environment.apiUrl}/branches/${id}`)
  }
}

