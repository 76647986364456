/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./search-dropdown.component";
import * as i2 from "../../../../../core/services/layout-config.service";
import * as i3 from "../../../../../core/services/quick-search.service";
var styles_SearchDropdownComponent = [];
var RenderType_SearchDropdownComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SearchDropdownComponent, data: {} });
export { RenderType_SearchDropdownComponent as RenderType_SearchDropdownComponent };
export function View_SearchDropdownComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_SearchDropdownComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "m-search-dropdown", [], [[8, "className", 0], [8, "id", 0], [1, "m-dropdown-toggle", 0], [1, "m-dropdown-persistent", 0], [1, "m-quicksearch-mode", 0], [1, "mQuickSearch", 0]], null, null, View_SearchDropdownComponent_0, RenderType_SearchDropdownComponent)), i0.ɵdid(1, 4440064, null, 0, i1.SearchDropdownComponent, [i2.LayoutConfigService, i0.ElementRef, i3.QuickSearchService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).classes; var currVal_1 = i0.ɵnov(_v, 1).id; var currVal_2 = i0.ɵnov(_v, 1).attrDropdownToggle; var currVal_3 = i0.ɵnov(_v, 1).attrDropdownPersistent; var currVal_4 = i0.ɵnov(_v, 1).attrQuicksearchMode; var currVal_5 = i0.ɵnov(_v, 1).mQuickSearchDirective; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
var SearchDropdownComponentNgFactory = i0.ɵccf("m-search-dropdown", i1.SearchDropdownComponent, View_SearchDropdownComponent_Host_0, {}, {}, []);
export { SearchDropdownComponentNgFactory as SearchDropdownComponentNgFactory };
