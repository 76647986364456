import { Routes } from "@angular/router";
import { NgxPermissionsGuard } from "ngx-permissions";
import { CodeComponent } from "./content/pages/auth/code/code.component";
import { ForgotPasswordComponent } from "./content/pages/auth/forgot-password/forgot-password.component";
import { RegisterComponent } from "./content/pages/auth/register/register.component";
import { PagesComponent } from "./content/pages/pages.component";
import { ErrorPageComponent } from "./content/pages/snippets/error-page/error-page.component";
var ɵ0 = {
    permissions: {
        only: ["ADMIN", "USER"],
        except: ["GUEST"],
        redirectTo: "/login"
    }
}, ɵ1 = {
    permissions: {
        except: ["ADMIN", "USER"],
        redirectTo: "/"
    }
}, ɵ2 = {
    permissions: {
        except: ["ADMIN", "USER"],
        redirectTo: "/"
    }
};
var routes = [
    {
        path: "",
        component: PagesComponent,
        // Remove comment to enable login
        canActivate: [NgxPermissionsGuard],
        data: ɵ0,
        children: [
            {
                path: "",
                loadChildren: "./content/pages/components/dashboard/dashboard.module#DashboardModule"
            },
            {
                path: "users",
                loadChildren: "./content/pages/users/users.module#UsersModule"
            },
            {
                path: "news",
                loadChildren: "./content/pages/news/news.module#NewsModule"
            },
            {
                path: "surveys",
                loadChildren: "./content/pages/surveys/surveys.module#SurveysModule"
            },
            {
                path: "capacitation",
                loadChildren: "./content/pages/capacitation/capacitation.module#NewsModule"
            },
            {
                path: "company",
                loadChildren: "./content/pages/company/company.module#CompanyModule"
            },
            {
                path: "speakers",
                loadChildren: "./content/pages/speakers/speakers.module#SpeakersModule"
            },
            {
                path: "schedule",
                loadChildren: "./content/pages/schedule/speakers.module#SpeakersModule"
            },
            {
                path: "events",
                loadChildren: "./content/pages/events/speakers.module#SpeakersModule"
            },
            {
                path: "materials",
                loadChildren: "./content/pages/materials/speakers.module#SpeakersModule"
            },
            {
                path: "notification",
                loadChildren: "./content/pages/notification/notification.module#NotificationModule"
            },
            {
                path: "profile",
                loadChildren: "./content/pages/header/profile/profile.module#ProfileModule"
            },
            {
                path: "community",
                loadChildren: "./content/pages/community/community.module#CommunityModule"
            }
        ]
    },
    {
        path: "login",
        canActivate: [NgxPermissionsGuard],
        loadChildren: "./content/pages/auth/auth.module#AuthModule",
        data: ɵ1
    },
    {
        path: "signup",
        component: RegisterComponent,
        canActivate: [NgxPermissionsGuard],
        data: ɵ2
    },
    {
        path: "forgot-password",
        canActivate: [NgxPermissionsGuard],
        component: ForgotPasswordComponent
    },
    {
        path: "code/:mail",
        canActivate: [NgxPermissionsGuard],
        component: CodeComponent
    },
    {
        path: "404",
        component: ErrorPageComponent
    },
    {
        path: "error/:type",
        component: ErrorPageComponent
    }
];
var PagesRoutingModule = /** @class */ (function () {
    function PagesRoutingModule() {
    }
    return PagesRoutingModule;
}());
export { PagesRoutingModule };
export { ɵ0, ɵ1, ɵ2 };
