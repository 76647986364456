// USA
export const locale = {
	lang: 'en',
	data: {
		ACCOUNT: {
			SUSPENDED: 'Your account was blocked because you do not have an active membership',
			PAYMENT_ALERT: 'Your account is close to being suspended please make the payment',
			DEMO_ALERT: 'Your account is close to being suspended please make the payment',
		},
		POSITIONS: {
			CREATE_LINK: 'Create position',
			SELECT_PLACEHOLDER: 'Select a position',
		},
		BRANCH: {
			CREATE_LINK: 'Create branch',
			SELECT_PLACEHOLDER: 'Select a branch'
		},
		PUSH: {
			GO_TO_SELECT: 'Select a option',
			EMPTY_DATATABLE: 'Here you will find the list of notifications sent',
			CREATE_LINK: 'send notification',
			SENT: 'Push sent',
			SEND_TO_ALL: 'Send to all',
			SEND_BUTTON: 'Push Notification',
			MESSAGE: 'Message',
			SENT_DATE: 'Sent date',
			SEND: 'Send Push',
			SEND_TO_SEGMENT: 'Send to a segment',
			TITLE_LABEL: 'Notification title',
			ON_CLICK: 'On click notification go to:',
			GROUPS_LABEL: 'Users Group',
			USER_POSITION: 'User Position',
			OS: 'Operative system',
			BRANCH_LABEL: 'Add Branch',
			USER_MULTISELECT: 'Select user multiselect',
			TEXT_NOTIFICATION: 'Text notification:',
		},
		COMMUNITY: {
			EMPTY: 'the publications made from the application will appear here',
			TIMELINE: 'Timeline Community',
		},
		GALLERY: {
			EMPTY_DATATABLE: 'your galleries will appear here, to create a gallery complete the form at the top of this page',
			FILES_REQUIRED: 'Gallery should have images',
			GALLERIES: 'Galleries',
			ASSOCIATE_TO: 'Associate to:',
			TITLE_LABEL: 'Title:',
			DESCRIPTION_LABEL: 'Description:',
			SELECT_IMAGES: 'Please select images',
			CREATED: 'Gallery created',
			UPDATED: 'Gallery updated',
		},
		FILEUPLOAD: {
			INVALID_FILE: '{0}: Invalid file type,',
			CHOOSE_LABEL: 'Choose',
			UPLOAD_LABEL: 'Upload',
			CANCEL_LABEL: 'Cancel',
		},
		LISTBOX: {
			FILTER_LABEL: 'Filter',
			MOVE_SELECTED: 'Move selected',
			MOVE_ALL: 'Move all',
			REMOVE_SELECTED: 'Remove selected',
			REMOVE_ALL: 'Remove all',
			INFO_TEXT: 'Showing all {0}',
			INFO_EMPTY: 'Empty list',
			FILTERED: '<span class="label label-warning">Filtered</span> {0} from {1}',
		},
		DATATABLE: {
			COLUMN: 'Column',
			COPY_SUCCESS_ONE: 'Information copied to the clipboard.',
			COPY_SUCCESS_TWO: '',
			COPY_keys: '',
			COPY_TITLE: 'Copied information',
			COPY: 'Copy',
			EMPTY: 'No data available in table',
			INFO: 'Showing _START_ to _END_ of _TOTAL_ entries',
			INFO_EMPTY: 'Showing 0 to 0 of 0 entries',
			INFO_FILTERED: '(filtered from _MAX_ total entries)',
			INFO_LENGTH: 'Show _MENU_ entries',
			NOT_FOUND: 'No matching records found',
			PRINT: 'Print',
		},
		ERRORS: {
			ERROR: 'Error',
			ERROR_CONNECTING: 'Error connecting to server',
			ERROR_MASIVE_CHARGE: 'Error in massive upload',
			VERIFY_FIELDS: 'Please verify the fields',
		},
		QUICK_ACTIONS: {
			CREATE_BRANCH: 'Create New Branch',
			ADD_NEW_USER: 'Add New User',
			CREATE_NEWS: 'Create Capacitation',
			ADD_GALLERY: 'Add New Gallery'
		},
		TRANSLATOR: {
			SELECT: 'Select your language',
		},
		MENU: {
			NEW: 'new',
			ACTIONS: 'Actions',
			CREATE_POST: 'Create New Post',
			CREATE_USER: 'Create New User',
			REPORTS: 'Reports',
			APPS: 'Apps',
			DASHBOARD: 'Dashboard',
			COMPANY: 'Company',
			SPEAKERS: 'Speakers',
			SCHEDULE: 'Speakers',
			EVENTS: 'Events',
			MATERIALS: 'Materials',
			USERS: 'Users',
			NEWS: 'Capacitations',
			COMMUNITY: 'Community',
			NOTIFICATIONS: 'Notifications',
			SUBMENUS: {
				NEW_USERS: 'Create User',
				NOTIFICATIONS: 'Notifications List',
				HOME: 'Home',
				CONTACT_LIST: 'Contacts list',
				MAP: 'Map',
				CONTACT_COMPANY: 'Contact Company/Position',
				MY_COMPANY: 'My Company',
				BRANCHES: 'Branches',
				COMPANY_POSITION: 'Company Position',
				SPEAKERS_LIST: 'Speakers List',
				SCHEDULE_LIST: 'Schedule List',
				EVENTS_LIST: 'Events List',
				MATERIALS_LIST: 'Materials List',
				USER_LIST: 'User list',
				USER_GROUP: 'User Group',
				CATEGORIES: 'Categories',
				NEW_LIST: 'Capacitations',
				GALLERIES: 'Galleries',
				NOTIFICATIONS_LIST: 'Notifications list',
				PUSH_NOTIFICATION: 'Push notifications',
				UPLOAD_USERS: 'Upload users'
			},
			HEADER: {
				MY_PROFILE: 'My profile',
				LOGOUT: 'Logout',
				QUICK_ACTIONS: 'Quick Actions',
				SHORTCUTS: 'Shortcuts',
				NEW_BRANCH: 'Create New Branch',
				ADD_USER: 'Add New User',
				CREATE_NEWS: 'Create Entry',
				ADD_GALLERY: 'Add New Gallery',
			}
		},
		BREADCRUMBS: {
			CONTACT_DTL: 'Contact details',
			CRUMB: {
				CONTACT: 'Contact',
			}
		},
		NEWS: {
			CATEGORY_DISABLED: 'This news will not be visible to users because the category is disabled',
			SELECT_PLACEHOLDER: 'select an entry',
			EMPTY_DATATABLE: 'Here you will find the list of published blog entries',
			CREATE_LINK: 'Create entry',
			TEXT_SHORT: 'Text short',
			LIST: 'Entries',
			CANT_COMMENTS: 'Comments',
			CANT_LIKES: 'Likes',
			VIEWS_CANT: 'Views',
			CREATE_BUTTON: 'Create entry',
			TITLE_PLACEHOLDER: 'Enter title of the entry',
			TITLE_LABEL: 'Title of the entry',
			PUBLICATION_DATE_PLACEHOLDER: 'Enter the publication date',
			PUBLICATION_DATE_LABEL: 'Publication date',
			CLOSE_DATE_LABEL: 'Close date',
			CLOSE_DATE_PLACEHOLDER: 'Enter close date',
			UPLOAD_COVER_LABEL: 'Upload cover photo',
			CATEGORY: 'Category',
			GROUP_TO_PUBLISH: 'Group to publish',
			ADD_BRANCH: 'Add Branch',
			ADD_POSITION: 'Add Position',
			SEND_NOTIFICATION_GROUP: 'Send notification to selected group',
			FULL_TEXT: 'Full text',
			CREATED: 'The capacitation was created',
			UPDATED: 'The capacitation was updated',
			GALLERY_CREATE:  'Create gallery',
			GALLERY_LISTING:  'Gallery Listing',
		},
		FILE_INPUT: {
			CHOOSE: 'Choose file...',
		},
		DASHBOARD: {
			CARDS: 'Cards',
			CONTACT_LIST: 'Top 10 Contact List',
			PRODUCTS: 'Recent Products',
			MAP: 'Map',
			CONTACT_COMPANY: 'Top 5 Contact company',
			CONTACT_POSITION: 'Top 5 Contact position',

			USERS: 'Users',
			CONTACTS: 'Contacts',
			NEWS: 'Capacitations',
			NEWS_LIKE: 'Interactions',


			LABELS: {
				CARDS: 'These statistics are generated from the cards sent from the app',
				MAP: 'Map of the last 10 cards sent',
				CONTACT_COMPANY: 'Cards sent by users',
				CONTACT_POSITION: 'Cards sent by users to position',

				USERS: 'With card',
				USERS_TWO: 'Without card',
				CONTACTS: 'With client, cards sent',
				NEWS: 'Hits',
				LIKE: 'Likes',
				COMMENTS: 'Comments',
			}
		},
		PROFILE_USER: {
			IMAGE_CHANGE: 'change image',
			MY_PROFILE: 'My Profile',
			TITLE: 'Personal Details',
			NAME: 'Name',
			LAST_NAME: 'Last Name',
			EMAIL: 'Email',
			POSITION: 'Position',
			COMPANY_NAME: 'Company Name',
			PHONE: 'Phone No.',
			BIRTHDAY: 'Birthday date',
			PASSWORD: 'Password',
			SAVE: 'Save changes',
			CANCEL: 'Cancel',

			SOCIAL_MESSAGE: 'You register with a Google social account so you can not change your password',
			CHANGE_IMAGE: 'To change the image please save.',

			LABELS: {
				FIELD_REQUIRED: 'Field required.',
				MIN_LENGHT: 'Min lenght 7 chars.',
				MIN_LENGHT_6: 'Min lenght 6 chars.',
				COMPANY: 'Company is not modifiable.',
				PASSWORD_INFO: 'Leave this field empty to not change your password.',
				FIELD_INCORRECT: 'Wrong email.',
				MAX_LENGHT: 'Maximum length of 15 characters.',
				ALONE_NUMBER: 'Only numbers.'
			},
			PLACEHOLDERS: {
				FIRST_NAME: 'Enter first name',
				LAST_NAME: 'Enter last name',
				EMAIL: 'Enter the email',
				POSITION: 'Select position',
				PHONE: 'Enter mobile',
				PASSWORD: 'Update password',
			}
		},
		PROFILE_COMPANY: {
			TITLE_EDIT: 'Edit My Company',
			COMPANY_NAME: 'Company Name: ',
			COMPANY_ALIAS: 'Company Alias: ',
			TEXT_COLOR: 'Select text color: ',
			BACKGROUND_COLOR: 'Select background color:',
			ATTACH: 'Attach Company Logo',
			PLACEHOLDERS: {
				COMPANY_NAME: 'Enter company name',
				COMPANY_ALIAS: 'Enter company alias',
			}
		},
		AUTH: {
			GENERAL: {
				OR: 'Or',
				SUBMIT_BUTTON: 'Submit',
				NO_ACCOUNT: 'Don’t have an account?',
				SIGNUP_BUTTON: 'Signup',
				FORGOT_BUTTON: 'Forgot Password?',
				BACK_BUTTON: 'Back',
				PRIVACY: 'Privacy',
				LEGAL: 'Legal',
				CONTACT: 'Contact',
				LOADING: 'Loading',
				SING_UP_LINK: 'Sing Up',
			},
			LOGIN: {
				TITLE: 'Login Account',
				BUTTON: 'Log in',
				GOOGLE: 'Using Google',
				BY_EMAIL: 'Log In by email',
				EMAIL_LABEL: 'Email address:',
				PASSWORD: 'Password:',
				ERROR_UNEXPECTED: 'Unexpected error',
				EMAIL_LABEL_PLACEHOLDER: 'Email address:',
				PASSWORD_PLACEHOLDER: 'Password:',
			},
			FORGOT: {
				TITLE: 'Forgotten your Password?',
				DESC: 'Having an account? You can recover your account using the email address or SMS number associated with your account.',
				CODE: 'Code received',
				MESSAGE_1: 'Next, enter the code you received.',
				MESSAGE_2: 'If the message does not arrive in 5 minutes, restart the process',
				MESSAGE_3: 'Pressing here.',

				HOW_RECEIVE: 'What means do you want to recover the password?',
			},
			REGISTER: {
				GET_STARTED: 'Get started with a free account',
				GET_SUBTITLE: 'Please use your work email address so we can connect you with your team in My Digital Card',
				PRINCING_LINK: 'See Plans and Pricing',
				SIGNUP_GOOGLE: 'Sign up using Google',
				BY_EMAIL: 'By email',
				EMAIL_ADDRESS: 'Email address',
				PASSWORD: 'Password',
				FIRST_NAME: 'First Name',
				COMPANY: 'Company',
				LANGUAGE: 'Language',
				TERMS: 'I agree to My Digital Card’s ',
				TERMS_LINK_1: ' Terms of Service ',
				AND: ' and ',
				TERMS_LINK_2: ' Privacy Policy ',
				BTN_REGISTER: 'Start registration process',
				ALREADY: 'Already have an account?',
				LOGIN: 'Log in',

				OPTION_1: 'English',
				OPTION_2: 'Spanish',

				TITLE: 'Sign Up',
				DESC: 'Enter your details to create your account',
				SUCCESS: 'Your account has been successfuly registered. Please use your registered account to login.'
			},
			INPUT: {
				EMAIL: 'Email',
				FULLNAME: 'Fullname',
				PASSWORD: 'Password',
				METHOD: 'Method',
				CODE: 'Code',
				CONFIRM_PASSWORD: 'Confirm Password',
			},
			VALIDATION: {
				INVALID: '{{name}} is not valid',
				REQUIRED: '{{name}} is required',
				MIN_LENGTH: '{{name}} minimum length is {{min}}',
				AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
				NOT_FOUND: 'The requested {{name}} is not found',
				INVALID_LOGIN: 'The login detail is incorrect',
				ATTEMPTS: 'apparently you have forgotten your password',
				RECOVER_PASSWORD: 'Recover password',
			}
		},
		CATEGORY: {
			REMOVED: 'Category removed',
			EMPTY_DATATABLE: 'Here you will find your news categories, complete the form at the top of this page to create a category',
			CREATE_LINK: 'create category',
			NAME: 'Category',
			NAME_LABEL: 'Category name',
			COLOR: 'Category color',
			NEW: 'Create Category',
			UPDATE: 'Update Category',
			UPDATED: 'Category Updated',
			NAME_PLACEHOLDER: 'Enter category name',
			COLOR_LABEL: 'Select color',
			LISTING_TITLE: 'Category Listing',
			ERROR_COLOR: 'Please select a color',
			ERROR_NAME: 'Category name is required',
			CREATED: 'Category was created',
		},
		INPUT: {
			BRANCH: 'Branch',
			SESSION_TYPE_HELP: 'The temporal password will be',
			SESSION_TYPE_G: 'G-Suites',
			SESSION_TYPE_N: 'Normal',
			TYPE_LOGIN: 'Type of login',
			ACCOUNT_TYPE: 'Account type',
			EMAIL: 'Email',
			CAN_NOTIFY: 'Can Notify?',
			POSITION: 'Position',
			MOBILE_PHONE: 'Mobile number',
			GROUPS: 'Groups',
			PLACEHOLDERS: {
				NAME: 'Enter first name',
				LAST_NAME: 'Enter last name',
				EMAIL: 'Enter email',
				MOBILE_PHONE: 'Enter phone',
				BIRTHDAY: 'Select date',
				GROUP_NAME: 'Enter group name',
				PASSWORD: 'EnterPassword',
				COMPANY: 'Enter company name',
				CODE: 'Enter code',
			},
			VALIDATIONS: {
				PATTERN: 'Field format is invalid',
				REQUIRED: 'Field required',
				EMAIL: 'Invalid email',
				PHONE: 'Invalid phone',
				MIN_HEIGHT: 'Value too short',
			}
		},
		CONTACTS: {
			USER: 'User',
			POSITION: 'Position',
			QUANTITY: 'Quantity',
			NAME: 'Name',
			COMPANY: 'Company',
			EMAIL: 'E-Mail',
			CELL_PHONE: 'Cell phone',
		},
		USERS: {
			UPDATE_USER: 'Update user',
			COLUMN_A: 'First Name',
			COLUMN_B: 'Last Name',
			COLUMN_C: 'Birthday - <strong>yyyy-mm-dd</strong>',
			COLUMN_D: 'Email (From the same domai)',
			COLUMN_E: 'Country Code - example: United States',
			COLUMN_F: 'Phone',
			COLUMN_G: 'Can notify (Yes - No)',
			COLUMN_H: 'Branch of the company',
			COLUMN_I: 'Position',
			COLUMN_J: 'Session type',
			COLUMN_K: 'Account type',
			COLUMN_L: 'Document Number',
			PROCESSED_USERS: 'File processed successfully',
			DOWNLOAD_XLSX_FILE: 'Download the template file',
			UPLOAD_XLSX_INST: 'and upload a excel file with the <strong>propper format and propper fields</strong> order',
			UPLOAD_USERS_TITLE: 'Select file to upload',
			SELECT_GROUP_MEMBER: 'Select group members',
			UPLOAD_XLSX_INST1: 'and upload a excel file with the',
			UPLOAD_XLSX_INST2: 'propper format and propper fields',
			UPLOAD_XLSX_INST3: 'order',
			GROUP_LISTING: 'Group Listing',
			TOTAL: {
				TITLE: 'Total Users',
				SUBTITLE: 'All Users',
			},
			ID: 'ID',
			IDENTIFICATION: 'Identification',
			FULL_NAME: 'Full Name',
			EMAIL: 'Email',
			MOBILE: 'Mobile',
			GROUP: 'Group',
			STATUS: 'Status',
			ACTIONS: 'Actions',
			USERS: 'Users',
			CREATED: 'User was created',
			UPDATED: 'User was updated',
		},
		BRANCHES: {
			TOTAL: {
				TITLE: 'Total sent',
				SUBTITLE: 'Total cards sent',
			},
			BRANCHE_NAME: 'Branch Name',
			COUNTRY: 'Country',
			CITY: 'City',
			BRANCH_ADDRESS: 'Branch Address',
			USER: 'Users',
			BRANCHE_PHONE: 'Branch Phone',
			// STATUS: 'Status',
			ACTIONS: 'Actions',

			NEW_BRANCH: 'New Branch',
			BRANCH_CITY: 'Branch City',
			BRANCH_PHONE: 'Branch Phone',
			BRANCH_SOCIAL: 'Social network name',
			PLACE_ID: 'Place ID:',
			PLACEHOLDERS: {
				CITY: 'City',
				ADDRESS: 'Address',
				NAME: 'Enter branch name',
				PHONE: 'Enter branch phone',
				PLACE_ID: 'Place ID',
			}
		},
		CARDS: {
			TOTAL: {
				TITLE: 'Total Cards',
				SUBTITLE: 'All Cards',
			},
			ID: 'ID',
			IDENTIFICATION: 'Identification',
			FULL_NAME: 'Full Name',
			EMAIL: 'Email',
			MOBILE: 'Mobile',
			GROUP: 'Group',
			STATUS: 'Status',
			ACTIONS: 'Actions'
		},
		COMPANY_POSITION: {
			ID: 'ID',
			IDENTIFICATION: 'Identification',
			FULL_NAME: 'Full Name',
			EMAIL: 'Email',
			MOBILE: 'Mobile',
			GROUP: 'Group',
			STATUS: 'Status',
			ACTIONS: 'Actions',
			NAME_POSITION: 'Name Position',
		},
		COMPANY_POSITION_CONTACT: {
			NAME_COMPANY: 'Name'
		},
		GROUPS: {
			CREATE_LINK: 'Create group',
			EMPTY_DATATABLE: 'Here you will find your user groups, you can create groups by completing the form located at the top of this page',
			ASSIGN_HELP: `To assign a user to group <strong>{{name}}</strong>, please select it from the list on the left side. To unassign use the list on the right side. You can assign or deallocate the arrows in bulk. (<< >>)`,
			UPDATED: 'Group updated',
			NAME: 'Group Name',
			SHOW_IN_DIRECTORY: 'Show in directory',
			DESCRIPTION: 'Description',
			UPDATE_GROUPS: 'Admin members',
			CREATED: 'Group created',
			REMOVED: 'Group removed',
			EDIT: 'Edit group',
			MANAGE: 'Admin users',
			ASSIGNED: 'Assigned users',
			NO_ASSIGNED: 'No assigned users',
			MEMBERS_UPDATED: 'the group has been updated, users of this group have been assigned or withdrawn',
		},
		GENERAL: {
			CODE_SUCCESS: 'The cancellation was made correctly',
			CODE_ERROR: 'The cancellation was not made',
			lang: 'en',
			SHOW_ALL_ERRORS: 'show all errors',
			SELECT_ONE: 'Select one',
			ALL: 'all',
			CLOSE: 'Close',
			SEND: 'Sent',
			PROCESSING: 'Processing',
			FIX_THIS_ERRORS:  'Please fix the next errors:',
			NEWS_TITLE: 'Entry title',
			UPDATE_SUCCESS: 'Updated',
			SUCCESS: 'Success',
			SUCCESS_PASS_TEMP: 'Generated password',
			SUCCESS_MSJ_TEMP: 'Your temporary password is: ',
			BTN_USE: 'Use',
			CONFIRM: 'Confirm',
			CREATED: 'Success creating',
			CONFIRM_REMOVE: 'Are you sure you want to eliminate?',
			CONFIRM_REMOVE_TEXT: 'You won\'t be able to revert this!',
			YES: 'yes',
			NO: 'no',
			ADMIN: 'Administrator',
			USER: 'User',
			LOADING: 'Loading',
			SEARCH: 'Search',
			EDIT: 'Edit',
			CREATE: 'Create',
			BIRTHDAY: 'Birthday',
			LAST_NAME:  'Last name',
			FIRST_NAME: 'First name',
			SAVE: 'Save',
			CANCEL: 'Cancel',
			REMOVE: 'Remove',
			TOGGLE_ACTIVE: 'Activate / Desactivate',
			UPDATE: 'Update',
			INACTIVE: 'Inactive',
			ACTIVE: 'Active',
			ENTRIES: 'entries',
			STATUS: 'Status',
			ACTIONS: 'Actions',
			FOLDER: 'Folder',
			SEARCH_PLACES: 'Search Places',
			INTRODUCTION: 'introduction',
			DESCRIPTION: 'Description',
			CREATION_DATE: 'Creation date',
			PUBLICATION_DATE: 'Publication date',
			ID: 'ID',
			USERS: 'Users',
			TITLE: 'Title',
			NAME: 'Name',
			CATEGORY: 'Category',
			DIRECTORY: 'Directory',
			ADD_USER: 'New User',
			ADD_BRANCH: 'New Branch',
			ADD_POSITION: 'New Position',
			SEARCH_PLACEHOLDER: 'Search in all fields',
			SEARCH_PLACES_PLACEHOLDER: 'Search in all places',
			NOT_REGISTERED: 'Not registered',
			TOTAL_CONTACT: 'Contacts',
			TAB_BASIC_INFO: 'Basic Info',
			TAB_BRANCH_SOCIAL: 'Social networks',
			FIELD_REQUIRED: 'Field required',
			BTN_ADD_SOCIAL: 'Add social network',
			MSJ_URL_FORMAT: 'Should be a url',
			NUMBER_FORMAT: 'Numeric field | maximum 15 numbers',
			EMPTY_SEARCH: 'Not results found for this view',
			CREATED_SUCCESS: 'Created',
			POSITION: {
				NEW_TITLE: 'New Position',
				UPDATE_TITLE: 'Edit Position',
				BREADCRUMB: 'Company position',
				POSITION_NAME: 'New name of the position:'
			},
			BRANCHES: {
				BRANCHES: 'Branches',
				REQUIRED: 'Please verify the form fields',
				NAME_REQUIRED: 'Name is required',
				PHONE_REQUIRED: 'Phone is required',
				URL_REQUIRED: 'Url is required',
				NEW_BRANCH: 'New branch',
				UPDATE_BRANCH: 'Update branch',
				SELECT_PLACE: 'Select a place in the map'
			},
			NO_DATA_FOUND: 'No data found',
			NO_DATA_FOUND_TOP_CONTACT_COMPANY: 'No found registered companies, this information is generated from the cards sent from the application.',
			NO_DATA_FOUND_TOP_CONTACT_POSITION: 'No found registered positions, this information is generated from the cards sent from the application.',
			SEE_MORE: 'See more',
			CROP_LOGO: 'Crop logo',
			SQUARE: 'Square',
			RECTANGULAR: 'Rectangular',
			MAP: 'Location of the cards sent',
			CONTACT: 'Contact Company/Position',
			CONTACT_COMPANY: 'Contact company',
			CONTACT_POSITION: 'Contact position',
			USERS_SENT_TARJET: 'Here you will find the users to whom cards have been sent.',
			COMPANIES_USERS_SENT: 'Here you will find the companies of the people to whom the digital card has been shared.',
			POSITION_USERS_SENT: 'Here you will find the charges of the people to whom the digital card has been shared.',
			CAN_CHANGE_POSITION: 'Can employees change the charge?',
			SELECT_OPTION: 'Please select some option'
		},
		ECOMMERCE: {
			COMMON: {
				SELECTED_RECORDS_COUNT: 'Selected records count: ',
				ALL: 'All',
				SUSPENDED: 'Suspended',
				ACTIVE: 'Active',
				FILTER: 'Filter',
				BY_STATUS: 'by Status',
				BY_TYPE: 'by Type',
				BUSINESS: 'Business',
				INDIVIDUAL: 'Individual',
				SEARCH: 'Search',
				IN_ALL_FIELDS: 'in all fields'
			},
			ECOMMERCE: 'eCommerce',
			CUSTOMERS: {
				CUSTOMERS: 'Customers',
				CUSTOMERS_LIST: 'Customers list',
				NEW_CUSTOMER: 'New Customer',
				DELETE_CUSTOMER_SIMPLE: {
					TITLE: 'Customer Delete',
					DESCRIPTION: 'Are you sure to permanently delete this customer?',
					WAIT_DESCRIPTION: 'Customer is deleting...',
					MESSAGE: 'Customer has been deleted'
				},
				DELETE_CUSTOMER_MULTY: {
					TITLE: 'Customers Delete',
					DESCRIPTION: 'Are you sure to permanently delete selected customers?',
					WAIT_DESCRIPTION: 'Customers are deleting...',
					MESSAGE: 'Selected customers have been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected customers',
					MESSAGE: 'Selected customers status have successfully been updated'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Customer has been updated',
					ADD_MESSAGE: 'Customer has been created'
				}
			}
		},
		FOOTER: {
			COPY: '2019 © My Digital Card By ',
			// COMPANY: 'Inmov',
			PRIVACY: 'Privacy',
			TYC: 'T&C',
			PURCHASE: 'Purchase',
		}
	}
};
