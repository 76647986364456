import { GetObjectPipe } from '../../../core/pipes/get-object.pipe';
import { Component, OnInit, HostBinding, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LayoutConfigService } from '../../../core/services/layout-config.service';
import * as objectPath from 'object-path';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from '../../../core/services/company.service';

@Component({
	selector: 'm-footer',
	templateUrl: './footer.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {
	@HostBinding('class') classes = 'm-grid__item m-footer';

	footerContainerClass$: BehaviorSubject<string> = new BehaviorSubject('');
	titleTerms: string;
	messageTerms: string;
	titlePrivicy: string;
	messagePrivicy: string;
	termsAndPrivacy: any;

	constructor (
		private configService: LayoutConfigService,
		private translate: TranslateService,
		private cdr: ChangeDetectorRef,
		private companyService: CompanyService
	) {
		this.configService.onLayoutConfigUpdated$.subscribe(model => {
			const config = model.config;

			let pageBodyClass = '';
			const selfLayout = objectPath.get(config, 'self.layout');
			if (selfLayout === 'boxed' || selfLayout === 'wide') {
				pageBodyClass += 'm-container--responsive m-container--xxl';
			} else {
				pageBodyClass += 'm-container--fluid';
			}
			this.footerContainerClass$.next(pageBodyClass);
		});
	}

	ngOnInit(): void {
		this.companyService.getTermsAndContidtios().subscribe( ( response: any) => {
			this.termsAndPrivacy = response[0];
		}, (e: any) => {
		});
	}
	async forgotPasswordPage(type: string) {
		let lang: any;
		await this.translate.stream('GENERAL').subscribe((res) => {
			lang = res.lang;
		});
		if (lang === 'es') {
			this.titleTerms = 'Términos de servicio';
			this.messageTerms = this.termsAndPrivacy.terms_es;
			this.titlePrivicy = 'Política de privacidad';
			this.messagePrivicy = this.termsAndPrivacy.policy_es.replace('<a', '<a style="color: red;" href');
		}
		if (lang === 'en') {
			this.titleTerms = 'Terms of Service';
			this.messageTerms = this.termsAndPrivacy.terms_en;
			this.titlePrivicy = 'Privacy Policy';
			this.messagePrivicy = this.termsAndPrivacy.policy_en.replace('<a', '<a style="color: red;" href');
		}
		switch (type) {
			case 'terms':
			(<any>$('#myModal')).modal();
			this.cdr.detectChanges();
			$('.modal-a-color-primary a').css('color', '#6a25c6');
			// swal( titleTerms, messageTerms);
			break;
			case 'privacy':
			(<any>$('#myModal2')).modal();
			this.cdr.detectChanges();
			$('.modal-a-color-primary a').css('color', '#6a25c6');
			break;
		}
	}
}
