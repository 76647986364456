import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { UserService } from '../../../../../core/services/user.service';
import { environment } from '../../../../../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
	selector: 'm-user-profile',
	templateUrl: './user-profile.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileComponent implements OnInit {
	user: any = {};
	@HostBinding('class')
	// tslint:disable-next-line:max-line-length
	classes = 'm-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light';

	@HostBinding('attr.m-dropdown-toggle') attrDropdownToggle = 'click';

	@Input() avatar: string = './assets/app/media/img/users/user4.jpg';
	@Input() avatarBg: SafeStyle = '';

	@ViewChild('mProfileDropdown') mProfileDropdown: ElementRef;

	constructor (
		private router: Router,
		private authService: AuthenticationService,
		private sanitizer: DomSanitizer,
		private userService: UserService,
		private cdr: ChangeDetectorRef,
	) {}

	async ngOnInit () {
		if (!this.avatarBg) {
			this.avatarBg = this.sanitizer.bypassSecurityTrustStyle('url(./assets/app/media/img/misc/user_profile_bg.jpg)');
		}
		this.userService.getMe()
			.subscribe((user: any) => {
				this.userService.me = user;
				this.user = user;
				if (user.avatar == null) {
					this.avatar = `./assets/app/media/img/users/user4.jpg`;
				} else {
					this.avatar = `${environment.assetsDomain}/storage/${user.avatar}`;
				}


				if (user.account_status !== 'Active') {
					switch(user.account_status) {
						case 'PaymentBlock': {
							// @ts-ignore
							window.$.blockUI({ message: this.translate.instant('ACCOUNT.SUSPENDED') });
						}
						case 'PaymentAlert': {
							// @ts-ignore
							window.$.blockUI({ message: this.translate.instant('ACCOUNT.PAYMENT_ALERT') });
						}
						case 'DemoAlert': {
							// @ts-ignore
							window.$.blockUI({ message: this.translate.instant('ACCOUNT.DEMO_ALERT') });
						}
					}
				}
				this.cdr.detectChanges();
			}, (ex) => {
				//console.log('ME ++++++++>>>>',ex);
			})
	}

	exampleMethodParent($event) {
		console.log($event);
	}

	public logout () {
		this.authService.logout(true);
	}
}
