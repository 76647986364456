import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthNotice } from './auth-notice.interface';

@Injectable({
	providedIn: 'root'
})
export class AuthNoticeService {
	/**
	 * Evento lanzado al momento de que cambia el mensaje a mostrar
	 */
	onNoticeChanged$: BehaviorSubject<AuthNotice>;

	constructor() {
		this.onNoticeChanged$ = new BehaviorSubject(null);
	}

	/**
	 * Establece un nuevo mensaje para mostrar al usuario
	 * @param message Nuevo mensaje
	 * @param type typo de mensaje
	 */
	setNotice(message: string, type?: string) {
		const notice: AuthNotice = {
			message: message,
			type: type
		};
		this.onNoticeChanged$.next(notice);
	}
}
