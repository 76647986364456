import { HttpParams, HttpHeaders } from '@angular/common/http';
var UtilsService = /** @class */ (function () {
    function UtilsService() {
    }
    /**
     * Build url parameters key and value pairs from array or object
     * @param obj
     */
    UtilsService.prototype.urlParam = function (obj) {
        return Object.keys(obj)
            .map(function (k) { return k + '=' + encodeURIComponent(obj[k]); })
            .join('&');
    };
    /**
     * Simple object check.
     * @param item
     * @returns {boolean}
     */
    UtilsService.prototype.isObject = function (item) {
        return item && typeof item === 'object' && !Array.isArray(item);
    };
    /**
     * Deep merge two objects.
     * @param target
     * @param ...sources
     * @see https://stackoverflow.com/a/34749873/1316921
     */
    UtilsService.prototype.mergeDeep = function (target) {
        var sources = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            sources[_i - 1] = arguments[_i];
        }
        if (!sources.length) {
            return target;
        }
        var source = sources.shift();
        if (this.isObject(target) && this.isObject(source)) {
            for (var key in source) {
                if (this.isObject(source[key])) {
                    if (!target[key]) {
                        Object.assign(target, (_a = {}, _a[key] = {}, _a));
                    }
                    this.mergeDeep(target[key], source[key]);
                }
                else {
                    Object.assign(target, (_b = {}, _b[key] = source[key], _b));
                }
            }
        }
        return this.mergeDeep.apply(this, [target].concat(sources));
        var _a, _b;
    };
    UtilsService.prototype.getPath = function (obj, val, path) {
        path = path || '';
        var fullpath = '';
        for (var b in obj) {
            if (obj[b] === val) {
                return path + '/' + b;
            }
            else if (typeof obj[b] === 'object') {
                fullpath =
                    this.getPath(obj[b], val, path + '/' + b) || fullpath;
            }
        }
        return fullpath;
    };
    UtilsService.prototype.getFindHTTPParams = function (queryParams) {
        var params = new HttpParams()
            .set('lastNamefilter', queryParams.filter)
            .set('sortOrder', queryParams.sortOrder)
            .set('sortField', queryParams.sortField)
            .set('pageNumber', queryParams.pageNumber.toString())
            .set('pageSize', queryParams.pageSize.toString());
        return params;
    };
    UtilsService.prototype.getHTTPHeader = function () {
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };
    };
    return UtilsService;
}());
export { UtilsService };
export function isInteger(value) {
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}
export function isString(value) {
    return typeof value === 'string';
}
