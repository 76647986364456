import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { AuthenticationService } from "./authentication.service";
import { catchError, tap } from "rxjs/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthenticationService) {}

  cleanToken() {
    this.auth.clearToken();
    location.reload();
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem("accessToken"); // auth is provided via constructor.
    console.log(next);
    if (token) {
      const nextReq = req.clone({
        headers: req.headers
          .set("x-Language", this.auth.getLang())
          .set("Authorization", "Bearer " + token)
          .set("X-Platform", "panel")
      });

      return next.handle(nextReq).pipe(
        tap(
          (data: any) => {
          //console.log("NoErr1", data.status);
          },
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              //console.log(err);
              if (err.status !== 401) {
                //console.log("Auth?", err.status);
                return;
              }else{
                localStorage.setItem("accessToken", "")
                //console.log("Unautorized?", err.status);
              }
              this.cleanToken();
            }
          }
        )
      );
    }
    return next.handle(
      req.clone({
        headers: req.headers
          .set("x-Language", this.auth.getLang())
          .set("X-Platform", "panel")
      })
    ).pipe(
      tap(
        (data: any) => {
        },
        (err: any) => {
          //console.log('ERR',err);
          if (err instanceof HttpErrorResponse) {
           // 
            if (err.status !== 401) {              
             // console.log("AU ",err.status);
              return;
            }else{
              localStorage.setItem("accessToken", "")
             // console.log("Unautorized?", err.status);
            }
            this.cleanToken();
          }
        }
      )
    );
  }
}
