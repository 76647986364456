import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
var TranslationService = /** @class */ (function () {
    function TranslationService(translate) {
        this.translate = translate;
        this.langIds = [];
        // add new langIds to the list
        this.translate.addLangs(['en', 'es']);
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang('en');
    }
    TranslationService.prototype.loadTranslations = function () {
        var _this = this;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var locales = args.slice();
        locales.forEach(function (locale) {
            // use setTranslation() with the third argument set to true
            // to append translations instead of replacing them
            _this.translate.setTranslation(locale.lang, locale.data, true);
            _this.langIds.push(locale.lang);
        });
        // add new languages to the list
        this.translate.addLangs(this.langIds);
    };
    TranslationService.prototype.setLanguage = function (lang) {
        if (lang) {
            this.translate.use(this.translate.getDefaultLang());
            this.translate.use(lang);
            localStorage.setItem('language', lang);
        }
    };
    TranslationService.prototype.getSelectedLanguage = function () {
        return of(localStorage.getItem('language') || this.translate.getDefaultLang());
    };
    TranslationService.ngInjectableDef = i0.defineInjectable({ factory: function TranslationService_Factory() { return new TranslationService(i0.inject(i1.TranslateService)); }, token: TranslationService, providedIn: "root" });
    return TranslationService;
}());
export { TranslationService };
