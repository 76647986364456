<!-- begin::Scroll Top -->
<style>
.m-scroll-top:hover>i{
	color: white !important;
}
</style>
<div mScrollTop id="m_scroll_top" style="background-color: #FA1339" class="m-scroll-top">
	<i class="la la-arrow-up"></i>
</div>
<!-- end::Scroll Top -->
