var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../../../../core/auth/authentication.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthNoticeService } from '../../../../core/auth/auth-notice.service';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { ForgotPasswordService } from '../../../../core/services/forgot-password.service';
var CodeComponent = /** @class */ (function () {
    function CodeComponent(authService, authNoticeService, translate, formBuilder, activatedRoute, router, forgotService, cdr) {
        this.authService = authService;
        this.authNoticeService = authNoticeService;
        this.translate = translate;
        this.formBuilder = formBuilder;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.forgotService = forgotService;
        this.cdr = cdr;
        this.processing = false;
        this.model = { item: '', token: '' };
        this.passChange = new EventEmitter();
        this.actionChange = new Subject();
        this.submitted = false;
        // @ViewChild('f') f: NgForm;
        this.errors = [];
        this.spinner = {
            active: false,
            spinnerSize: 18,
            raised: true,
            buttonColor: 'primary',
            spinnerColor: 'accent',
            fullWidth: false
        };
        console.log(this.actionChange);
    }
    CodeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.params.subscribe(function (params) {
            var email_url = params.mail;
            _this.form = _this.formBuilder.group({
                item: [email_url, [Validators.required, Validators.email]],
                token: ['', Validators.required],
            });
        });
        // this.model.item = this.email;
    };
    Object.defineProperty(CodeComponent.prototype, "f", {
        get: function () { return this.form.controls; },
        enumerable: true,
        configurable: true
    });
    CodeComponent.prototype.submit = function () {
        var _this = this;
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.processing = true;
        var values = __assign({}, this.form.value);
        var errorCallback = function (e) {
            _this.processing = false;
            console.log('error');
            var errorMsj = '';
            switch (e.status) {
                case 401:
                    e.error.errors.messages.forEach(function (element) {
                        errorMsj = element;
                    });
                    break;
                case 422:
                    var myText_1 = "";
                    var array = Object.keys(e.error.errors).map(function (key) {
                        myText_1 = myText_1 + " " + e.error.errors[key][0];
                    });
                    for (var index = 0; index < array.length; index++) {
                        if (index + 1 == array.length) {
                            errorMsj = myText_1;
                        }
                    }
                    break;
                default:
                    errorMsj = 'Error conecting to server';
            }
            swal('Error', errorMsj, 'error');
            $('body').removeClass('swal2-height-auto');
            _this.cdr.detectChanges();
        };
        var successCallback = function (data) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            var cod_pass;
            return __generator(this, function (_a) {
                this.processing = false;
                console.log(data.response.messages[0]);
                cod_pass = data.response.messages[0];
                this.form.reset();
                this.translate.get([
                    'GENERAL.SUCCESS',
                ]).subscribe(function (t) { return __awaiter(_this, void 0, void 0, function () {
                    var _this = this;
                    var msj_success;
                    return __generator(this, function (_a) {
                        msj_success = this.translate.instant('GENERAL.SUCCESS_MSJ_TEMP') + " <strong>" + cod_pass + "</strong>";
                        // btn cancelar usar
                        swal({
                            title: this.translate.instant('GENERAL.SUCCESS_PASS_TEMP'),
                            html: msj_success,
                            type: 'success',
                            showCancelButton: true,
                            // confirmButtonColor: '#3085d6',
                            // cancelButtonColor: '#d33',
                            confirmButtonText: this.translate.instant('GENERAL.BTN_USE'),
                            cancelButtonText: this.translate.instant('GENERAL.CANCEL'),
                        }).then(function (result) {
                            if (result.value) {
                                _this.router.navigateByUrl('/login?email=' + values.item + '&pass=' + cod_pass);
                            }
                        });
                        $('body').removeClass('swal2-height-auto');
                        return [2 /*return*/];
                    });
                }); });
                this.cdr.detectChanges();
                return [2 /*return*/];
            });
        }); };
        this.forgotService.validateToken(values).subscribe(successCallback, errorCallback);
    };
    CodeComponent.prototype.forgotPage = function (event) {
        event.preventDefault();
        this.action = 'forgot-password';
        this.actionChange.next(this.action);
    };
    return CodeComponent;
}());
export { CodeComponent };
