var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';
import { AuthenticationService } from '../auth/authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../auth/authentication.service";
var ForgotPasswordService = /** @class */ (function () {
    function ForgotPasswordService(http, authService) {
        this.http = http;
        this.authService = authService;
    }
    ForgotPasswordService.prototype.forgotPassword = function (data) {
        return this.http.post(environment.apiUrl + "/app/recovery/pass", __assign({}, data));
    };
    // '/app/validate/token'
    ForgotPasswordService.prototype.validateToken = function (data) {
        return this.http.post(environment.apiUrl + "/app/validate/token", __assign({}, data));
    };
    ForgotPasswordService.ngInjectableDef = i0.defineInjectable({ factory: function ForgotPasswordService_Factory() { return new ForgotPasswordService(i0.inject(i1.HttpClient), i0.inject(i2.AuthenticationService)); }, token: ForgotPasswordService, providedIn: "root" });
    return ForgotPasswordService;
}());
export { ForgotPasswordService };
