import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { DoughnutChartComponent } from './doughnut-chart/doughnut-chart.component';
import { ChartsModule } from 'ng2-charts';
import {AgmCoreModule} from '@agm/core';
import { MapChartComponent } from './map-chart/map-chart.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';



@NgModule({
	imports: [
		CommonModule,
		ChartsModule,
		TranslateModule.forChild(),
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyAM-x4fGWDESn1d2wjRqh_qhrPCfXZtSlY',
			libraries: ['places']
		}),
		RouterModule
	],
	exports: [
		MapChartComponent,
		BarChartComponent,
		DoughnutChartComponent,
	],
	declarations: [
		BarChartComponent,
		DoughnutChartComponent,
		MapChartComponent,
	]
})
export class WidgetChartsModule {}
