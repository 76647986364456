/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i2 from "@angular/material/progress-spinner";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "@angular/common";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/router";
import * as i7 from "./app.component";
import * as i8 from "./core/services/layout-config.service";
import * as i9 from "./core/services/class-init.service";
import * as i10 from "@angular/platform-browser";
import * as i11 from "./core/services/translation.service";
import * as i12 from "./core/services/page-config.service";
import * as i13 from "./core/services/splash-screen.service";
import * as i14 from "./core/services/acl.service";
import * as i15 from "./core/auth/authentication.service";
import * as i16 from "ngx-permissions";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { splashScreen: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["splashScreen", 1]], null, 3, "div", [["class", "m-splash-screen"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["alt", "Logo"], ["src", "./assets/demo/default/media/img/logo/logo_large.png"], ["style", "width: 90px; margin-bottom: 30px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["diameter", "40"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i1.View_MatSpinner_0, i1.RenderType_MatSpinner)), i0.ɵdid(4, 49152, null, 0, i2.MatSpinner, [i0.ElementRef, i3.Platform, [2, i4.DOCUMENT], [2, i5.ANIMATION_MODULE_TYPE], i2.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null), (_l()(), i0.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(6, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { var currVal_3 = "40"; _ck(_v, 4, 0, currVal_3); _ck(_v, 6, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 4)._noopAnimations; var currVal_1 = i0.ɵnov(_v, 4).diameter; var currVal_2 = i0.ɵnov(_v, 4).diameter; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "body", [["m-root", ""]], [[8, "style", 2], [8, "className", 0]], null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 4308992, null, 0, i7.AppComponent, [i8.LayoutConfigService, i9.ClassInitService, i10.DomSanitizer, i11.TranslationService, i6.Router, i12.PageConfigService, i13.SplashScreenService, i14.AclService, i15.AuthenticationService, i16.NgxPermissionsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).style; var currVal_1 = i0.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var AppComponentNgFactory = i0.ɵccf("body[m-root]", i7.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
