/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./timeline-item/timeline-item.component.ngfactory";
import * as i2 from "./timeline-item/timeline-item.component";
import * as i3 from "@angular/common";
import * as i4 from "./list-timeline.component";
import * as i5 from "../../../../../core/services/logs.service";
var styles_ListTimelineComponent = [];
var RenderType_ListTimelineComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ListTimelineComponent, data: {} });
export { RenderType_ListTimelineComponent as RenderType_ListTimelineComponent };
function View_ListTimelineComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "m-list-timeline__heading"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.heading; _ck(_v, 1, 0, currVal_0); }); }
function View_ListTimelineComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "m-timeline-item", [], [[8, "className", 0]], null, null, i1.View_TimelineItemComponent_0, i1.RenderType_TimelineItemComponent)), i0.ɵdid(2, 114688, null, 0, i2.TimelineItemComponent, [], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).classes; _ck(_v, 1, 0, currVal_0); }); }
function View_ListTimelineComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "m-list-timeline__group"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListTimelineComponent_2)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "m-list-timeline__items"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ListTimelineComponent_3)), i0.ɵdid(5, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.heading; _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_co.logList)); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_ListTimelineComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "m-list-timeline"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ListTimelineComponent_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = ((((tmp_0_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.logList))) == null) ? null : tmp_0_0.length) > 0); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ListTimelineComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "m-list-timeline", [], null, null, null, View_ListTimelineComponent_0, RenderType_ListTimelineComponent)), i0.ɵdid(1, 114688, null, 0, i4.ListTimelineComponent, [i5.LogsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ListTimelineComponentNgFactory = i0.ɵccf("m-list-timeline", i4.ListTimelineComponent, View_ListTimelineComponent_Host_0, { type: "type", heading: "heading", logList: "logList" }, {}, []);
export { ListTimelineComponentNgFactory as ListTimelineComponentNgFactory };
