<!--begin::Signin-->
	  
	<!-- Modal -->
	<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
	<div class="modal-dialog modal-lg" role="document">
		<div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title" id="myModalLabel">{{ titleTerms }}</h4>
		</div>
		<div class="modal-body">
			<p class="modal-a-color-primary" [innerHTML]="messageTerms"></p>
		
		</div>
		<!--<div class="modal-footer">
			<button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
			<button type="button" class="btn btn-primary">Save changes</button>
		</div>-->
		</div>
	</div>
	</div>

	<!-- Modal -->
	<div class="modal fade" id="myModal2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
		<div class="modal-dialog modal-lg" role="document">
			<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title" id="myModalLabel">{{ titlePrivicy }}</h4>
			</div>
			<div class="modal-body">
				<p class="modal-a-color-primary" [innerHTML]="messagePrivicy"></p>
			</div>
			<!--<div class="modal-footer">
				<button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
				<button type="button" class="btn btn-primary">Save changes</button>
			</div>-->
			</div>
		</div>
	</div>

<div style="flex: 1" class="m-grid__item m-grid__item--fluid m-grid m-grid--desktop m-grid--ver-desktop m-grid--hor-tablet-and-mobile m-login m-login--6" id="m_login">
	<div class="m-grid__item m-grid__item--fluid  m-grid__item--order-tablet-and-mobile-1 m-login__wrapper">
		<div id="m_header_topbar" class="m-topbar m-stack m-stack--ver" style="position: relative; box-shadow: none !important;">
			<!-- <div class="m-stack__item m-topbar__nav-wrapper"><br>
				<ul class="m-topbar__nav m-nav m-nav--inline my-btn-language-selector">
					<m-language-selector></m-language-selector>
				</ul>
			</div> -->
		</div>
		<!--begin::Body-->
		<div class="m-login__body">
		<div class="m-login__signin">
			<div class="m-login__title">
				<div class="m-login__title">
					<img src="./assets/demo/default/media/img/logo/logo_large.png" alt="">
				</div>
				<div class="m-login__desc">
					<b translate="AUTH.REGISTER.GET_STARTED">Get started with a free account</b>
					<p translate="AUTH.REGISTER.GET_SUBTITLE">Please use your work email address so we can connect you with your team in My Digital Card</p>
					<a href="http://indeapp.online/#pricing" style="color: #FA4260" translate="AUTH.REGISTER.PRINCING_LINK">See Plans and Princing</a>
				</div>
			</div>
			<div class="m-login__options">
				<button href="javascript:;" mat-raised-button color="secondary" class="btn-block btn-gle" id="customBtn2">
					<span>
						<img src="./assets/custom/google_logo.svg" width="26" class="im-google-logo" />
						<span translate="AUTH.REGISTER.SIGNUP_GOOGLE">Sign up using Google</span>
					</span>
				</button>
			</div>
			<div class="m-login__form-divider">
				<div class="m-divider">
					<span></span>
					<span translate="AUTH.GENERAL.OR">OR</span>
					<span></span>
				</div>
			</div>
			<div style="text-align: center; background-color: #F7F8FA; padding: 10px; width: 100%; color: #3B3A3E; font-weight: 600">
				<span translate="AUTH.REGISTER.BY_EMAIL">By email</span>
			</div>
			<!-- <m-auth-notice></m-auth-notice> -->
			<!--begin::Form-->
			<form class="m-login__form m-form" [formGroup]="form" (ngSubmit)="onSubmit()">
				<div class="form-group">
					<label translate="AUTH.REGISTER.EMAIL_ADDRESS">Email address</label>
					<input test-id="username" class="form-control m-input m-input--square" type="email" name="email" [placeholder]="'INPUT.PLACEHOLDERS.EMAIL' | translate"
					autocomplete="off" 
					formControlName="email"
					[ngClass]="{ 'is-invalid': f.email.invalid && f.email.touched, 'is-disabled': social }"
					email="true" required>
					<div *ngIf="f.email.invalid && f.email.touched" class="invalid-feedback">
						<div *ngIf="f.email.errors.required" translate="INPUT.VALIDATIONS.REQUIRED">Field required</div>
					</div>
				</div>
				<div class="form-group">
					<label translate="GENERAL.FIRST_NAME">First Name</label>
					<input test-id="first_name" class="form-control m-input m-input--square" type="text" name="first_name" [placeholder]="'INPUT.PLACEHOLDERS.NAME' | translate"
					
					formControlName="first_name"			
					[ngClass]="{ 'is-invalid': f.first_name.invalid && f.first_name.touched}"
					required>
					<div *ngIf="f.first_name.errors && f.first_name.touched" class="invalid-feedback">
							<div *ngIf="f.first_name.errors.required" translate="INPUT.VALIDATIONS.REQUIRED">Field required</div>
					</div>
				</div>
				<div class="form-group">
						<label translate="GENERAL.LAST_NAME">Last Name</label>
						<input test-id="last_name" class="form-control m-input m-input--square" type="text" name="last_name" [placeholder]="'INPUT.PLACEHOLDERS.LAST_NAME' | translate"
						
						formControlName="last_name"			
						[ngClass]="{ 'is-invalid': f.last_name.invalid && f.last_name.touched}"
						required>
						<div *ngIf="f.last_name.errors && f.last_name.touched" class="invalid-feedback">
								<div *ngIf="f.last_name.errors.required" translate="INPUT.VALIDATIONS.REQUIRED">Field required</div>
						</div>
					</div>
				<div class="form-group">
					<label translate="AUTH.REGISTER.COMPANY">Company</label>
					<input test-id="company" class="form-control m-input m-input--square" type="text" name="company" 
					[placeholder]="'INPUT.PLACEHOLDERS.COMPANY' | translate" placeholder="Company name"
					formControlName="company"	
					[ngClass]="{ 'is-invalid': f.company.invalid && f.company.touched }"
					required>
					<div *ngIf="f.company.errors && f.company.touched" class="invalid-feedback">
							<div *ngIf="f.company.errors.required" translate="INPUT.VALIDATIONS.REQUIRED">Field required</div>
					</div>
				</div>
				<div class="form-group" [hidden]="social">
					<label translate="AUTH.REGISTER.PASSWORD">Password</label>
					<input test-id="password" class="form-control m-input m-input--square" minlength="6" type="password" name="password"
					[placeholder]="'INPUT.PLACEHOLDERS.PASSWORD' | translate" autocomplete="off" 
					formControlName="password"			 
					[ngClass]="{ 'is-invalid': f.password.errors && f.password.touched }"
					required>
					<div *ngIf="f.password.errors && f.password.touched" class="invalid-feedback invalid-show">
						<div *ngIf="f.password.errors.required" translate="INPUT.VALIDATIONS.REQUIRED">Field required</div>
						<div *ngIf="f.password.errors.minlength" translate="PROFILE_USER.LABELS.MIN_LENGHT_6">Min lenght 6 chars</div>
					</div>
				</div>
				<div class="form-group">
						<label translate="AUTH.REGISTER.LANGUAGE">Language</label>
						<select test-id="language" class="form-control m-input m-input--square" type="text" name="language"
						formControlName="language"	
						[ngClass]="{ 'is-invalid': f.language.invalid && f.language.touched }"
						required>
							<option value="en" selected = "true"  translate="AUTH.REGISTER.OPTION_1">English</option>
							<option value="es" translate="AUTH.REGISTER.OPTION_2">Spanish</option>
					</select>
						<div *ngIf="f.language.errors && f.language.touched" class="invalid-feedback">
								<div *ngIf="f.language.errors.required" translate="INPUT.VALIDATIONS.REQUIRED">Field required</div>
						</div>
					</div>

				<div class="form-group check-style">
						<!-- <label>Company</label> -->
						<label class="m-checkbox" >
						<input test-id="tyc" class="form-control m-input m-input--square" type="checkbox" name="tyc" 
						formControlName="tyc"	
						[ngClass]="{ 'is-invalid': f.tyc.invalid && f.tyc.touched }"
						required >
						<label translate="AUTH.REGISTER.TERMS"> I agree to My Digital Card’s </label>&nbsp;
						<a href="javascript:;"  (click)="forgotPasswordPage('terms')" class="m-link im-link__danger">
								<span translate="AUTH.REGISTER.TERMS_LINK_1"> Terms of Service </span>
						</a> 
						&nbsp;<label translate="AUTH.REGISTER.AND"> and </label>&nbsp;
						<a href="javascript:;" (click)="forgotPasswordPage('privacy')" class="m-link im-link__danger">
								<span translate="AUTH.REGISTER.TERMS_LINK_2"> Privacy Policy. </span>
						</a> 
						<span></span>
						<div *ngIf="f.tyc.errors && f.tyc.touched" class="invalid-feedback">
								<div *ngIf="f.tyc.errors.required" translate="INPUT.VALIDATIONS.REQUIRED">Field required</div>
						</div>
						</label>
				</div>
				<button color="primary" class="btn-block mat-raised-button mat-primary"
				[disabled]="processing" [ngClass]="{'m-loader m-loader--light m-loader--left': processing}"
				mat-raised-button>
				<span *ngIf="!processing"  translate="AUTH.REGISTER.BTN_REGISTER">Processing</span>
				<span *ngIf="processing" translate="GENERAL.PROCESSING">Processing</span>
				<!-- <span translate="AUTH.REGISTER.BTN_REGISTER"> Start registration process</span> -->
			</button>
			</form>
			<div class="text-center signup_link"><span translate="AUTH.REGISTER.ALREADY">Already have an account?</span>&nbsp;
				<!-- <a href="" translate="AUTH.GENERAL.SING_UP_LINK" class="im-link__danger">Sing Up</a> -->
				<a href="javascript:;" routerLink="/login"  class="im-link__danger"><span translate="AUTH.REGISTER.LOGIN">Log in</span></a>
			</div>
		</div>
		<!--end::Signin-->
		</div>
</div>
<div class="m-grid__item   m-grid__item--order-tablet-and-mobile-2 m-grid m-grid--hor m-login__aside" style="background-image: url(assets/app/media/img/bg/bg-4.jpg); background-size: cover">
	<div class="m-grid__item">
	</div>

	<div class="m-grid__item m-grid__item--fluid m-grid m-grid--ver">
		<div class="m-grid__item m-grid__item--middle">
		</div>
	</div>
</div>
</div>
