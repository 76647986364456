import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CoreModule } from '../../core/core.module';
import { PartialsModule } from '../partials/partials.module';
import { CodeComponent } from './auth/code/code.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { RegisterComponent } from './auth/register/register.component';
import { PagesComponent } from './pages.component';
import { ErrorPageComponent } from './snippets/error-page/error-page.component';
import { LayoutModule } from '../layout/layout.module';



@NgModule({
	declarations: [
		PagesComponent,
		ErrorPageComponent,
		RegisterComponent,
		ForgotPasswordComponent,
		CodeComponent,
	],
	imports: [
		LayoutModule,
		CommonModule,
		HttpClientModule,
		FormsModule,
		CoreModule,
		RouterModule,
		TranslateModule.forChild(),
		ReactiveFormsModule,
		PartialsModule,
	],
	exports: [
	],
	providers: []
})
export class PagesModule {
}
