<!-- BEGIN: Topbar -->
<!-- <div class="m-stack__item m-stack__item--fluid m-header-head" id="m_header_nav"> -->
<div id="m_header_topbar" class="m-topbar m-stack m-stack--ver m-stack--general">
	<div class="m-stack__item m-topbar__nav-wrapper">
		<ul class="m-topbar__nav m-nav m-nav--inline">
			<m-search-dropdown *ngIf="searchType === 'search-dropdown'"></m-search-dropdown>
			<m-search-default *ngIf="searchType === 'search-default'"></m-search-default>
			<div id="m_header_menu" class="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas  m-header-menu--skin-light m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-dark m-aside-header-menu-mobile--submenu-skin-dark ">	
				<ul class="m-menu__nav  m-menu__nav--submenu-arrow ">
					<li
						*ngFor="let item of headerOptions.items"
						class="m-menu__item  m-menu__item--submenu m-menu__item--rel"
						m-menu-submenu-toggle="click"
						m-menu-link-redirect="1"
						aria-haspopup="true"
					>
						<a
							[routerLink]="item.page"
							routerLinkActive="active"
							class="m-menu__link"
							[routerLinkActiveOptions]="{exact: item.exact}"
						>
							<i [class]="'m-menu__link-icon ' + item.icon"></i>
							<span class="m-menu__link-text" [translate]="item.translate">{{ item.title }}</span>
						</a>
					</li>
				</ul>
			</div>
			<m-notification></m-notification>
			<m-quick-action></m-quick-action>
			<m-language-selector></m-language-selector>
			<m-user-profile></m-user-profile>

			<!-- TODO; if layout config quick_sidebar.display is enabled -->
			<!-- <li id="m_quick_sidebar_toggle" class="m-nav__item">
				<a href="javascript:;" class="m-nav__link m-dropdown__toggle">
					<span class="m-nav__link-icon">
						<i class="flaticon-grid-menu"></i>
					</span>
				</a>
			</li> -->
		</ul>
	</div>
</div>
<!-- </div> -->
<!-- END: Topbar -->
