var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LayoutConfigService } from '../../../core/services/layout-config.service';
import * as objectPath from 'object-path';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from '../../../core/services/company.service';
var FooterComponent = /** @class */ (function () {
    function FooterComponent(configService, translate, cdr, companyService) {
        var _this = this;
        this.configService = configService;
        this.translate = translate;
        this.cdr = cdr;
        this.companyService = companyService;
        this.classes = 'm-grid__item m-footer';
        this.footerContainerClass$ = new BehaviorSubject('');
        this.configService.onLayoutConfigUpdated$.subscribe(function (model) {
            var config = model.config;
            var pageBodyClass = '';
            var selfLayout = objectPath.get(config, 'self.layout');
            if (selfLayout === 'boxed' || selfLayout === 'wide') {
                pageBodyClass += 'm-container--responsive m-container--xxl';
            }
            else {
                pageBodyClass += 'm-container--fluid';
            }
            _this.footerContainerClass$.next(pageBodyClass);
        });
    }
    FooterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.companyService.getTermsAndContidtios().subscribe(function (response) {
            _this.termsAndPrivacy = response[0];
        }, function (e) {
        });
    };
    FooterComponent.prototype.forgotPasswordPage = function (type) {
        return __awaiter(this, void 0, void 0, function () {
            var lang;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.translate.stream('GENERAL').subscribe(function (res) {
                            lang = res.lang;
                        })];
                    case 1:
                        _a.sent();
                        if (lang === 'es') {
                            this.titleTerms = 'Términos de servicio';
                            this.messageTerms = this.termsAndPrivacy.terms_es;
                            this.titlePrivicy = 'Política de privacidad';
                            this.messagePrivicy = this.termsAndPrivacy.policy_es.replace('<a', '<a style="color: red;" href');
                        }
                        if (lang === 'en') {
                            this.titleTerms = 'Terms of Service';
                            this.messageTerms = this.termsAndPrivacy.terms_en;
                            this.titlePrivicy = 'Privacy Policy';
                            this.messagePrivicy = this.termsAndPrivacy.policy_en.replace('<a', '<a style="color: red;" href');
                        }
                        switch (type) {
                            case 'terms':
                                $('#myModal').modal();
                                this.cdr.detectChanges();
                                $('.modal-a-color-primary a').css('color', '#6a25c6');
                                // swal( titleTerms, messageTerms);
                                break;
                            case 'privacy':
                                $('#myModal2').modal();
                                this.cdr.detectChanges();
                                $('.modal-a-color-primary a').css('color', '#6a25c6');
                                break;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return FooterComponent;
}());
export { FooterComponent };
