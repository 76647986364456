import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { AuthenticationService } from "../auth/authentication.service";
import { Observable } from "rxjs";

@Injectable()
export class UserService {
  me: any;
  constructor(private http: HttpClient, private auth: AuthenticationService) {}

  getList(page = 5) {
    return this.http.get(
      `${environment.apiUrl}/user?filterColumn=name&filterValue=&page=1&pageSize=100000&sortField=name&sortOrder=asc`
    );
  }

  getMe(): Observable<any> {
    const observable = this.http.get(`${environment.apiUrl}/auth/me`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    observable.subscribe((data: any) => { console.log("It"); }, err => {  console.log("It2"); });
    return observable;
  }

  createUser(values) {
    return this.http.post(`${environment.apiUrl}/user`, values);
  }

  getById(id) {
    return this.http.get(`${environment.apiUrl}/user/${id}`);
  }

  updateById(id, data) {
    return this.http.put(`${environment.apiUrl}/user/${id}`, data);
  }

  getGroups() {
    return this.http.get(
      `${environment.apiUrl}/groups?filterColumn=&filterValue=&page=1&pageSize=100000000000&sortField=created_at&sortOrder=desc`
    );
  }

  createGroup(data) {
    return this.http.post(`${environment.apiUrl}/groups`, data);
  }

  removeById(id) {
    return this.http.delete(`${environment.apiUrl}/user/${id}`);
  }

  getGroupById(id) {
    return this.http.get(`${environment.apiUrl}/groups/${id}`);
  }

  removeGroupById(id) {
    return this.http.delete(`${environment.apiUrl}/groups/${id}`);
  }

  associateUsersToGroup(it_groups_users_id: number, it_users_id: number[]) {
    return this.http.post(`${environment.apiUrl}/groups/users/associate`, {
      it_groups_users_id,
      it_users_id
    });
  }

  toggleActive(id) {
    return this.http.post(`${environment.apiUrl}/user/switch/status/${id}`, {});
  }

  updateImageProfile(id, data) {
    return this.http.post(
      `${environment.apiUrl}/user/profile_image/update/${id}`,
      data
    );
  }

  updateGroup(id, data) {
    return this.http.put(`${environment.apiUrl}/groups/${id}`, data);
  }

  uploadMassive(file) {
		const fd = new FormData();
		fd.append('file', file);
		return this.http.post(`${environment.apiUrl}/users/store/mass`, fd, {
			headers: {
				Accept: 'application/json'
			}
		});
	}
	

  activateMassive(file, type) {
    const fd = new FormData();
    fd.append("file", file);
    fd.append("type", type);
    return this.http.post(`${environment.apiUrl}/user/mass/status`, fd);
  }
}
