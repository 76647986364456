var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../../../../core/auth/authentication.service';
import { AuthNoticeService } from '../../../../core/auth/auth-notice.service';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from '../../../../core/services/company.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { environment } from '../../../../../environments/environment';
/**
 * @memberof AuthModule
 * Componente para el registro de nuevos usuarios
 */
var RegisterComponent = /** @class */ (function () {
    /**
     *
     * @param authService Servicio encargado de gestionar la autenticación
     * @param authNoticeService Servicio encargado de gestionar las notificaciones mostradas al usuario
     * @param translate Servicio encargado proveer traducciones
     * @param companyService Servicio encargado de gestionar los datos de la compañia por medio del api inmovtech
     * @param router Servicio encargado de gestionar las rutas dentro de la aplicación
     * @param formBuilder Servicio de angular para la generación de formularios
     * @param auth Servicio encargado de gestionar la autenticación del usuario de cara al api inmovtech
     * @param cdr Servicio encargado de la detección de cambios de angular
     */
    function RegisterComponent(authService, authNoticeService, translate, companyService, router, formBuilder, auth, cdr) {
        this.authService = authService;
        this.authNoticeService = authNoticeService;
        this.translate = translate;
        this.companyService = companyService;
        this.router = router;
        this.formBuilder = formBuilder;
        this.auth = auth;
        this.cdr = cdr;
        /**
         * Indica si el formulario se esta procesando
         */
        this.processing = false;
        /**
         * Datos que han sido ingresados por el usuario al formulario
         */
        this.model = { email: '' };
        /**
         * Evento ejecutado al momento en que cambia la acción ejecutada por la aplicación
         */
        this.actionChange = new Subject();
        /**
         * Variable que indica si se esta cargando información del servidor
         */
        this.loading = false;
        /**
         * Listado de errores encontrados al momento de tratar de enviar el formulario de registro
         */
        this.errors = [];
        /**
         * Indica si el formulario se ha procesado con anterioridad
         */
        this.submitted = false;
        /**
         * Indica si el registro se esta realizando con redes sociales
         */
        this.social = false;
        /**
         * Configuración del spinner de carga del botón de envio del formulario
         */
        this.spinner = {
            active: false,
            spinnerSize: 18,
            raised: true,
            buttonColor: 'primary',
            spinnerColor: 'accent',
            fullWidth: false
        };
    }
    /**
     * Inicializa el formulario de registro con sus respectivas validaciones
     */
    RegisterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.companyService.getTermsAndContidtios().subscribe(function (response) {
            _this.termsAndPrivacy = response[0];
        }, function (e) {
            console.log('e', e);
        }, function () {
            console.log('Termino');
        });
        this.translate.get('lang', { value: 'lang' }).subscribe(function (data) { });
        gapi.load('auth2', function () {
            _this.auth2 = gapi.auth2.init({
                client_id: environment.googleClientId,
                cookiepolicy: 'single_host_origin',
            });
            _this.attachSignin(document.getElementById('customBtn2'));
        });
        this.form = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            company: ['', Validators.required],
            tyc: new FormControl(false, Validators.requiredTrue),
            language: [this.translate.getBrowserLang(), Validators.required]
            // logo: ''
        });
    };
    Object.defineProperty(RegisterComponent.prototype, "f", {
        /**
         * @ignore
         */
        get: function () { return this.form.controls; },
        enumerable: true,
        configurable: true
    });
    /**
     * Redirigir a la pagina de inicio de sesión
     * @param event
     */
    RegisterComponent.prototype.loginPage = function (event) {
        event.preventDefault();
        this.action = 'login';
        this.actionChange.next(this.action);
    };
    /**
     * Inicializa el plugin de inicio de sesión con google
     * @param element
     */
    RegisterComponent.prototype.attachSignin = function (element) {
        var _this = this;
        this.auth2.attachClickHandler(element, {}, function (googleUser) {
            var basicProfile = googleUser.getBasicProfile();
            _this.form.patchValue({
                email: basicProfile.getEmail(),
                first_name: basicProfile.getGivenName(),
                last_name: basicProfile.getFamilyName(),
                password: '123456'
            });
            _this.social = true;
            _this.cdr.detectChanges();
        }, function (error) {
        });
    };
    /**
     * Manejador de eventos para el envio del formulario de registro
     */
    RegisterComponent.prototype.onSubmit = function () {
        var _this = this;
        this.cdr.detectChanges();
        this.submitted = true;
        if (!this.form.valid) {
            Object.keys(this.form.controls).forEach(function (field) {
                var control = _this.form.get(field);
                control.markAsTouched({ onlySelf: true });
            });
            return;
        }
        this.processing = true;
        var values = __assign({}, this.form.value);
        if (this.social) {
            values.type_register = 'S';
        }
        else {
            values.type_register = 'M';
        }
        var errorCallback = function (e) {
            var errorMsj = '';
            switch (e.status) {
                case 401:
                    e.error.errors.messages.forEach(function (element) {
                        errorMsj = element;
                    });
                    break;
                case 422:
                    var myText_1 = '';
                    var array = Object.keys(e.error.errors).map(function (key) {
                        myText_1 = myText_1 + " " + e.error.errors[key][0];
                    });
                    for (var index = 0; index < array.length; index++) {
                        if (index + 1 == array.length) {
                            errorMsj = myText_1;
                        }
                    }
                    break;
                default:
                    errorMsj = 'Error conecting to server';
            }
            _this.processing = false;
            _this.cdr.detectChanges();
            swal('Error', errorMsj, 'error');
            // alert('Error connecting to server please try later')
        };
        var successCallback = function (data) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            var successMsj, myText_2, array, index;
            return __generator(this, function (_a) {
                this.processing = false;
                console.log(data);
                successMsj = '';
                if (data.errors) {
                    myText_2 = "";
                    array = Object.keys(data.errors).map(function (key) {
                        myText_2 = data.errors[key][0];
                    });
                    for (index = 0; index < array.length; index++) {
                        if (index + 1 == array.length) {
                            successMsj = myText_2;
                        }
                    }
                }
                else {
                    successMsj = "Error conecting to server (S)";
                    // return;
                }
                this.form.reset();
                this.translate.get([
                    'GENERAL.SUCCESS',
                ]).subscribe(function (t) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, swal(t['GENERAL.SUCCESS'], successMsj, 'success')];
                            case 1:
                                _a.sent();
                                this.router.navigate(['/login']);
                                return [2 /*return*/];
                        }
                    });
                }); });
                this.cdr.detectChanges();
                return [2 /*return*/];
            });
        }); };
        this.companyService.createCompany(values).subscribe(successCallback, errorCallback);
    };
    /**
     * Se encarga de mostrar los terminos y condiciones o las políticas de privacidad según el caso
     * @param type
     */
    RegisterComponent.prototype.forgotPasswordPage = function (type) {
        return __awaiter(this, void 0, void 0, function () {
            var lang;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log(this.translate.getBrowserLang());
                        return [4 /*yield*/, this.translate.stream('GENERAL').subscribe(function (res) {
                                lang = res.lang;
                            })];
                    case 1:
                        _a.sent();
                        if (lang === 'es') {
                            this.titleTerms = 'Términos de servicio';
                            this.messageTerms = this.termsAndPrivacy.terms_es;
                            this.titlePrivicy = 'Política de privacidad';
                            this.messagePrivicy = this.termsAndPrivacy.policy_es.replace('<a', '<a style="color: red;" href');
                        }
                        if (lang === 'en') {
                            this.titleTerms = 'Terms of Service';
                            this.messageTerms = this.termsAndPrivacy.terms_en;
                            this.titlePrivicy = 'Privacy Policy';
                            this.messagePrivicy = this.termsAndPrivacy.policy_en.replace('<a', '<a style="color: red;" href');
                        }
                        console.log(this.termsAndPrivacy.policy_es.replace('<a', '<a style="color: red;" href'));
                        switch (type) {
                            case 'terms':
                                $('#myModal').modal();
                                this.cdr.detectChanges();
                                $('.modal-a-color-primary a').css('color', '#6a25c6');
                                // swal( titleTerms, messageTerms);
                                break;
                            case 'privacy':
                                $('#myModal2').modal();
                                this.cdr.detectChanges();
                                $('.modal-a-color-primary a').css('color', '#6a25c6');
                                break;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return RegisterComponent;
}());
export { RegisterComponent };
