/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./menu-section.component";
var styles_MenuSectionComponent = [];
var RenderType_MenuSectionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MenuSectionComponent, data: {} });
export { RenderType_MenuSectionComponent as RenderType_MenuSectionComponent };
export function View_MenuSectionComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h4", [["class", "m-menu__section-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"])), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["class", "m-menu__section-icon flaticon-more-v3"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.section; _ck(_v, 1, 0, currVal_0); }); }
export function View_MenuSectionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "m-menu-section", [], [[8, "className", 0]], null, null, View_MenuSectionComponent_0, RenderType_MenuSectionComponent)), i0.ɵdid(1, 114688, null, 0, i1.MenuSectionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
var MenuSectionComponentNgFactory = i0.ɵccf("m-menu-section", i1.MenuSectionComponent, View_MenuSectionComponent_Host_0, { item: "item" }, {}, []);
export { MenuSectionComponentNgFactory as MenuSectionComponentNgFactory };
