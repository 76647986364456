/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../core/directives/menu-aside-toggle.directive";
import * as i3 from "@angular/router";
import * as i4 from "./brand.component";
import * as i5 from "../../../../core/services/class-init.service";
import * as i6 from "../../../../core/services/layout-config.service";
var styles_BrandComponent = [];
var RenderType_BrandComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BrandComponent, data: {} });
export { RenderType_BrandComponent as RenderType_BrandComponent };
function View_BrandComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "a", [["class", "m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block"], ["href", "javascript:;"], ["id", "m_aside_left_minimize_toggle"], ["mMenuAsideToggle", ""]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "m-brand__toggler--active": 0 }), i0.ɵdid(3, 4341760, null, 0, i2.MenuAsideToggleDirective, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 0, "span", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block"; var currVal_1 = _ck(_v, 2, 0, _co.menuAsideMinimizeDefault); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_BrandComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block"], ["href", "javascript:;"], ["id", "m_aside_left_offcanvas_toggle"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [], null, null, null, null, null))], null, null); }
export function View_BrandComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "m-stack m-stack--ver m-stack--general"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "m-stack__item m-stack__item--middle m-brand__logo"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "a", [["class", "m-brand__logo-wrapper"], ["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 0, "img", [["alt", ""]], [[1, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "m-stack__item m-stack__item--middle m-brand__tools"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BrandComponent_1)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BrandComponent_2)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/"; _ck(_v, 3, 0, currVal_2); var currVal_4 = _co.menuAsideMinimizToggle; _ck(_v, 7, 0, currVal_4); var currVal_5 = _co.menuAsideDisplay; _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 3).target; var currVal_1 = i0.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _co.headerLogo; _ck(_v, 4, 0, currVal_3); }); }
export function View_BrandComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "m-brand", [], [[8, "className", 0]], null, null, View_BrandComponent_0, RenderType_BrandComponent)), i0.ɵdid(1, 114688, null, 0, i4.BrandComponent, [i5.ClassInitService, i6.LayoutConfigService, i1.DOCUMENT], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
var BrandComponentNgFactory = i0.ɵccf("m-brand", i4.BrandComponent, View_BrandComponent_Host_0, { menuAsideLeftSkin: "menuAsideLeftSkin", menuAsideMinimizeDefault: "menuAsideMinimizeDefault", menuAsideMinimizToggle: "menuAsideMinimizToggle", menuAsideDisplay: "menuAsideDisplay", menuHeaderDisplay: "menuHeaderDisplay", headerLogo: "headerLogo" }, {}, []);
export { BrandComponentNgFactory as BrandComponentNgFactory };
