<div>
  <label for="" *ngIf="label">{{ label }}</label>
  <div
    style="position: relative"
    [ngClass]="{ 'invalid-container': invalid && type === 'select2' }"
  >
    <input
      #input
      *ngIf="type === 'text'"
      type="text"
      [ngClass]="{
        'form-control m-input m-input--square': true,
        'is-invalid': invalid
      }"
      [placeholder]="placeholder"
      [(ngModel)]="value"
      [disabled]="disabled"
    />
    <input
      #input
      *ngIf="type === 'tel'"
      type="tel"
      [ngClass]="{
        'form-control m-input m-input--square': true,
        'is-invalid': invalid
      }"
      [placeholder]="placeholder"
      [ngModel]="value"
      [disabled]="disabled"
    />
    <input
      #input
      *ngIf="type === 'date'"
      type="text"
      [ngClass]="{
        'form-control m-input m-input--square': true,
        'is-invalid': invalid
      }"
      [placeholder]="placeholder"
      [ngModel]="value"
      [disabled]="disabled"
    />
    <input
      #input
      *ngIf="type === 'datetime'"
      type="text"
      [ngClass]="{
        'form-control m-input m-input--square': true,
        'is-invalid': invalid
      }"
      [placeholder]="placeholder"
      [ngModel]="value"
      [disabled]="disabled"
    />
    <textarea
      #input
      *ngIf="type === 'textarea'"
      [ngClass]="{
        'form-control m-input m-input--square': true,
        'is-invalid': invalid
      }"
      [placeholder]="placeholder"
      [(ngModel)]="value"
      [disabled]="disabled"
    ></textarea>

    <select
      #select
      ngDefaultControl
      [disabled]="disabled"
      [attr.multiple]="multiple ? 'multiple' : null"
      [ngClass]="{
        'form-control m-input m-input--square': true,
        'is-invalid': invalid
      }"
      *ngIf="type === 'select2'"
      (change)="_onChangeCallback($event)"
    >
      <option
        [selected]="value == item.id"
        *ngFor="let item of select2Data; trackBy: trackByFn"
        [value]="item.id"
        >{{ item.text }}</option
      >
    </select>
    <i
      *ngIf="iconclass"
      [class]="iconclass + ' form-control-feedback'"
      style="font-size: 22px; color: #A8A9B6; transform: translateY(-50%);"
    ></i>
  </div>
</div>
