import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Injector,
  Input,
  NgZone,
  OnInit,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import {
  NgbCalendar,
  NgbDatepicker,
  NgbDateStruct
} from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import autosize from "autosize";
import flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import _ from "lodash";
import moment from "moment";

// @ts-nocheck
// @ts-nocheck

export function generate_control_value_accessor(InputClass) {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputClass),
    multi: true
  };
}
export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputWithIconComponent),
  multi: true
};

const callback = () => {};

@Component({
  selector: "m-input-with-icon",
  templateUrl: "./input-with-icon.component.html",
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  styleUrls: ["./input-with-icon.component.scss"]
})
export class InputWithIconComponent implements OnInit, ControlValueAccessor {
  @Input() iconclass: string;
  @Input() placeholder: string = "";
  @Input() label: string;
  @Input() public select2Value: string;
  @Input() type: string = "text";
  @Input() change: Function;
  @Input() minDate: Date;
  @Input() initialCountry: string;
  @Input() maxDate: Date;
  @Input() select2Data: any[] = [];
  @Input() valueLoaded: boolean = true;
  @Input() datePickerStartView: string = "0, 'month'";
  @Input() multiple: boolean;
  @Input() invalid: boolean;
  @Input() async: boolean = false;
  @Input() disabled: boolean;
  @ViewChild("select") select: ElementRef;
  @ViewChild("d") d: NgbDatepicker;
  @ViewChild("input") input: ElementRef;

  private _onChangeCallback: (_: any) => void = callback;
  private _onTouchedCallback: () => void = callback;

  model: NgbDateStruct;
  _innerState: any;

  select2options: any = {
    containerCssClass: "is-invalid"
  };

  lang = "en";

  calendar: NgbCalendar;
  translate: TranslateService;
  cdr: ChangeDetectorRef;
  zone: NgZone;

  constructor(injector: Injector) {
    this.calendar = injector.get(NgbCalendar);
    this.translate = injector.get(TranslateService);
    this.cdr = injector.get(ChangeDetectorRef);
    this.zone = injector.get(NgZone);
    this.select2options = {
      multiple: this.multiple,
      containerCssClass: "is-invalid"
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type) {
      this.type = changes.type.currentValue;
    }
    if (changes.invalid) {
      this.invalid = changes.invalid.currentValue;
    }
    if (changes.select2Data) {
      this.zone.run(() => {
        this.select2Data = changes.select2Data.currentValue;
      });
    }
    if (changes.minDate && this.type === "datetime") {
      if (this.flatpickrInstance) {
        this.flatpickrInstance.set("minDate", changes.minDate.currentValue);
      }
    }
  }

  public writeValue = (value: any) => {
    let formatedValue = value;
    if (
      (this.type == "date" || this.type === "datetime") &&
      moment(value).isValid()
    ) {
      if (this.type === "datetime") {
        formatedValue = moment(value).format("YYYY-MM-DD HH:mm:ss");
      } else {
        formatedValue = moment(value).format("YYYY-MM-DD");
      }
    }

    if (this.type === "datetime") {
      _.defer(() => {
        if (formatedValue) {
          this.flatpickrInstance.setDate(formatedValue);
          this.flatpickrInstance.jumpToDate(formatedValue);
        }
      });
    }
    if (value !== this._innerState) {
      this._innerState = formatedValue;
      this._onChangeCallback(formatedValue);
    }
    if (this.type == "select2") {
      _.defer(() => {
        $(this.select.nativeElement).val(value);
        this.initializeSelect2();
      });
    }
  };

  get value(): any {
    return this._innerState;
  }

  set value(v: any) {
    this.writeValue(v);
  }

  public registerOnChange(fn: any) {
    this._onChangeCallback = fn;
  }

  public registerOnTouched(fn: any) {
    this._onTouchedCallback = fn;
  }

  public refreshValue(value: any): void {
    this.value = value;
  }

  getOptions() {
    return {
      multiple: this.multiple
    };
  }

  es: any;

  iti: any;

  flatpickrInstance: any;

  initializePhoneInput = () => {
    const options = {
      initialCountry: this.initialCountry ? this.initialCountry : "auto",
      geoIpLookup: function(success, failure) {
        $.get("https://ipinfo.io", function() {}, "jsonp").always(function(
          resp
        ) {
          var countryCode = resp && resp.country ? resp.country : "";
          success(countryCode);
        });
      },
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/14.0.8/js/utils.js",
      formatOnDisplay: true
    };
    _.defer(() => {
      // @ts-ignore
      this.iti = window.intlTelInput(this.input.nativeElement, options);
    });
  };

  initializeDateInput() {
    _.defer(() => {
      const $element = $(this.input.nativeElement);
      // @ts-ignore
      $element.datepicker({
        format: "yyyy-mm-dd",
        language: this.translate.currentLang,
        autoclose: true,
        endDate: this.maxDate,
        startView: "decade",
        startDate: this.minDate
      });
      $element.on("changeDate", (event: any) => {
        this.writeValue(event.target.value);
      });
    });
  }

  initializeDatetime() {
    const dateTimeChange = (selectedDates, dateStr, instance) => {
      if (dateStr) {
        this.writeValue(dateStr);
      }
    };
    _.defer(() => {
      // @ts-ignore
      this.flatpickrInstance = flatpickr(this.input.nativeElement, {
        enableTime: true,
        enableSeconds: true,
        time_24hr: true,
        onValueUpdate: dateTimeChange,
        minDate: this.minDate,
        maxDate: this.maxDate,
        locale: this.translate.currentLang === "es" && Spanish
      });
    });
  }

  ngOnInit() {
    if (this.type === "tel") {
      this.initializePhoneInput();
    }
    if (this.type === "date") {
      this.initializeDateInput();
    }
    if (this.type === "select2") {
      this.initializeSelect2();
    }
    if (this.type === "datetime") {
      this.initializeDatetime();
    }
    if (this.type === "textarea") {
      _.defer(() => {
        autosize(this.input.nativeElement);
      });
    }
  }

  initializeSelect2() {
    if (this.type === "select2") {
      _.defer(() => {
        const $element = $(this.select.nativeElement);
        // @ts-ignore
        $element.select2(this.getOptions());
        $element.on("change", () => {
          this.writeValue($element.val());
          this.cdr.markForCheck();
        });
      });
    }
  }

  trackByFn(i, item) {
    return item.id;
  }
}
