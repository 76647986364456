import { TranslateService } from "@ngx-translate/core";
import * as R from "ramda";
import swal from "sweetalert2";
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
var LaravelValidationsService = /** @class */ (function () {
    function LaravelValidationsService(translate) {
        this.translate = translate;
    }
    LaravelValidationsService.prototype.alertErrorList = function (ex) {
        var messageList = R.path(["error", "errors"], ex);
        var errors = "";
        if (messageList) {
            if (messageList.messages && Array.isArray(messageList.messages)) {
                errors = messageList.messages.map(function (e) { return "<li>" + e + "</li>"; }).join("");
            }
            else if (messageList.messages &&
                typeof messageList.messages == "string") {
                errors = messageList.messages;
            }
            else {
                errors = R.values(R.mapObjIndexed(function (x) { return x[0]; }, messageList))
                    .map(function (e) { return "<li>" + e + "</li>"; })
                    .join("");
            }
            swal(this.translate.instant("GENERAL.FIX_THIS_ERRORS"), "\n          <ul>\n            " + errors + "\n          </ul>\n        ", "error");
            return;
        }
    };
    LaravelValidationsService.prototype.alertSingleError = function (ex) {
        var message = R.path(["error", "messages", 0], ex);
        if (message) {
            swal(this.translate.instant("ERRORS.ERROR"), message, "error");
            return;
        }
    };
    LaravelValidationsService.prototype.alert500 = function (ex) {
        if (ex.code === 500) {
            swal(this.translate.instant("ERRORS.ERROR"), this.translate.instant("ERRORS.ERROR_CONNECTING"), "error");
            return;
        }
        if (ex.status === 500) {
            swal(this.translate.instant("ERRORS.ERROR"), ex.message, "error");
            return;
        }
    };
    LaravelValidationsService.prototype.alert429 = function (ex) {
        if (ex.code === 429) {
            swal(this.translate.instant("ERRORS.ERROR"), this.translate.instant("ERRORS.ERROR_CONNECTING"), "error");
            return;
        }
    };
    LaravelValidationsService.prototype.alert401 = function (ex) {
        if (ex.code === 401) {
            swal(this.translate.instant("ERRORS.ERROR"), this.translate.instant("ERRORS.ERROR_CONNECTING"), "error");
            return;
        }
    };
    LaravelValidationsService.prototype.alertErrors = function (ex) {
        this.alert500(ex);
        this.alert429(ex);
        this.alert401(ex);
        this.alertErrorList(ex);
        this.alertSingleError(ex);
    };
    LaravelValidationsService.ngInjectableDef = i0.defineInjectable({ factory: function LaravelValidationsService_Factory() { return new LaravelValidationsService(i0.inject(i1.TranslateService)); }, token: LaravelValidationsService, providedIn: "root" });
    return LaravelValidationsService;
}());
export { LaravelValidationsService };
