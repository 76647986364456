// tslint:disable-next-line:no-shadowed-variable
import { ConfigModel } from '../core/interfaces/config';

// tslint:disable-next-line:no-shadowed-variable
export class MenuConfig implements ConfigModel {
	public config: any = {};

	constructor() {
		this.config = {
			header: {
				self: {},
				items: [
				]
			},
			aside: {
				self: {},
				items: [
					{
						title: 'Dashboard',
						desc: 'Some description goes here',
						root: true,
						icon: 'fas fa-home',
						page: '/',
						// badge: {type: 'm-badge--danger', value: '2'},
						translate: 'MENU.DASHBOARD',
						submenu: [
							{
								title: 'Home',
								// tooltip: 'Home panel',
								page: '/',
								translate: 'MENU.SUBMENUS.HOME',
							},
							// {
							// 	title: 'Contacts list',
							// 	// tooltip: 'Contacts',
							// 	page: '/dashboard/contacts',
							// 	translate: 'MENU.SUBMENUS.CONTACT_LIST',
							// },
							// {
							// 	title: 'Map',
							// 	// tooltip: 'Map MDC',
							// 	page: '/dashboard/maps',
							// 	translate: 'MENU.SUBMENUS.MAP',
							// },
							// {
							// 	title: 'Contact Company/Position',
							// 	// tooltip: 'Contacts',
							// 	page: '/dashboard/positions',
							// 	translate: 'MENU.SUBMENUS.CONTACT_COMPANY',
							// },
						]
					},
					// {
					// 	title: 'Company',
					// 	desc: 'Some description goes here',
					// 	// root: true,
					// 	icon: 'far fa-building',
					// 	// page: '/',
					// 	// badge: {type: 'm-badge--danger', value: '2'},
					// 	translate: 'MENU.COMPANY',
					// 	submenu: [
					// 		{
					// 			title: 'My Company',
					// 			// tooltip: 'Company',
					// 			translate: 'MENU.SUBMENUS.MY_COMPANY',
					// 			page: '/company/my-company',
					// 		},
					// 		{
					// 			title: 'Branches',
					// 			// tooltip: 'branche',
					// 			translate: 'MENU.SUBMENUS.BRANCHES',
					// 			page: '/company/branches',
					// 		},
					// 		{
					// 			title: 'Company Position',
					// 			// tooltip: 'Map MDC',
					// 			translate: 'MENU.SUBMENUS.COMPANY_POSITION',
					// 			page: '/company/company-position',
					// 		}
					// 	]
					// },
					{
						title: 'Speakers',
						desc: 'Speakers ',
						// root: true,
						icon: 'far fa-street-view',
						// page: '/',
						// badge: {type: 'm-badge--danger', value: '2'},
						translate: 'MENU.SPEAKERS',
						submenu: [
							/*{
								title: 'My Company',
								// tooltip: 'Company',
								translate: 'MENU.SUBMENUS.MY_COMPANY',
								page: '/company/my-company',
							},
							{
								title: 'Branches',
								// tooltip: 'branche',
								translate: 'MENU.SUBMENUS.BRANCHES',
								page: '/company/branches',
							},*/
							{
								title: 'Speaker List',
								// tooltip: 'Map MDC',
								translate: 'MENU.SUBMENUS.SPEAKERS_LIST',
								page: '/speakers/list',
							}
						]
					},
					{
						title: 'schedule',
						desc: 'schedule ',
						// root: true,
						icon: 'far fa-calendar-alt',
						translate: 'MENU.SCHEDULE',
						submenu: [
							{
								title: 'General Schedule',
								// tooltip: 'Map MDC',
								translate: 'MENU.SUBMENUS.SCHEDULE_LIST',
								page: '/schedule/list',
							}
						]
					},
					{
						title: 'events',
						desc: 'events ',
						// root: true,
						icon: 'fas fa-calendar-check',
						translate: 'MENU.EVENTS',
						submenu: [
							{
								title: 'General Events',
								// tooltip: 'Map MDC',
								translate: 'MENU.SUBMENUS.EVENTS_LIST',
								page: '/events/list',
							}
						]
					},
					{
						title: 'materials',
						desc: 'materials ',
						// root: true,
						icon: 'far fa-file',
						translate: 'MENU.MATERIALS',
						submenu: [
							{
								title: 'General Materials',
								// tooltip: 'Map MDC',
								translate: 'MENU.SUBMENUS.MATERIALS_LIST',
								page: '/materials/list',
							}
						]
					},
					{ // users
						title: 'User',
						desc: 'Some description goes here',
						root: true,
						icon: 'far fa-user',
						// page: '/',
						// badge: {type: 'm-badge--danger', value: '2'},
						translate: 'MENU.USERS',
						submenu: [
							{
								title: 'User list',
								// tooltip: 'users',
								page: '/users/user-list',
								translate: 'MENU.SUBMENUS.USER_LIST',
							},
							{
								title: 'User Group',
								// tooltip: 'groups',
								page: '/users/user-group',
								translate: 'MENU.SUBMENUS.USER_GROUP',
							},
							{
								title: 'Create User',
								// tooltip: 'groups',
								page: '/users/new-user',
								translate: 'MENU.SUBMENUS.NEW_USERS',
							},
							{
								title: 'Upload users',
								// tooltip: 'groups',
								page: '/users/upload-users',
								translate: 'MENU.SUBMENUS.UPLOAD_USERS',
							},
							{
								title: 'Activar o desactivar usuarios',
								// tooltip: 'groups',
								page: '/users/activate-mass',
							},
						]
					},
					{ // users
						title: 'Noticias',
						desc: 'Some description goes here',
						root: true,
						icon: 'far fa-newspaper',
						submenu: [
							{
								title: 'Categories',
								// tooltip: 'categories',
								page: '/news/categories',
								translate: 'MENU.SUBMENUS.CATEGORIES',
							},

							{
								title: 'Lista de noticias',
								// tooltip: 'news list',
								page: '/news/newslist',
							},
							{
								title: 'Galleries',
								page: '/news/gallery',
								translate: 'MENU.SUBMENUS.GALLERIES',
							}
						]
					},
					// { // users
					// 	title: 'Capacitaciones',
					// 	desc: 'Some description goes here',
					// 	root: true,
					// 	icon: 'far fa-newspaper',
					// 	submenu: [
					// 		{
					// 			title: 'Entries',
					// 			// tooltip: 'news list',
					// 			page: '/capacitation/newslist',
					// 			translate: 'MENU.SUBMENUS.NEW_LIST',
					// 		}
					// 	]
					// },
					// { // users
					// 	title: 'Encuestas',
					// 	desc: 'Some description goes here',
					// 	root: true,
					// 	icon: 'fab fa-wpforms',
					// 	submenu: [
					// 		{
					// 			title: 'Lista de encuestas',
					// 			// tooltip: 'news list',
					// 			page: '/surveys/list',
					// 		},
					// 		{
					// 			title: 'Nueva encuesta',
					// 			// tooltip: 'news list',
					// 			page: '/surveys/new',
					// 		},
					// 		/*{
					// 			title: 'Resultados',
					// 			// tooltip: 'news list',
					// 			page: '/surveys/results',
					// 		},*/
					// 	]
					// },
					{ // community
						title: 'Community',
						desc: 'Some description goes here',
						root: true,
						icon: 'fas fa-users',
						page: '/community',
						// badge: {type: 'm-badge--danger', value: '2'},
						translate: 'MENU.COMMUNITY',
					},
					{ // community
						title: 'Notifications',
						desc: 'Notifications',
						root: true,
						icon: 'fas fa-bell',
						translate: 'MENU.NOTIFICATIONS',
						submenu: [
							{
								title: 'Notifications List',
								// tooltip: 'categories',
								page: '/notification/notifications',
								translate: 'MENU.SUBMENUS.NOTIFICATIONS_LIST',
							},
							{
								title: 'Push Notifications',
								// tooltip: 'news list',
								page: '/notification/new-push',
								translate: 'MENU.SUBMENUS.PUSH_NOTIFICATION',
							},
						]
					},
					// { // notification
					// 	title: 'Notifications',
					// 	desc: 'Some description goes here',
					// 	root: true,
					// 	icon: 'far fa-bell',
					// 	// page: '/',
					// 	badge: {type: 'm-badge--danger', value: '2'},
					// 	translate: 'MENU.NOTIFICATIONS',
					// 	submenu: [
					// 		{
					// 			title: 'Notifications list',
					// 			// tooltip: 'notifications',
					// 			// page: '/',
					// 			translate: 'MENU.SUBMENUS.NOTIFICATIONS_LIST',
					// 		},
					// 		{
					// 			title: 'Push notifications',
					// 			// tooltip: 'branche',
					// 			translate: 'MENU.SUBMENUS.PUSH_NOTIFICATION',
					// 		}
					// 	]
					// },
					// {section: 'Tools'},
					// {
					// 	title: 'Layout Builder',
					// 	root: true,
					// 	icon: 'flaticon-settings',
					// 	page: '/builder'
					// }
				]
			}
		};
	}
}
