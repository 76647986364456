import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as R from "ramda";
import swal from "sweetalert2";

@Injectable({
  providedIn: "root"
})
export class LaravelValidationsService {
  constructor(private translate: TranslateService) {}

  alertErrorList(ex) {
    const messageList = R.path(["error", "errors"], ex);
    let errors = "";
    if (messageList) {
      if (messageList.messages && Array.isArray(messageList.messages)) {
        errors = messageList.messages.map(e => `<li>${e}</li>`).join("");
      } else if (
        messageList.messages &&
        typeof messageList.messages == "string"
      ) {
        errors = messageList.messages;
      } else {
        errors = R.values(R.mapObjIndexed(x => x[0], messageList))
          .map(e => `<li>${e}</li>`)
          .join("");
      }

      swal(
        this.translate.instant("GENERAL.FIX_THIS_ERRORS"),
        `
          <ul>
            ${errors}
          </ul>
        `,
        "error"
      );
      return;
    }
  }

  alertSingleError(ex) {
    const message = R.path(["error", "messages", 0], ex);
    if (message) {
      swal(this.translate.instant("ERRORS.ERROR"), message, "error");
      return;
    }
  }

  alert500(ex) {
    if (ex.code === 500) {
      swal(
        this.translate.instant("ERRORS.ERROR"),
        this.translate.instant("ERRORS.ERROR_CONNECTING"),
        "error"
      );
      return;
    }
    if (ex.status === 500) {
      swal(this.translate.instant("ERRORS.ERROR"), ex.message, "error");
      return;
    }
  }
  alert429(ex) {
    if (ex.code === 429) {
      swal(
        this.translate.instant("ERRORS.ERROR"),
        this.translate.instant("ERRORS.ERROR_CONNECTING"),
        "error"
      );
      return;
    }
  }
  alert401(ex) {
    if (ex.code === 401) {
      swal(
        this.translate.instant("ERRORS.ERROR"),
        this.translate.instant("ERRORS.ERROR_CONNECTING"),
        "error"
      );
      return;
    }
  }

  alertErrors(ex) {
    this.alert500(ex);
    this.alert429(ex);
    this.alert401(ex);
    this.alertErrorList(ex);
    this.alertSingleError(ex);
  }
}
