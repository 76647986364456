import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';
import { AuthenticationService } from '../auth/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
  ) { 

  }

  forgotPassword(data){
    return this.http.post(`${environment.apiUrl}/app/recovery/pass`, {
      ...data,
    })
  }

  // '/app/validate/token'
  validateToken(data){
    return this.http.post(`${environment.apiUrl}/app/validate/token`, {
      ...data,
    })
  }
}
