/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./aside-right.component";
var styles_AsideRightComponent = [];
var RenderType_AsideRightComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AsideRightComponent, data: {} });
export { RenderType_AsideRightComponent as RenderType_AsideRightComponent };
export function View_AsideRightComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h6", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Right Aside"])), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "m-separator m-separator--dashed m--space-10"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.\n"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.\n"]))], null, null); }
export function View_AsideRightComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "m-aside-right", [], [[8, "className", 0]], null, null, View_AsideRightComponent_0, RenderType_AsideRightComponent)), i0.ɵdid(1, 114688, null, 0, i1.AsideRightComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
var AsideRightComponentNgFactory = i0.ɵccf("m-aside-right", i1.AsideRightComponent, View_AsideRightComponent_Host_0, {}, {}, []);
export { AsideRightComponentNgFactory as AsideRightComponentNgFactory };
