var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AfterViewInit, ElementRef, OnInit, } from '@angular/core';
import { LayoutConfigService } from './core/services/layout-config.service';
import { ClassInitService } from './core/services/class-init.service';
import { TranslationService } from './core/services/translation.service';
import * as objectPath from 'object-path';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { PageConfigService } from './core/services/page-config.service';
import { filter } from 'rxjs/operators';
import { SplashScreenService } from './core/services/splash-screen.service';
import { AclService } from './core/services/acl.service';
// language list
import { locale as enLang } from './config/i18n/en';
import { locale as chLang } from './config/i18n/ch';
import { locale as esLang } from './config/i18n/es';
import { locale as jpLang } from './config/i18n/jp';
import { locale as deLang } from './config/i18n/de';
import { locale as frLang } from './config/i18n/fr';
import { AuthenticationService } from './core/auth/authentication.service';
import { NgxPermissionsService } from 'ngx-permissions';
// LIST KNOWN ISSUES
// [Violation] Added non-passive event listener; https://github.com/angular/angular/issues/8866
var AppComponent = /** @class */ (function () {
    function AppComponent(layoutConfigService, classInitService, sanitizer, translationService, router, pageConfigService, splashScreenService, aclService, auth, permissionsService) {
        var _this = this;
        this.layoutConfigService = layoutConfigService;
        this.classInitService = classInitService;
        this.sanitizer = sanitizer;
        this.translationService = translationService;
        this.router = router;
        this.pageConfigService = pageConfigService;
        this.splashScreenService = splashScreenService;
        this.aclService = aclService;
        this.auth = auth;
        this.permissionsService = permissionsService;
        this.title = 'Metronic';
        this.classes = '';
        // subscribe to class update event
        this.classInitService.onClassesUpdated$.subscribe(function (classes) {
            // get body class array, join as string classes and pass to host binding class
            setTimeout(function () { return _this.classes = classes.body.join(' '); });
        });
        this.layoutConfigService.onLayoutConfigUpdated$.subscribe(function (model) {
            _this.classInitService.setConfig(model);
            _this.style = '';
            if (objectPath.get(model.config, 'self.layout') === 'boxed') {
                var backgroundImage = objectPath.get(model.config, 'self.background');
                if (backgroundImage) {
                    _this.style = _this.sanitizer.bypassSecurityTrustStyle('background-image: url(' + objectPath.get(model.config, 'self.background') + ')');
                }
            }
            // splash screen image
            _this.splashScreenImage = objectPath.get(model.config, 'loader.image');
        });
        // register translations
        this.translationService.loadTranslations(enLang, chLang, esLang, jpLang, deLang, frLang);
        this.translationService.setLanguage(localStorage.getItem('language') || navigator.language.substr(0, 2));
        // override config by router change from pages config
        this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function (event) {
            _this.layoutConfigService.setModel({ page: objectPath.get(_this.pageConfigService.getCurrentPageConfig(), 'config') }, true);
        });
    }
    AppComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var permissions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.auth.isAuthorized().toPromise()];
                    case 1:
                        if (!_a.sent()) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.auth.getUserRoles().toPromise()];
                    case 2:
                        permissions = _a.sent();
                        this.permissionsService.loadPermissions(permissions);
                        return [2 /*return*/];
                    case 3:
                        this.permissionsService.loadPermissions(['GUEST']);
                        return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.ngAfterViewInit = function () {
        if (this.splashScreen) {
            this.splashScreenService.init(this.splashScreen.nativeElement);
        }
    };
    return AppComponent;
}());
export { AppComponent };
