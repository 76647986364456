import { ConfigModel } from '../core/interfaces/config';
import { TranslateService } from '@ngx-translate/core';

export class PagesConfig implements ConfigModel {
	public config: any = {};

	constructor(
		private translate: TranslateService
	) {
		this.config = {
			'/': {
				page: {
					title: 'Dashboard',
					desc: 'Latest updates and statistic charts'
				}
			},
			'my-company': {
				page: { title: 'Company', desc: 'Company Profile' }
			},
			builder: {
				page: { title: 'Layout Builder', desc: 'Layout builder' }
			},
			branches: {
				page: { title: 'Branches' }
			},
			maps: {
				page: { title: 'Geolocation Branches', desc: 'map branches' }
			},
			contacts: {
				page: { title: 'List Contacts', desc: 'list contacts' }
			},
			positions: {
				page: { title: 'Contact Positions', desc: 'map branches' }
			},
			'company-position': {
				page: { title: 'Company Position', desc: 'position' }
			},
			'contacts-details/:id': {
				page: { title: 'Contact Detail', desc: 'detail contact' }
			},
			header: {
				actions: {
					page: { title: 'Actions', desc: 'actions example page' }
				}
			},
			'branches-new':{
				page: { title: 'New Branch', desc: 'branch' }
			},
			profile: {
				page: { title: 'User Profile', desc: '' }
			},
			404: {
				page: { title: '404 Not Found', desc: '', subheader: false }
			}
		};
	}
}
