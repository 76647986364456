<!--begin::Signin-->

<body style="flex: 1;display: flex;flex-direction: column">
	<div style="flex: 1;display: flex;flex-direction: column;">
		<div style="flex: 1;" class="m-grid__item m-grid__item--fluid m-grid m-grid--desktop m-grid--ver-desktop m-grid--hor-tablet-and-mobile m-login m-login--6"
		 id="m_login">
			<div class="m-grid__item m-grid__item--fluid m-grid m-grid--desktop m-grid--ver-desktop m-grid--hor-tablet-and-mobile m-login m-login--6">
				<div class="m-grid__item m-grid__item--fluid  m-grid__item--order-tablet-and-mobile-1 m-login__wrapper">
					<!--begin::Body-->
					<!--begin::Signin-->
					<div class="m-login__body">
						<div class="m-login__signin">

							<div class="m-login__title">
								<h3 class="m-login__title" translate="AUTH.FORGOT.TITLE">Forgotten Password ?</h3>
								<div class="m-login__desc" style="text-align: justify !important" translate="AUTH.FORGOT.DESC">Enter your email to reset your password:</div>
							</div>

							<!-- <div-notice></div-notice> -->

							<!--begin::Form-->
							<form class="m-login__form m-form"  [formGroup]="form" (ngSubmit)="submit()">
	
									<div class="form-group">
										<!-- <mat-form-field> -->
										<!-- <mat-label>Email</mat-label> -->
										<label translate="AUTH.REGISTER.EMAIL_ADDRESS">Email address</label>
										<input class="form-control m-input m-input--square" 
										type="email" 
										formControlName="item"
										 [placeholder]="'INPUT.PLACEHOLDERS.EMAIL' | translate"
										 [ngClass]="{ 'is-invalid': submitted && f.item.errors }" 
										 autocomplete="off"
										 email="true" 
										 required>
										<div *ngIf="submitted && f.item.errors" class="invalid-feedback">
											<div *ngIf="f.item.errors.required" translate="INPUT.VALIDATIONS.REQUIRED">Field required</div>
											<div *ngIf="f.item.errors.email" translate="INPUT.VALIDATIONS.EMAIL">Invalid email</div>
										</div>
										<!-- </mat-form-field> -->
									</div>

								<div class="form-group">
									<label  translate="AUTH.FORGOT.HOW_RECEIVE">How do you want to receive the notification?</label>
									<!-- <select 
									formControlName="method" 
									[ngClass]="{ 'is-invalid': submitted && f.method.errors }" 
									class="form-control edited"
									 required>
										<option value="email" selected="email">email</option>
										<option value="sms">sms</option>
									</select> -->
									<m-input-with-icon
										[select2Data]="positionsOptions"
										type="select2"
										placeholder="Select position"
										formControlName="method" 
										[ngClass]="{ 'is-invalid': submitted && f.method.errors }" 
										[select2Value]="positionsOptions.value"
										></m-input-with-icon>
									<div *ngIf="submitted && f.method.errors" class="invalid-feedback">
											<div *ngIf="f.method.errors.required" translate="INPUT.VALIDATIONS.REQUIRED">Field required</div>
									</div>
								</div>
							</form>
							<!--end::Form-->

							<!--begin::Action-->
							<div class="m-login__action m-login__action--fit">
								<!-- <button mat-button (click)="loginPage($event)" [disabled]="loading" translate="AUTH.GENERAL.BACK_BUTTON">Back</button> -->
								<button  type="button" _ngcontent-c3="" routerLink="/login" mat-button="" class="mat-button">
									<span class="mat-button-wrapper" translate="AUTH.GENERAL.BACK_BUTTON">Back</span>
									<div class="mat-button-ripple mat-ripple" matripple=""></div>
									<div class="mat-button-focus-overlay"></div>
								</button>
								<button (click)="submit()" color="primary" class="mat-raised-button mat-primary" [disabled]="processing"
								[ngClass]="{'m-loader m-loader--light m-loader--left': processing}" mat-raised-button>
									<span *ngIf="!processing" translate="AUTH.GENERAL.SUBMIT_BUTTON">Send</span>
                    				<span *ngIf="processing" translate="AUTH.GENERAL.SUBMIT_BUTTON">Send</span>
								</button>
								<!-- <m-spinner-button _ngcontent-c3="" _nghost-c10=""> -->
									<!-- <button _ngcontent-c10="" mat-raised-button="" class="mat-raised-button mat-primary"><span class="mat-button-wrapper"><span _ngcontent-c10="">Enviar</span></span><div class="mat-button-ripple mat-ripple" matripple=""></div><div class="mat-button-focus-overlay"></div></button> -->
							<!-- </m-spinner-button> -->
								<!-- <m-spinner-button [options]="spinner" (click)="submit()">{{'AUTH.GENERAL.SUBMIT_BUTTON' | translate}}</m-spinner-button> -->
							</div>
							<!--end::Action-->
						</div>
					</div>
					<!--end::Signin-->
				</div>
				<div _ngcontent-c1="" class="m-grid__item   m-grid__item--order-tablet-and-mobile-2 m-grid m-grid--hor m-login__aside"
				 style="background-image: url(assets/app/media/img/bg/bg-4.jpg); background-size: cover">
					<div _ngcontent-c1="" class="m-grid__item"></div>
					<div _ngcontent-c1="" class="m-grid__item m-grid__item--fluid m-grid m-grid--ver">
						<div _ngcontent-c1="" class="m-grid__item m-grid__item--middle"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</body>