import { BehaviorSubject, Observable, Subject, from, throwError, of } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';
import moment from 'moment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'ngx-auth';

import { TokenStorage } from './token-storage.service';
import { UtilsService } from '../services/utils.service';
import { AccessData } from './access-data';
import { Credential } from './credential';
import { environment } from '../../../environments/environment';
import { AuthNoticeService } from './auth-notice.service';
import { TranslateService } from '@ngx-translate/core';
import { LaravelValidationsService } from '../services/laravel-validations.service';

/**
 * Servicio encargado de manejar el proceso de autenticación con los servicios del api inmovtech
 */
@Injectable()
export class AuthenticationService implements AuthService {
	/**
	 * Url principal del api inmovtech
	 */
	API_URL = environment.apiUrl;
	/**
	 * Ruta a la cual se deben enviar las peticiones de inicio se sesión
	 */
	API_ENDPOINT_LOGIN = '/auth/login';
	/**
	 * Ruta a la cual se deben enviar las peticiones de recuperación de contraseña
	 */
	API_ENDPOINT_RECOVERY = '/app/recovery/pass';
	/**
	 * Ruta a la cual se deben enviar las peticiones de validación del token
	 */
	API_ENDPOINT_VALIDATE_TOKEN = '/app/validate/token';
	/**
	 * Ruta a la cual se deben enviar las peticiones de inicio de sesión con redes sociales
	 */
	API_GOOGLE_LOGIN = '/auth/login_social';
	/**
	 * Ruta a la cual se deben enviar las peticiones para refrescar el token de acceso
	 */
	API_ENDPOINT_REFRESH = '/refresh';
	/**
	 * Ruta a la cual se deben enviar las peticiones de registro de nuevos usuarios
	 */
	API_ENDPOINT_REGISTER = '/register';
	/**
	 * Ruta que permite aceptar los terminos y condiciones
	 */
	API_AGREE_TERMS = '/api/v1/terms/accept/new';

	/**
	 * Evento lanzado al momento de que las credenciales del usuario cambien
	 */
	public onCredentialUpdated$: Subject<AccessData>;

	/**
	 * Evento lanzado al momento que el usuario en sesión cambie
	 */
	public userSubject = new BehaviorSubject(null);

	/**
	 * 
	 * @param http Cliente http de angular
	 * @param tokenStorage Servicio encargado de gestionar el almacenamiento de los tokens de sesión
	 * @param authNotice Servicio encargado de gestionar las alertas de autenticación
	 * @param util Servicio encargado de proveer utilitarios transversales a la aplicación
	 * @param translate Servicio encargado de gestionar la traducción
	 * @param laravelVal Servicio encargado de gestionar todos los códigos de error que pueden ser generados por el api inmovtech
	 */
	constructor(
		private http: HttpClient,
		private tokenStorage: TokenStorage,
		private authNotice: AuthNoticeService,
		private util: UtilsService,
		private translate: TranslateService,
		private laravelVal: LaravelValidationsService,
	) {
		this.onCredentialUpdated$ = new Subject();
	}

	/**
	 * Verificar si el usuario tiene una sesión activa
	 * @returns {Observable<boolean>}
	 * @memberOf AuthService
	 */
	public isAuthorized(): Observable<boolean> {
		return this.tokenStorage.getAccessToken().pipe(
			map(token => !!token)
		);
	}

	/**
	 * Obterner el token de acceso para el usuario en sesión
	 * @returns {Observable<string>}
	 */
	public getAccessToken(): Observable<string> {
		return this.tokenStorage.getAccessToken();
	}

	/**
	 * Permite obtener los roles que tiene el usuario en sesión
	 * @returns {Observable<any>}
	 */
	public getUserRoles(): Observable<any> {
		return this.tokenStorage.getUserRoles();
	}

	/**
	 * Método encargado de refrescar el token de acceso y validar que este continue siendo válido
	 * @returns {Observable<any>}
	 */
	public refreshToken(): Observable<AccessData> {
		return this.tokenStorage.getRefreshToken().pipe(
			switchMap((refreshToken: string) => {
				return this.http.get<AccessData>(this.API_URL + this.API_ENDPOINT_REFRESH + '?' + this.util.urlParam(refreshToken));
			}),
			tap(this.saveAccessData.bind(this)),
			catchError(err => {
				this.logout();
				return throwError(err);
			})
		);
	}

	/**
	 * Verifica un respuesta fallida para determinar si se debe refrescar el token de acceso
	 * @param {Response} response
	 * @returns {boolean}
	 */
	public refreshShouldHappen(response: HttpErrorResponse): boolean {
		return response.status === 401;
	}

	/**
	 * Permite identificar si la peticion se realiza para refrescar el token de acceso
	 * @param {string} url
	 * @returns {boolean}
	 */
	public verifyTokenRequest(url: string): boolean {
		return url.endsWith(this.API_ENDPOINT_REFRESH);
	}

	/**
	 * Realiza la autenticación con usuario y contraseña del usuario
	 * @param {Credential} credential Credenciales con las cuales se hará la autenticación
	 * @returns {Observable<any>}
	 */
	public login(credential: Credential): Observable<any> {
		// Expecting response from API
		return this.http.post<AccessData>(this.API_URL + this.API_ENDPOINT_LOGIN, {
			login: credential.email,
			password: credential.password,
			language: 'EN',
		}, {
			headers: {
				'X-Platform': 'panel'
			}
		}).pipe(
			map((result: any) => {
				this.userSubject.next(result);
				if (result instanceof Array) {
					return result.pop();
				}
				return result;
			}),
			tap(this.saveAccessData.bind(this)),
			catchError((ex): any => {
				if (ex.status === 401) {
					this.authNotice.setNotice(ex.error.errors.messages[0], 'error');
					return throwError(ex.error.errors.messages[0]);
				}
				this.authNotice.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'error');
				return throwError(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'));
			})
		);
	}

	/**
	 * Obtener el timezone del usuario
	 */
	public getUserTimeZone() {
		return /\((.*)\)/.exec(new Date().toString())[1];
	}

	/**
	 * Obtener el idioma del usuario
	 */
	public getLang() {
		return localStorage.getItem('language');
	}

	/**
	 * Funcion de autenticación por medio de google
	 * @param credential credenciales con las cuales se realizará la autenticación social con el api inmovtech
	 */
	public googlelogin(credential: any): Observable<any> {
		// Expecting response from API
		return this.http.post<AccessData>(this.API_URL + this.API_GOOGLE_LOGIN, {
			...credential,
			user_lang: this.getLang(),
			time_zone: this.getUserTimeZone(),
		}).pipe(
			map((result: any) => {
				if (result instanceof Array) {
					return result.pop();
				}
				return result;
			}),
			tap(this.saveAccessData.bind(this)),
			catchError((ex) => {
				if (ex.code === 401) {
					ex.code = 402;
				}
				this.laravelVal.alertErrors(ex);
				return of();
			})
		);
	}

	/**
	 * Handle Http operation that failed.
	 * Let the app continue.
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
	private handleError<T>(operation = 'operation', result?: any) {
		return (error: any): Observable<any> => {
			// Let the app keep running by returning an empty result.
			return from(result);
		};
	}

	/**
	 * Terminar la sesión para el usuario en sesión
	 * @param refresh 
	 */
	public logout(refresh?: boolean): void {
		this.tokenStorage.clear();
		if (refresh) {
			location.reload(true);
		}
	}

	/**
	 * Remover el token de sesión almacenado en el sistema
	 */
	public clearToken() {
		this.tokenStorage.clear();
	}

	/**
	 * Generar unixtimestamp de periodo de expiración
	 * @param seconds 
	 */
	private createExpiresIn(seconds: string) {
		return moment().add(seconds, 'seconds').unix();
	}

	/**
	 * Almacenar en localstorage todos los datos de acceso para futuros ingresos
	 * @private
	 * @param {AccessData} data
	 */
	private saveAccessData(accessData: AccessData) {
		if (typeof accessData !== 'undefined') {
			this.tokenStorage
				.setAccessToken(accessData.accessToken)
				.setRefreshToken(accessData.refreshToken)
				.setExpiresIn(this.createExpiresIn(accessData.expires_in))
				.setUserRoles(['USER']);
			this.onCredentialUpdated$.next(accessData);
		}
	}

	/**
	 * Enviar una petición para registrar un nuevo usuario
	 * @param {Credential} credential Información con la cual se creará la cuenta
	 * @returns {Observable<any>}
	 */
	public register(credential: Credential): Observable<any> {
		// dummy token creation
		credential = Object.assign({}, credential, {
			accessToken: 'access-token-' + Math.random(),
			refreshToken: 'access-token-' + Math.random(),
			roles: ['USER'],
		});
		return this.http.post(this.API_URL + this.API_ENDPOINT_REGISTER, credential);
	}

	/**
	 * Método ejecutado al momento de que le usuario en sesión acepta los terminos y condiciones
	 */
	agreeTermsAndConditions() {
		return this.http.get(this.API_URL + this.API_AGREE_TERMS);
	}

}
