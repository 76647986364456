<!-- <li class="m-nav__item m-topbar__quick-actions m-topbar__quick-actions--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push m-dropdown--mobile-full-width m-dropdown--skin-light" m-dropdown-toggle="click"> -->
<a href="javascript:;" class="m-nav__link m-dropdown__toggle">
	<span class="m-nav__link-icon">
		<span class="m-nav__link-icon-wrapper">
			<i class="flaticon-share"></i>
		</span>
	</span>
</a>
<div class="m-dropdown__wrapper">
	<span class="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
	<div class="m-dropdown__inner">
		<div class="m-dropdown__header m--align-center" style="background: url(./assets/app/media/img/misc/quick_actions_bg.jpg); background-size: cover;">
			<span class="m-dropdown__header-title" translate="MENU.HEADER.QUICK_ACTIONS">
				Quick Actions
			</span>
			<span class="m-dropdown__header-subtitle" translate="MENU.HEADER.SHORTCUTS">
				Shortcuts
			</span>
		</div>
		<div class="m-dropdown__body m-dropdown__body--paddingless">
			<div class="m-dropdown__content">
				<div class="data" data="false" data-max-height="380" data-mobile-max-height="200">
					<div class="m-nav-grid m-nav-grid--skin-light">
						<div class="m-nav-grid__row">
							<a routerLink="/company/branches-new" href="javascript:;" matTooltipPosition="left" class="m-nav-grid__item">
								<i class="m-nav-grid__icon fal fa-building"></i>
								<span class="m-nav-grid__text" translate="QUICK_ACTIONS.CREATE_BRANCH">
									Create New Branch
								</span>
							</a>
							<a routerLink="/users/new-user" class="m-nav-grid__item">
								<i class="m-nav-grid__icon 	fal fa-user"></i>
								<span class="m-nav-grid__text" translate="QUICK_ACTIONS.ADD_NEW_USER">
									Add New User
								</span>
							</a>
						</div>
						<div class="m-nav-grid__row">
							<a routerLink="/news/newslist/create-new"  matTooltipPosition="left" class="m-nav-grid__item">
								<i class="m-nav-grid__icon fal fa-newspaper"></i>
								<span class="m-nav-grid__text" translate="QUICK_ACTIONS.CREATE_NEWS">
									Create News
								</span>
							</a>
							<a routerLink="/news/gallery"  matTooltipPosition="left" class="m-nav-grid__item">
								<i class="m-nav-grid__icon fal fa-images"></i>
								<span class="m-nav-grid__text" translate="QUICK_ACTIONS.ADD_GALLERY">
									Add New Gallery
								</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- </li> -->
