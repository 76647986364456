import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import "hammerjs";
// import { ImageCropperComponent } from 'ngx-img-cropper';
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {};
var ɵ0 = DEFAULT_PERFECT_SCROLLBAR_CONFIG;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
