import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuickSidebarComponent } from './layout/quick-sidebar/quick-sidebar.component';
import { ScrollTopComponent } from './layout/scroll-top/scroll-top.component';
import { TooltipsComponent } from './layout/tooltips/tooltips.component';
import { ListSettingsComponent } from './layout/quick-sidebar/list-settings/list-settings.component';
import { MessengerModule } from './layout/quick-sidebar/messenger/messenger.module';
import { CoreModule } from '../../core/core.module';
import { ListTimelineModule } from './layout/quick-sidebar/list-timeline/list-timeline.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NoticeComponent } from './content/general/notice/notice.component';
import { PortletModule } from './content/general/portlet/portlet.module';
import { SpinnerButtonModule } from './content/general/spinner-button/spinner-button.module';
import { BlogComponent } from './content/widgets/general/blog/blog.component';
import { FinanceStatsComponent } from './content/widgets/general/finance-stats/finance-stats.component';
import { PackagesComponent } from './content/widgets/general/packages/packages.component';
import { TasksComponent } from './content/widgets/general/tasks/tasks.component';
import { SupportTicketsComponent } from './content/widgets/general/support-tickets/support-tickets.component';
import { RecentActivitiesComponent } from './content/widgets/general/recent-activities/recent-activities.component';
import { RecentNotificationsComponent } from './content/widgets/general/recent-notifications/recent-notifications.component';
import { AuditLogComponent } from './content/widgets/general/audit-log/audit-log.component';
import { BestSellerComponent } from './content/widgets/general/best-seller/best-seller.component';
import { AuthorProfitComponent } from './content/widgets/general/author-profit/author-profit.component';
import { DataTableComponent } from './content/widgets/general/data-table/data-table.component';
import { WidgetChartsModule } from './content/widgets/charts/widget-charts.module';
import { Select2Module } from 'ng2-select2';
import { StatComponent } from './content/widgets/general/stat/stat.component';
import { ContactCompanyComponent } from './content/widgets/general/contact-company/contact-company.component';
import { ContactPositionComponent } from './content/widgets/general/contact-position/contact-position.component';


import {
	MatInputModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatProgressBarModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatTooltipModule} from '@angular/material';
import { CounterBannerComponent } from './counter-banner/counter-banner.component';
import { CardComponent } from './card/card.component';
import { UserDataTableComponent } from './user-data-table/user-data-table';
import { DatatableFiltersComponent } from './datatable-filters/datatable-filters.component';
import { UserGroupDatatableComponent } from './user-group-datatable/user-group-datatable.component';
import { TranslateModule } from '@ngx-translate/core';
import { InputWithIconComponent } from './input-with-icon/input-with-icon.component';
import { GoogleAuthComponent } from './google-auth/google-auth.component';
import { ContactListComponent } from './content/widgets/general/contact-list/contact-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {SelectModule} from 'ng2-select';
import { NgxSummernoteModule } from 'ngx-summernote';
import { FileuploadComponent } from './fileupload/fileupload.component';
import { FileUploadModule } from 'primeng/fileupload';
import { CalendarModule } from 'primeng/calendar';
import { GroupSelectComponent } from './group-select/group-select.component';
import { BranchSelectComponent } from './branch-select/branch-select.component';
import { EventsSelectComponent } from './events-select/events-select.component';
import { PositionSelectComponent } from './position-select/position-select.component';
import { GoogleRegisterComponent } from './google-register/google-register.component';
import { UserSelectComponent } from './user-select/user-select.component';
import { FileWithPreviewComponent } from './file-with-preview/file-with-preview.component';
import { CommonValidationsComponent } from './common-validations/common-validations.component';
import { NgxPhoneSelectModule } from 'ngx-phone-select';
import { SpinnerComponent } from './spinner/spinner.component';
import { EmptyViewComponent } from './empty-view/empty-view.component';
import { DatatableToggleComponent } from './datatable-toggle/datatable-toggle.component';
import { CardHeaderWithButtonComponent } from './card-header-with-button/card-header-with-button.component';


@NgModule({
	declarations: [
		QuickSidebarComponent,
		ScrollTopComponent,
		TooltipsComponent,
		ListSettingsComponent,
		NoticeComponent,
		BlogComponent,
		FinanceStatsComponent,
		PackagesComponent,
		TasksComponent,
		SupportTicketsComponent,
		RecentActivitiesComponent,
		RecentNotificationsComponent,
		AuditLogComponent,
		BestSellerComponent,
		AuthorProfitComponent,
		DataTableComponent,
		StatComponent,
		ContactCompanyComponent,
		CounterBannerComponent,
		CardComponent,
		UserDataTableComponent,
		DatatableFiltersComponent,
		ContactPositionComponent,
		InputWithIconComponent,
		UserGroupDatatableComponent,
		GoogleAuthComponent,
		GoogleRegisterComponent,
		ContactListComponent,
		FileuploadComponent,
		GroupSelectComponent,
		BranchSelectComponent,
		EventsSelectComponent,
		PositionSelectComponent,
		GoogleRegisterComponent,
		UserSelectComponent,
		FileWithPreviewComponent,
		CommonValidationsComponent,
		SpinnerComponent,
		EmptyViewComponent,
		DatatableToggleComponent,
		CardHeaderWithButtonComponent,
	],
	exports: [
		QuickSidebarComponent,
		ScrollTopComponent,
		FileWithPreviewComponent,
		TooltipsComponent,
		ListSettingsComponent,
		NoticeComponent,
		BlogComponent,
		FinanceStatsComponent,
		PackagesComponent,
		TasksComponent,
		SupportTicketsComponent,
		RecentActivitiesComponent,
		RecentNotificationsComponent,
		AuditLogComponent,
		BestSellerComponent,
		AuthorProfitComponent,
		DataTableComponent,
		StatComponent,
		PortletModule,
		SpinnerButtonModule,
		ContactCompanyComponent,
		ContactPositionComponent,
		CounterBannerComponent,
		CardComponent,
		UserDataTableComponent,
		DatatableFiltersComponent,
		UserGroupDatatableComponent,
		InputWithIconComponent,
		GoogleAuthComponent,
		GoogleRegisterComponent,
		ContactListComponent,
		FileuploadComponent,
		BranchSelectComponent,
		EventsSelectComponent,
		GroupSelectComponent,
		PositionSelectComponent,
		UserSelectComponent,
		CommonValidationsComponent,
		SpinnerComponent,
		EmptyViewComponent,
		DatatableToggleComponent,
		CardHeaderWithButtonComponent,
	],
	imports: [
		CommonModule,
		RouterModule,
		NgbModule.forRoot(),
		PerfectScrollbarModule,
		MessengerModule,
		ListTimelineModule,
		CoreModule,
		PortletModule,
		SpinnerButtonModule,
		MatSortModule,
		MatProgressSpinnerModule,
		MatTableModule,
		MatPaginatorModule,
		MatSelectModule,
		MatProgressBarModule,
		MatButtonModule,
		MatCheckboxModule,
		MatIconModule,
		MatTooltipModule,
		WidgetChartsModule,
		RouterModule,
		FormsModule,
		SelectModule,
		ReactiveFormsModule,
		NgxSummernoteModule,
		FileUploadModule,
		Select2Module,
		CalendarModule,
		NgxPhoneSelectModule,
		TranslateModule.forChild(),
	],
})
export class PartialsModule {}
