<!-- begin::Footer -->
<div class="m-container m-page__container m-container--full-height" [ngClass]="footerContainerClass$ | async">
	<div class="m-footer__wrapper">
		<div class="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop">
			<div class="m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--last">
			<span class="m-footer__copyright">
				2020 &copy; Livent
				<a href="https://www.inmov.com/" target="_blank" class="m-link">
					Inmov
				</a>
			</span>
			</div>
			<!--<div class="m-stack__item m-stack__item--right m-stack__item--middle m-stack__item--first">
				<ul class="m-footer__nav m-nav m-nav--inline m--pull-right">
					<li class="m-nav__item">
						<a href="#" class="m-nav__link">
						<span class="m-nav__link-text">
							About
						</span>
						</a>
					</li>
					<li class="m-nav__item">
						<a href="#" class="m-nav__link">
						<span class="m-nav__link-text">
							Privacy
						</span>
						</a>
					</li>
					<li class="m-nav__item">
						<a href="#" class="m-nav__link">
						<span class="m-nav__link-text">
							T&C
						</span>
						</a>
					</li>
					<li class="m-nav__item">
						<a href="#" class="m-nav__link">
						<span class="m-nav__link-text">
							Purchase
						</span>
						</a>
					</li>
					<li class="m-nav__item m-nav__item">
						<a href="#" class="m-nav__link" data-toggle="m-tooltip" title="Support Center" data-placement="left">
							<i class="m-nav__link-icon flaticon-info m--icon-font-size-lg3"></i>
						</a>
					</li>
				</ul>
			</div>-->
		</div>
	</div>
</div>
<!-- end::Footer -->
