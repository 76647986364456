import { ChangeDetectorRef, ElementRef, forwardRef, Injector, NgZone, OnInit, SimpleChanges } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { NgbCalendar, NgbDatepicker } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import autosize from "autosize";
import flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import _ from "lodash";
import moment from "moment";
// @ts-nocheck
// @ts-nocheck
export function generate_control_value_accessor(InputClass) {
    return {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(function () { return InputClass; }),
        multi: true
    };
}
export var CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return InputWithIconComponent; }),
    multi: true
};
var callback = function () { };
var ɵ0 = callback;
var InputWithIconComponent = /** @class */ (function () {
    function InputWithIconComponent(injector) {
        var _this = this;
        this.placeholder = "";
        this.type = "text";
        this.select2Data = [];
        this.valueLoaded = true;
        this.datePickerStartView = "0, 'month'";
        this.async = false;
        this._onChangeCallback = callback;
        this._onTouchedCallback = callback;
        this.select2options = {
            containerCssClass: "is-invalid"
        };
        this.lang = "en";
        this.writeValue = function (value) {
            var formatedValue = value;
            if ((_this.type == "date" || _this.type === "datetime") &&
                moment(value).isValid()) {
                if (_this.type === "datetime") {
                    formatedValue = moment(value).format("YYYY-MM-DD HH:mm:ss");
                }
                else {
                    formatedValue = moment(value).format("YYYY-MM-DD");
                }
            }
            if (_this.type === "datetime") {
                _.defer(function () {
                    if (formatedValue) {
                        _this.flatpickrInstance.setDate(formatedValue);
                        _this.flatpickrInstance.jumpToDate(formatedValue);
                    }
                });
            }
            if (value !== _this._innerState) {
                _this._innerState = formatedValue;
                _this._onChangeCallback(formatedValue);
            }
            if (_this.type == "select2") {
                _.defer(function () {
                    $(_this.select.nativeElement).val(value);
                    _this.initializeSelect2();
                });
            }
        };
        this.initializePhoneInput = function () {
            var options = {
                initialCountry: _this.initialCountry ? _this.initialCountry : "auto",
                geoIpLookup: function (success, failure) {
                    $.get("https://ipinfo.io", function () { }, "jsonp").always(function (resp) {
                        var countryCode = resp && resp.country ? resp.country : "";
                        success(countryCode);
                    });
                },
                utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/14.0.8/js/utils.js",
                formatOnDisplay: true
            };
            _.defer(function () {
                // @ts-ignore
                _this.iti = window.intlTelInput(_this.input.nativeElement, options);
            });
        };
        this.calendar = injector.get(NgbCalendar);
        this.translate = injector.get(TranslateService);
        this.cdr = injector.get(ChangeDetectorRef);
        this.zone = injector.get(NgZone);
        this.select2options = {
            multiple: this.multiple,
            containerCssClass: "is-invalid"
        };
    }
    InputWithIconComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.type) {
            this.type = changes.type.currentValue;
        }
        if (changes.invalid) {
            this.invalid = changes.invalid.currentValue;
        }
        if (changes.select2Data) {
            this.zone.run(function () {
                _this.select2Data = changes.select2Data.currentValue;
            });
        }
        if (changes.minDate && this.type === "datetime") {
            if (this.flatpickrInstance) {
                this.flatpickrInstance.set("minDate", changes.minDate.currentValue);
            }
        }
    };
    Object.defineProperty(InputWithIconComponent.prototype, "value", {
        get: function () {
            return this._innerState;
        },
        set: function (v) {
            this.writeValue(v);
        },
        enumerable: true,
        configurable: true
    });
    InputWithIconComponent.prototype.registerOnChange = function (fn) {
        this._onChangeCallback = fn;
    };
    InputWithIconComponent.prototype.registerOnTouched = function (fn) {
        this._onTouchedCallback = fn;
    };
    InputWithIconComponent.prototype.refreshValue = function (value) {
        this.value = value;
    };
    InputWithIconComponent.prototype.getOptions = function () {
        return {
            multiple: this.multiple
        };
    };
    InputWithIconComponent.prototype.initializeDateInput = function () {
        var _this = this;
        _.defer(function () {
            var $element = $(_this.input.nativeElement);
            // @ts-ignore
            $element.datepicker({
                format: "yyyy-mm-dd",
                language: _this.translate.currentLang,
                autoclose: true,
                endDate: _this.maxDate,
                startView: "decade",
                startDate: _this.minDate
            });
            $element.on("changeDate", function (event) {
                _this.writeValue(event.target.value);
            });
        });
    };
    InputWithIconComponent.prototype.initializeDatetime = function () {
        var _this = this;
        var dateTimeChange = function (selectedDates, dateStr, instance) {
            if (dateStr) {
                _this.writeValue(dateStr);
            }
        };
        _.defer(function () {
            // @ts-ignore
            _this.flatpickrInstance = flatpickr(_this.input.nativeElement, {
                enableTime: true,
                enableSeconds: true,
                time_24hr: true,
                onValueUpdate: dateTimeChange,
                minDate: _this.minDate,
                maxDate: _this.maxDate,
                locale: _this.translate.currentLang === "es" && Spanish
            });
        });
    };
    InputWithIconComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.type === "tel") {
            this.initializePhoneInput();
        }
        if (this.type === "date") {
            this.initializeDateInput();
        }
        if (this.type === "select2") {
            this.initializeSelect2();
        }
        if (this.type === "datetime") {
            this.initializeDatetime();
        }
        if (this.type === "textarea") {
            _.defer(function () {
                autosize(_this.input.nativeElement);
            });
        }
    };
    InputWithIconComponent.prototype.initializeSelect2 = function () {
        var _this = this;
        if (this.type === "select2") {
            _.defer(function () {
                var $element = $(_this.select.nativeElement);
                // @ts-ignore
                $element.select2(_this.getOptions());
                $element.on("change", function () {
                    _this.writeValue($element.val());
                    _this.cdr.markForCheck();
                });
            });
        }
    };
    InputWithIconComponent.prototype.trackByFn = function (i, item) {
        return item.id;
    };
    return InputWithIconComponent;
}());
export { InputWithIconComponent };
export { ɵ0 };
