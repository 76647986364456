// Spain
export const locale = {
	lang: 'es',
	data: {
		ACCOUNT: {
			SUSPENDED: 'tu cuenta fue bloqueada debido a que no cuentas con una membresia activa',
			PAYMENT_ALERT: 'Tu cuenta está próxima a ser suspendida por favor realiza el pago',
			DEMO_ALERT: 'Your account is close to being suspended please make the payment',
		},
		POSITIONS: {
			SELECT_PLACEHOLDER: 'Seleccione cargo',
			CREATE_LINK: 'Crear cargo'
		},
		BRANCH: {
			CREATE_LINK: 'Crear sede',
			SELECT_PLACEHOLDER: 'Seleccione una sede'
		},
		PUSH: {
			GO_TO_SELECT: 'Selecciona una opción',
			EMPTY_DATATABLE: 'aquí encontrarás el listado de notificaciones enviadas',
			CREATE_LINK: 'enviar notificación',
			SENT: 'Notificación enviado',
			SEND_TO_ALL: 'Enviar a todos',
			SEND_BUTTON: 'Enviar notificación',
			MESSAGE: 'Mensaje',
			SENT_DATE: 'Fecha de envio',
			SEND: 'Enviar notificación',
			SEND_TO_SEGMENT: 'Enviar a segmento de usuarios',
			TITLE_LABEL: 'Título de la notificación:',
			ON_CLICK: 'Al hacer clic ir a:',
			GROUPS_LABEL: 'Grupos de usuarios',
			USER_POSITION: 'Cargos:',
			OS: 'Sistema operativo',
			BRANCH_LABEL: 'Sedes:',
			USER_MULTISELECT: 'Seleccionar usuarios de la lista:',
			TEXT_NOTIFICATION: 'Texto de la notificación:',
		},
		COMMUNITY: {
			EMPTY: 'aquí aparecerán las publicaciones realizadas desde la aplicación',
			TIMELINE: 'Publicaciones',
		},
		GALLERY: {
			EMPTY_DATATABLE: 'aquí aparecerán tus galerías, para crear una galería completa el formulario en la parte superior de la pagina',
			FILES_REQUIRED: 'La galería debe tener al menos una imágen',
			GALLERIES: 'Galerías',
			CREATED: 'Galería creada',
			ASSOCIATE_TO: 'Asociar a:',
			TITLE_LABEL: 'Título:',
			DESCRIPTION_LABEL: 'Descripción:',
			SELECT_IMAGES: 'Por favor seleccione las imagenes',
			UPDATED: 'Galería actualizada',
		},
		FILEUPLOAD: {
			INVALID_FILE: '{0}: Tipo de archivo invalido,',
			CHOOSE_LABEL: 'Seleccionar',
			UPLOAD_LABEL: 'Subir',
			CANCEL_LABEL: 'Cancelar',
		},
		LISTBOX: {
			FILTER_LABEL: 'Filtrar',
			MOVE_SELECTED: 'Mover seleccionados',
			MOVE_ALL: 'Mover todos',
			REMOVE_SELECTED: 'Remover seleccionados',
			REMOVE_ALL: 'Remover todos',
			INFO_TEXT: 'Mostrando {0}',
			FILTERED:'<span class="label label-warning">Filtrado</span> {0} from {1}',
			INFO_EMPTY:'Lista vacia',
		},
		DATATABLE: {
			COLUMN: 'Columna',
			COPY_SUCCESS_ONE: 'Información copiada al portapapeles.',
			COPY_SUCCESS_TWO: '',
			COPY_keys: '',
			COPY_TITLE: 'Información copiada',
			COPY: 'Copiar',
			EMPTY: 'No existen datos disponibles',
			INFO: 'Mostrando _START_ a _END_ de _TOTAL_ registros',
			INFO_EMPTY: 'Mostrando 0 a 0 de 0 registros',
			INFO_FILTERED: '(filtrando por _MAX_ total registros)',
			INFO_LENGTH: 'Mostrar _MENU_ registros',
			NOT_FOUND: 'No se encontraron resultados',
			PRINT: 'Imprimir',
		},
		ERRORS: {
			ERROR: 'Error',
			ERROR_CONNECTING: 'Error conectando al servidor',
			ERROR_MASIVE_CHARGE: 'Error de carga masiva',
			VERIFY_FIELDS: 'Por favor verifica los campos',
		},
		QUICK_ACTIONS: {
			CREATE_BRANCH: 'Crear Sede',
			ADD_NEW_USER: 'Crear Usuario',
			CREATE_NEWS: 'Crear Capacitación',
			ADD_GALLERY: 'Crear Galeria'
		},
		TRANSLATOR: {
			SELECT: 'Elige tu idioma',
		},
		MENU: {
			NOTIFICATIONS: 'Notificaciones',
			NEW: 'nuevo',
			ACTIONS: 'Comportamiento',
			CREATE_POST: 'Crear nueva publicación',
			CREATE_USER: 'Crear usuario',
			REPORTS: 'Informes',
			APPS: 'Aplicaciones',
			DASHBOARD: 'Tablero',
			COMPANY: 'Empresa',
			SPEAKERS: 'Conferencistas',
			SCHEDULE: 'Agenda',
			EVENTS: 'Eventos',
			MATERIALS: 'Materiales',
			USERS: 'Usuarios',
			NEWS: 'Capacitaciones',
			COMMUNITY: 'Comunidad',
			SUBMENUS: {
				NEW_USERS: 'Crear Usuario',
				UPLOAD_USERS: 'Cargar usuarios',
				HOME: 'Inicio',
				CONTACT_LIST: 'Lista de contactos',
				MAP: 'Mapa',
				CONTACT_COMPANY: 'Contacto Empresa/Cargo',
				MY_COMPANY: 'Mi empresa',
				BRANCHES: 'Sedes',
				COMPANY_POSITION: 'Cargos de la compañía',
				SPEAKERS_LIST: 'Listado de conferencistas',
				SCHEDULE_LIST: 'Listado de Agendas',
				EVENTS_LIST: 'Listado de Eventos',
				MATERIALS_LIST: 'Listado de Materiales',
				USER_LIST: 'Lista de usuarios',
				USER_GROUP: 'Grupo de usuario',
				CATEGORIES: 'Categorías',
				NEW_LIST: 'Lista de Capacitaciones',
				GALLERIES: 'Galerias',
				NOTIFICATIONS_LIST: 'Lista de notificaciones',
				PUSH_NOTIFICATION: 'Enviar notificaciones',
			},
			HEADER: {
				MY_PROFILE: 'Mi perfil',
				LOGOUT: 'Cerrar sesión',
				QUICK_ACTIONS: 'Acciones rápidas',
				SHORTCUTS: 'Atajos',
				NEW_BRANCH: 'Crear nueva sede',
				ADD_USER: 'Añadir nuevo usuario',
				CREATE_NEWS: 'Nueva entrada',
				ADD_GALLERY: 'Añadir nueva galería',
			}
		},
		NEWS: {
			CATEGORY_DISABLED: 'Esta capacitación no será visible para los usuarios debido a que la categoria se encuentra deshabilitada',
			SELECT_PLACEHOLDER: 'selecciona una entrada',
			EMPTY_DATATABLE: 'aqui encontraras el listado de entradas de blog publicadas',
			CREATE_LINK: 'Crear entrada',
			TEXT_SHORT: 'Resumen',
			LIST: 'Lista de entradas',
			CANT_COMMENTS: 'Comentarios',
			CANT_LIKES: 'Me gusta',
			VIEWS_CANT: 'Vistas',
			CREATE_BUTTON: 'Crear Capacitación',
			TITLE_PLACEHOLDER: 'Ingresar título de la capacitación',
			TITLE_LABEL: 'Título de la capacitación',
			PUBLICATION_DATE_PLACEHOLDER: 'Ingresar fecha de publicación',
			PUBLICATION_DATE_LABEL: 'Fecha de publicación',
			CLOSE_DATE_LABEL: 'Fecha de cierre',
			CLOSE_DATE_PLACEHOLDER: 'Ingrese la fecha de cierre',
			UPLOAD_COVER_LABEL: 'Selecciona la foto de portada',
			CATEGORY: 'Categoria',
			GROUP_TO_PUBLISH: 'Publicar en grupos',
			ADD_BRANCH: 'Añadir sede',
			ADD_POSITION: 'Añadir posicion',
			SEND_NOTIFICATION_GROUP: 'Enviar notificación a los grupos seleccionados',
			FULL_TEXT: 'Texto completo',
			CREATED: 'La capacitación fue creada',
			UPDATED: 'La capacitación fue actualizada',
			GALLERY_CREATE: 'Crear galeria',
			GALLERY_LISTING:  'Gallery Listing',
		},
		FILE_INPUT: {
			CHOOSE: 'Seleccionar Archivo...',
		},
		BREADCRUMBS:{
			CONTACT_DTL:'Detalles de contacto',
			CRUMB:{
				CONTACT:'Contacto',
			}
		},
		DASHBOARD:{
			CARDS: 'Tarjetas',
			PRODUCTS: 'Productos recientes',
			CONTACT_LIST: 'Top 10 Lista de Contactos',
			MAP: 'Mapa',
			CONTACT_COMPANY: 'Top 5 contacto por empresa',
			CONTACT_POSITION: 'Top 5 contacto por cargo',
			USERS: 'Usuarios',
			CONTACTS: 'Contactos',
			NEWS: 'Capacitaciones',
			NEWS_LIKE: 'Interacciones',

			LABELS: {
				CARDS: 'Estas estadísticas se generan a partir de las tarjetas enviadas desde la',
				MAP: 'Mapa de las ultimas 10 tarjetas enviadas',
				CONTACT_COMPANY: 'Tarjetas enviadas por los usuarios.',
				CONTACT_POSITION: 'Tarjetas enviadas por los usuarios a los cargos.',

				USERS: 'Con tarjeta',
				USERS_TWO: 'Sin tarjeta',
				CONTACTS: 'Con cliente, tarjetas enviadas',
				NEWS: 'Cantidad de visitas a las capacitaciones',
				LIKE: 'Cantidad de Me gusta',
				COMMENTS: 'Capacitaciones con Comentarios',
			}
		},
		GROUPS: {
			CREATE_LINK: 'Crear grupo',
			REMOVED: 'Grupo removido',
			EMPTY_DATATABLE: 'aquí encontraras tus grupos de usuarios, puedes crear grupos completando el formulario ubicado en la parte superior de esta pagina',
			ASSIGN_HELP: `Para asignar un usuario al grupo <strong>{{name}}</strong>, por favor seleccionalo desde la lista del lado izquierdo. Para desasignar utiliza la lista del lado derecho. Puedes asignar o desasignar de forma masiva las flechas. (<< >>)`,
			UPDATED: 'Grupo actualizado',
			NAME: 'Nombre del grupo',
			SHOW_IN_DIRECTORY: 'Mostrar en directorio',
			DESCRIPTION: 'Descripción',
			UPDATE_GROUPS: 'Administrar usuarios',
			CREATED: 'Grupo creado',
			EDIT: 'Editar grupo',
			MANAGE: 'Administrar usuarios',
			ASSIGNED: 'Usuarios asignados',
			NO_ASSIGNED: 'Usuarios no asignados',
			MEMBERS_UPDATED: 'El grupo ha sido actualizado, se han asignado o retirado usuarios de este grupo',
		},
		GENERAL: {
			CODE_SUCCESS: 'La cancelación fue realizada correctamente',
			CODE_ERROR: 'La cancelación no fue realizada',
			lang: 'es',
			SHOW_ALL_ERRORS: 'mostrar todos los errores',
			SELECT_ONE: 'Selecciona',
			ALL: 'Todos',
			CLOSE: 'Cerrar',
			SEND: 'Enviadas',
			PROCESSING: 'Procesando',
			FIX_THIS_ERRORS: 'Por favor corrige los siguientes errores',
			LOADING: 'Cargando',
			NEWS_TITLE: 'Título de la Capacitacion',
			CREATED: 'Creado correctamente',
			CONFIRM: 'Confirmar',
			SUCCESS: 'Hecho',
			SUCCESS_PASS_TEMP: 'Contraseña generada',
			SUCCESS_MSJ_TEMP: 'Tu contraseña temporal es: ',
			BTN_USE: 'Usar',
			CONFIRM_REMOVE: 'Esta seguro de querer remover este elemento?',
			CONFIRM_REMOVE_TEXT: 'Este cambio no se puede deshacer!',
			YES: 'si',
			NO: 'no',
			ADMIN: 'Administrador',
			USER: 'Usuario',
			EDIT: 'Editar',
			CREATE: 'Crear',
			BIRTHDAY: 'Fecha de nacimiento',
			LAST_NAME:  'Apellidos',
			FIRST_NAME: 'Nombres',
			SAVE: 'Guardar',
			CANCEL: 'Cancelar',
			REMOVE: 'Borrar',
			TOGGLE_ACTIVE: 'Activar / Desactivar',
			UPDATE: 'Actualizar',
			INACTIVE: 'inactivo',
			ACTIVE: 'Activo',
			ENTRIES: 'Mostrar',
			STATUS: 'Estado',
			ACTIONS: 'Acciones',
			FOLDER: 'Carpeta',
			SEARCH: 'Buscar',
			SEARCH_PLACES: 'Buscar Lugar',
			INTRODUCTION: 'introducción',
			DESCRIPTION: 'Descripción',
			CREATION_DATE: 'Fecha de creación',
			PUBLICATION_DATE: 'Fecha de publicación',
			ID: 'ID',
			USERS: 'Usuarios',
			TITLE: 'Título',
			NAME: 'Nombre',
			CATEGORY: 'Categoria',
			DIRECTORY: 'Directorio',
			ADD_USER: 'Crear Usuario',
			ADD_BRANCH: 'Nueva Sede',
			ADD_POSITION: 'Nuevo Cargo',
			SEARCH_PLACEHOLDER: 'Buscar en todos los campos',
			SEARCH_PLACES_PLACEHOLDER: 'Buscar en todos los lugares',
			NOT_REGISTERED: 'No registra',
			TOTAL_CONTACT: 'Contactos',
			TAB_BASIC_INFO: 'Información básica',
			TAB_BRANCH_SOCIAL: 'Redes sociales',
			FIELD_REQUIRED: 'Campo requerido',
			BTN_ADD_SOCIAL: 'Añadir red social',
			MSJ_URL_FORMAT: 'Debe ser un url',
			NUMBER_FORMAT: 'Campo numérico | máximo 15 números',
			EMPTY_SEARCH: 'No existen resultados para esta vista',
			UPDATE_SUCCESS: 'Información actualizada',
			CREATED_SUCCESS: 'Información creada.',
			POSITION: {
				NEW_TITLE: 'Nueva posición',
				UPDATE_TITLE: 'Editar posición',
				BREADCRUMB: 'Posición de la compañía',
				POSITION_NAME: 'Nombre  nuevo de la posición:'
			},
			BRANCHES: {
				BRANCHES: 'Sedes',
				REQUIRED: 'Por favor verifique los campos del formulario',
				NAME_REQUIRED: 'Nombre es requerido',
				PHONE_REQUIRED: 'Telefono es requerido',
				URL_REQUIRED: 'Url es requerido',
				NEW_BRANCH: 'Nueva sede',
				UPDATE_BRANCH: 'Actualizar sede',
				SELECT_PLACE: 'Selecciona un lugar en el mapa'
			},
			NO_DATA_FOUND: 'No se encuentran datos',
			NO_DATA_FOUND_TOP_CONTACT_COMPANY: 'No se encontraron empresas registradas, esta información se genera a partir de las tarjetas enviadas desde la aplicación.',
			NO_DATA_FOUND_TOP_CONTACT_POSITION: 'No se encontraron cargos registrados, esta información se genera a partir de las tarjetas enviadas desde la aplicación.',
			SEE_MORE: 'Ver más',
			CROP_LOGO: 'Recortar logo',
			SQUARE: 'Cuadrado',
			RECTANGULAR: 'Rectangular',
			MAP: 'Localización de las tarjetas enviadas',
			CONTACT: 'Contacto Empresa/Cargo',
			CONTACT_COMPANY: 'Contacto(s) por empresa',
			CONTACT_POSITION: 'Contacto(s) por cargo',
			USERS_SENT_TARJET: 'Aquí encontrarás los usuarios a los que se les ha enviado cards.',
			COMPANIES_USERS_SENT: 'Aquí encontrarás las empresas de las personas a las que se les ha compartido la tarjeta digital.',
			POSITION_USERS_SENT: 'Aquí encontrarás los cargos de las personas a las que se les ha compartido la tarjeta digital.',
			CAN_CHANGE_POSITION: '¿Los empleados pueden cambiar el cargo?',
			SELECT_OPTION: 'Por favor seleccione alguna opción'
		},
		CATEGORY: {
			REMOVED: 'Categoria removida',
			EMPTY_DATATABLE: 'aqui apareceran tus categorias de capacitaciones, completa el formulario en la parte superior de esta página para crear  una  categoria',
			CREATE_LINK: 'crear categoría',
			NAME: 'Categoria',
			NAME_LABEL: 'Nombre de categoría',
			UPDATE: 'Actualizar Categoria',
			UPDATED: 'Categoria Actualizada',
			COLOR: 'Color de la etiqueta',
			NEW: 'Crear categoria',
			NAME_PLACEHOLDER: 'Ingresar nombre de la categoría',
			COLOR_LABEL: 'Seleccione color',
			LISTING_TITLE: 'Listado de categorías',
			ERROR_COLOR: 'Por favor selecciona un color',
			ERROR_NAME: 'El nombre de la categoria es requerido',
			CREATED: 'Categoria creada',
		},
		PROFILE_USER: {
			IMAGE_CHANGE: 'cambiar imagen',
			MY_PROFILE: 'Mi perfil',
			TITLE: 'Datos personales',
			NAME: 'Nombre',
			LAST_NAME: 'Apellido',
			EMAIL: 'Correo',
			POSITION: 'Cargo',
			COMPANY_NAME: 'Nombre de empresa',
			PHONE: 'Teléfono no.',
			BIRTHDAY: 'Fecha de cumpleaños',
			PASSWORD: 'Contraseña',
			SAVE: 'Guardar cambios',
			CANCEL: 'Cancelar',

			SOCIAL_MESSAGE: 'Usted se registro con una cuenta social de Google por tanto no puede modificar su contraseña',
			PASSWORD_INFO: 'Deje este campo vacío para no cambiar su contraseña.',
			CHANGE_IMAGE: 'Para cambiar la imagen por favor guarda.',



			LABELS: {
				FIELD_REQUIRED: 'Campo requerido.',
				MIN_LENGHT: 'Longitud mínima 7 caracteres.',
				MIN_LENGHT_6: 'Longitud mínima 6 caracteres.',
				COMPANY: 'La empresa no es modificable.',
				PASSWORD_INFO: 'Deje este campo vacío para no cambiar su contraseña.',
				FIELD_INCORRECT: 'Correo electrónico incorrecto.',
				MAX_LENGHT: 'Longitud máxima de 15 caracteres.',
				ALONE_NUMBER: 'Sólo números.'
			},
			PLACEHOLDERS: {
				FIRST_NAME: 'Ingrese el nombre',
				LAST_NAME: 'Ingrese el apellido',
				EMAIL: 'Ingrese el correo electrónico',
				POSITION: 'Select position',
				PHONE: 'Ingrese Teléfono',
				PASSWORD: 'Actualizar contraseña',
			}
		},
		PROFILE_COMPANY: {
			TITLE_EDIT: 'Editar Mi Empresa',
			COMPANY_NAME: 'Nombre Empresa: ',
			COMPANY_ALIAS: 'Alias Empresa: ',
			TEXT_COLOR: 'Seleccione color de texto: ',
			BACKGROUND_COLOR: 'Seleccione color de fondo:',
			ATTACH: 'Adjuntar logotipo',
			PLACEHOLDERS: {
				COMPANY_NAME: 'Ingrese nombre de la empresa',
				COMPANY_ALIAS: 'Ingrese alias de la empresa',
			}
		},
		AUTH: {
			GENERAL: {
				OR: 'O',
				SUBMIT_BUTTON: 'Enviar',
				NO_ACCOUNT: 'No tienes una cuenta?',
				SIGNUP_BUTTON: 'Regístrate',
				FORGOT_BUTTON: 'Se te olvidó tu contraseña?',
				BACK_BUTTON: 'Atrás',
				PRIVACY: 'Intimidad',
				LEGAL: 'Legal',
				CONTACT: 'Contacto',
			},
			LOGIN: {
				TITLE: 'Crear una cuenta',
				BUTTON: 'Iniciar sesión',
				BY_EMAIL: 'Ingreso con correo',
				EMAIL_LABEL: 'Correo:',
				PASSWORD: 'Contraseña:',
				EMAIL_LABEL_PLACEHOLDER: 'Correo',
				PASSWORD_PLACEHOLDER: 'Contraseña',
				GOOGLE: 'Ingresar con google',
				ERROR_UNEXPECTED: 'Error inesperado',
			},
			FORGOT: {
				TITLE: '¿Olvidaste tu contraseña?',
				DESC: '¿Tienes una cuenta? Puede recuperar su cuenta utilizando la dirección de correo electrónico o el número de SMS asociado con su cuenta.',
				CODE: 'Código recibido',				
				MESSAGE_1: "A continuación, ingresa el código que recibiste.",
				MESSAGE_2: "Si el mensaje no llega en 5 minutos reinicia el proceso ",
				MESSAGE_3: "Presionando aquí.",
				
				HOW_RECEIVE: '¿Con qué medio quieres recuperar la contraseña?',

			},
			REGISTER: {
				GET_STARTED: 'Comience con una cuenta gratuita',
				GET_SUBTITLE: 'Utilice su dirección de correo electrónico del trabajo para que podamos conectarlo con su equipo en Mi tarjeta digital',
				PRINCING_LINK: 'Ver Planes y Precios',
				SIGNUP_GOOGLE: 'Regístrate usando Google',
				BY_EMAIL: 'Por correo electronico',
				EMAIL_ADDRESS: 'Dirección de correo electrónico',
				PASSWORD: 'Contraseña',
				FIRST_NAME: 'Nombre de pila',
				COMPANY: 'Empresa',
				LANGUAGE: 'Idioma',
				TERMS: 'Estoy de acuerdo con mi tarjeta digital ',
				TERMS_LINK_1: ' Términos de servicio ',
				AND: ' y ',
				TERMS_LINK_2: ' Política de privacidad ',
				BTN_REGISTER: 'Iniciar proceso de registro',
				ALREADY: '¿Ya tienes una cuenta?',
				LOGIN: 'Iniciar sesión',

				OPTION_1: 'Inglés',
				OPTION_2: 'Español',

				TITLE: 'Sign Up',
				DESC: 'Enter your details to create your account',
				SUCCESS: 'Your account has been successfuly registered. Please use your registered account to login.'
			},
			INPUT: {
				EMAIL: 'Correo',
				FULLNAME: 'Fullname',
				PASSWORD: 'Password',
				METHOD: 'Método',
				CODE: 'Código',
				CONFIRM_PASSWORD: 'Confirm Password',
			},
			VALIDATION: {
				INVALID: '{{name}} no es valido',
				REQUIRED: '{{name}} es requerido',
				MIN_LENGTH: '{{name}} el tamaño mínimo es {{min}}',
				AGREEMENT_REQUIRED: 'Aceptar los terminos y condiciones es requerido',
				NOT_FOUND: 'el {{name}} es requerido y no fue encontrado',
				INVALID_LOGIN: 'Las credenciales ingresadas son incorrectas',
				ATTEMPTS: 'Aparentemente has olvidado tu contraseña',
				RECOVER_PASSWORD: 'Recuperar contraseña',
			}
		},
		CONTACTS: {
			USER: 'Usuario',
			POSITION: 'Cargo',
			QUANTITY: 'Cantidad',
			NAME: 'Nombre',
			COMPANY: 'Compañia',
			EMAIL: 'Correo',
			CELL_PHONE: 'Número de Contacto',

		},
		INPUT: {
			GROUPS: 'Grupos',
			BRANCH: 'Sede',
			CAN_NOTIFY: 'Notificaciones',
			POSITION: 'Cargo',
			SESSION_TYPE_HELP: 'La contraseña temporal será ',
			SESSION_TYPE_G: 'G-Suites',
			SESSION_TYPE_N: 'Normal',
			TYPE_LOGIN: 'Tipo de ingreso',
			ACCOUNT_TYPE: 'Tipo de cuenta',
			EMAIL: 'Correo',
			MOBILE_PHONE: 'Celular',
			PLACEHOLDERS: {
				NAME: 'Ingresa nombres',
				LAST_NAME: 'Ingresa apellidos',
				EMAIL: 'Ingresa correo',
				MOBILE_PHONE: 'Ingresa celular',
				BIRTHDAY: 'Fecha de nacimiento',
				GROUP_NAME: 'Nombre del grupo',
				PASSWORD: 'Ingresa contraseña',
				COMPANY: 'Ingresa nombre de la empresa',
				CODE: 'Ingresa código',
			},
			VALIDATIONS: {
				PATTERN: 'Valor no valido para este campo',
				REQUIRED: 'Campo requerido',
				EMAIL: 'Correo invalido',
				PHONE: 'Teléfono invalido',
				MIN_HEIGHT: 'Valor muy corto',

			}
		},
		USERS: {
			UPDATE_USER: 'Actualizar usuario',
			COLUMN_A: 'Nombres',
			COLUMN_B: 'Apellidos',
			COLUMN_C: 'Fecha de nacimiento - <strong>aaaa-mm-dd</strong>',
			COLUMN_D: 'Correo electrónico (Del mismo domino)',
			COLUMN_E: 'País',
			COLUMN_F: 'Teléfono',
			COLUMN_G: 'Puede notificar - <strong>Yes<strong>, <strong>No</strong>',
			COLUMN_H: 'Sede de la empresa (debe ser creada con anterioridad)',
			COLUMN_I: 'Cargo',
			COLUMN_J: 'Inicia sesión - <strong>Social:</strong> Autenticación con gmail, <strong>User and Password:</strong> Usuario y contraseña',
			COLUMN_K: 'Perfil - <strong>1. Administrador</strong>, <strong>2. Usuario Regular</strong>',
			COLUMN_L: 'Número de documento',
			PROCESSED_USERS: 'Se han añadido los usuarios',
			DOWNLOAD_XLSX_FILE: 'Descargar el archivo plantilla',
			UPLOAD_XLSX_INST: 'DEMO <strong>asdfsadfas</strong>',
			UPLOAD_USERS_TITLE: 'Seleccione un archivo',
			SELECT_GROUP_MEMBER: 'Editar grupo',
			UPLOAD_XLSX_INST1: 'y sube el archivo con',
			UPLOAD_XLSX_INST2: 'el formato y el',
			UPLOAD_XLSX_INST3: 'orden indicado',
			USERS: 'Usuarios',
			GROUP_LISTING: 'Grupos de usuarios',
			TOTAL: {
				TITLE: 'Total Usuarios',
				SUBTITLE: 'Todos los Usuarios',
			},
			ID: 'ID',
			IDENTIFICATION: 'Identificación',
			FULL_NAME: 'Nombre Completo',
			EMAIL: 'Correo',
			MOBILE: 'Celular',
			GROUP: 'Gropo',
			STATUS: 'Estado',
			ACTIONS: 'Acciones',
			CREATED: 'Usuario creado',
			UPDATED: 'Usuario actualizado',
		},
		BRANCHES: {
			TOTAL: {
				TITLE: 'Total de enviadas',
				SUBTITLE: 'Total de tarjetas enviadas',
			},
			BRANCHE_NAME: 'Nombre de Sede',
			COUNTRY: 'País',
			CITY: 'Ciudad',
			BRANCH_ADDRESS: 'Dirección Sede',
			USER: 'Usuarios',
			BRANCHE_PHONE: 'Teléfono Sede',
			// STATUS: 'Status',
			ACTIONS: 'Actions',
			
			NEW_BRANCH: 'Nueva Sede',
			BRANCH_CITY: 'Ciudad Sede',
			BRANCH_PHONE: 'Télefono Sede',
			BRANCH_SOCIAL: 'Nombre de la red social',
			PLACE_ID: 'ID del lugar:',
			PLACEHOLDERS:{
				CITY: 'Ciudad',
				ADDRESS: 'Dirección',
				NAME: 'Ingrese el nombre de la sede',
				PHONE: 'Ingresar teléfono de sede',
				PLACE_ID: 'ID del lugar',
			}
		},
		CARDS: {
			TOTAL: {
				TITLE: 'Total Cartas',
				SUBTITLE: 'Todas los Cartas',
			},
			ID: 'ID',
			IDENTIFICATION: 'Identificación',
			FULL_NAME: 'Nombre Completo',
			EMAIL: 'Email',
			MOBILE: 'Celular',
			GROUP: 'Gropo',
			STATUS: 'Estado',
			ACTIONS: 'Acciones'
		},
		COMPANY_POSITION: {
			ID: 'ID',
			IDENTIFICATION: 'Identificación',
			FULL_NAME: 'Nombre Completo',
			EMAIL: 'Email',
			MOBILE: 'Celular',
			GROUP: 'Gropo',
			STATUS: 'Estado',
			ACTIONS: 'Acciones',
			NAME_POSITION: 'Nombre del Cargo',
		},
		COMPANY_POSITION_CONTACT: {
			NAME_COMPANY: 'Nombre'
		},
		ECOMMERCE: {
			COMMON: {
				SELECTED_RECORDS_COUNT: 'Selected records count: ',
				ALL: 'All',
				SUSPENDED: 'Suspended',
				ACTIVE: 'Active',
				FILTER: 'Filter',
				BY_STATUS: 'by Status',
				BY_TYPE: 'by Type',
				BUSINESS: 'Business',
				INDIVIDUAL: 'Individual',
				SEARCH: 'Search',
				IN_ALL_FIELDS: 'in all fields'
			},
			ECOMMERCE: 'eCommerce',
			CUSTOMERS: {
				CUSTOMERS: 'Customers',
				CUSTOMERS_LIST: 'Customers list',
				NEW_CUSTOMER: 'New Customer',
				DELETE_CUSTOMER_SIMPLE: {
					TITLE: 'Customer Delete',
					DESCRIPTION: 'Are you sure to permanently delete this customer?',
					WAIT_DESCRIPTION: 'Customer is deleting...',
					MESSAGE: 'Customer has been deleted'
				},
				DELETE_CUSTOMER_MULTY: {
					TITLE: 'Customers Delete',
					DESCRIPTION: 'Are you sure to permanently delete selected customers?',
					WAIT_DESCRIPTION: 'Customers are deleting...',
					MESSAGE: 'Selected customers have been deleted'
				},
				UPDATE_STATUS: {
					TITLE: 'Status has been updated for selected customers',
					MESSAGE: 'Selected customers status have successfully been updated'
				},
				EDIT: {
					UPDATE_MESSAGE: 'Customer has been updated',
					ADD_MESSAGE: 'Customer has been created'
				}
			}
		},
		FOOTER: {
			
			COPY: '2019 © Mi tarjeta digital por ',
			// COMPANY: 'Inmov',
			PRIVACY: 'Privacidad',
			TYC: 'T&C',
			PURCHASE: 'Compra',
		}
	}
};
