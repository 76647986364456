/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./timeline-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../core/pipes/time-elapsed.pipe";
import * as i4 from "./timeline-item.component";
var styles_TimelineItemComponent = [i0.styles];
var RenderType_TimelineItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimelineItemComponent, data: {} });
export { RenderType_TimelineItemComponent as RenderType_TimelineItemComponent };
function View_TimelineItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "m-badge m-badge--wide"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(2, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "m-badge m-badge--wide"; var currVal_1 = _co.badgeClass(); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.item.tags; _ck(_v, 2, 0, currVal_2); }); }
function View_TimelineItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "m-list-timeline__time"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.item.datetime)); _ck(_v, 1, 0, currVal_0); }); }
export function View_TimelineItemComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(131072, i3.TimeElapsedPipe, [i1.ChangeDetectorRef, i1.NgZone]), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "m-list-timeline__badge m-list-timeline__badge--state-warning"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "a", [["class", "m-list-timeline__text"], ["href", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineItemComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineItemComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (((_co.item.tags == null) ? null : _co.item.tags.length) > 0); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.item.datetime; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.text; _ck(_v, 3, 0, currVal_0); }); }
export function View_TimelineItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "m-timeline-item", [], [[8, "className", 0]], null, null, View_TimelineItemComponent_0, RenderType_TimelineItemComponent)), i1.ɵdid(1, 114688, null, 0, i4.TimelineItemComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
var TimelineItemComponentNgFactory = i1.ɵccf("m-timeline-item", i4.TimelineItemComponent, View_TimelineItemComponent_Host_0, { item: "item" }, {}, []);
export { TimelineItemComponentNgFactory as TimelineItemComponentNgFactory };
