/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./messenger-out.component";
var styles_MessengerOutComponent = [];
var RenderType_MessengerOutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MessengerOutComponent, data: {} });
export { RenderType_MessengerOutComponent as RenderType_MessengerOutComponent };
export function View_MessengerOutComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "m-messenger__message m-messenger__message--out"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "m-messenger__message-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "m-messenger__message-arrow"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "m-messenger__message-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "m-messenger__message-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message.text; _ck(_v, 5, 0, currVal_0); }); }
export function View_MessengerOutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "m-messenger-out", [], [[8, "className", 0]], null, null, View_MessengerOutComponent_0, RenderType_MessengerOutComponent)), i0.ɵdid(1, 114688, null, 0, i1.MessengerOutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
var MessengerOutComponentNgFactory = i0.ɵccf("m-messenger-out", i1.MessengerOutComponent, View_MessengerOutComponent_Host_0, { message: "message" }, {}, []);
export { MessengerOutComponentNgFactory as MessengerOutComponentNgFactory };
