<div *ngIf="errorType === 1" class="m-grid__item m-grid__item--fluid m-grid m-error-1" style="background-image: url(./assets/app/media/img/error/bg1.jpg);">
	<div class="m-error_container">
		<span class="m-error_number">
			<h1>404</h1>
		</span>
		<p class="m-error_desc">
			OOPS! Something went wrong here
		</p>
	</div>
</div>

<div *ngIf="errorType === 2" class="m-grid__item m-grid__item--fluid m-grid m-error-2" style="background-image: url(./assets/app/media/img/error/bg2.jpg);">
	<div class="m-error_container">
		<span class="m-error_title2 m--font-light">
			<h1>OOPS!</h1>
		</span>
		<span class="m-error_desc m--font-light">
			Something went wrong here
		</span>
	</div>
</div>

<div *ngIf="errorType === 3" class="m-grid__item m-grid__item--fluid m-grid m-error-3" style="background-image: url(./assets/app/media/img/error/bg3.jpg);">
	<div class="m-error_container">
		<span class="m-error_number">
			<h1>404</h1>
		</span>
		<p class="m-error_title m--font-light">
			How did you get here
		</p>
		<p class="m-error_subtitle">
			Sorry we can't seem to find the page you're looking for.
		</p>
		<p class="m-error_description">
			There may be amisspelling in the URL entered,<br>
			or the page you are looking for may no longer exist.
		</p>
	</div>
</div>

<div *ngIf="errorType === 4" class="m-grid__item m-grid__item--fluid m-grid m-error-4" style="background-image: url(./assets/app/media/img/error/bg4.jpg);">
	<div class="m-error_container">
		<h1 class="m-error_number">
			404
		</h1>
		<p class="m-error_title">
			ERROR
		</p>
		<p class="m-error_description">
			Nothing left to do here.
		</p>
	</div>
</div>

<div *ngIf="errorType === 5" class="m-grid__item m-grid__item--fluid m-grid m-error-5" style="background-image: url(./assets/app/media/img/error/bg5.jpg);">
	<div class="m-error_container">
		<span class="m-error_title">
			<h1>Oops!</h1>
		</span>
		<p class="m-error_subtitle">
			Something went wrong here.
		</p>
		<p class="m-error_description">
			We're working on it and we'll get it fixed<br>
			as soon possible.<br>
			You can back or use our Help Center.
		</p>
	</div>
</div>

<div *ngIf="errorType === 6" class="m-grid__item m-grid__item--fluid m-grid m-error-6" style="background-image: url(./assets/app/media/img/error/bg6.jpg);">
	<div class="m-error_container">
		<div class="m-error_subtitle m--font-light">
			<h1>Oops...</h1>
		</div>
		<p class="m-error_description m--font-light">
			Looks like something went wrong.
			<br> We're working on it
		</p>
	</div>
</div>
