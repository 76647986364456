/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./error-page.component";
import * as i4 from "@angular/router";
var styles_ErrorPageComponent = [i0.styles];
var RenderType_ErrorPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorPageComponent, data: {} });
export { RenderType_ErrorPageComponent as RenderType_ErrorPageComponent };
function View_ErrorPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "m-grid__item m-grid__item--fluid m-grid m-error-1"], ["style", "background-image: url(./assets/app/media/img/error/bg1.jpg);"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "m-error_container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "m-error_number"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["404"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "m-error_desc"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" OOPS! Something went wrong here "]))], null, null); }
function View_ErrorPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "m-grid__item m-grid__item--fluid m-grid m-error-2"], ["style", "background-image: url(./assets/app/media/img/error/bg2.jpg);"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "m-error_container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "m-error_title2 m--font-light"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["OOPS!"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "m-error_desc m--font-light"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Something went wrong here "]))], null, null); }
function View_ErrorPageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "m-grid__item m-grid__item--fluid m-grid m-error-3"], ["style", "background-image: url(./assets/app/media/img/error/bg3.jpg);"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "m-error_container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "m-error_number"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["404"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "m-error_title m--font-light"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" How did you get here "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["class", "m-error_subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Sorry we can't seem to find the page you're looking for. "])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "p", [["class", "m-error_description"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" There may be amisspelling in the URL entered,"])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" or the page you are looking for may no longer exist. "]))], null, null); }
function View_ErrorPageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "m-grid__item m-grid__item--fluid m-grid m-error-4"], ["style", "background-image: url(./assets/app/media/img/error/bg4.jpg);"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "m-error_container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "m-error_number"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 404 "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "m-error_title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" ERROR "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "m-error_description"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Nothing left to do here. "]))], null, null); }
function View_ErrorPageComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "m-grid__item m-grid__item--fluid m-grid m-error-5"], ["style", "background-image: url(./assets/app/media/img/error/bg5.jpg);"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "m-error_container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "m-error_title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Oops!"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "m-error_subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Something went wrong here. "])), (_l()(), i1.ɵeld(7, 0, null, null, 5, "p", [["class", "m-error_description"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" We're working on it and we'll get it fixed"])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" as soon possible."])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You can back or use our Help Center. "]))], null, null); }
function View_ErrorPageComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "m-grid__item m-grid__item--fluid m-grid m-error-6"], ["style", "background-image: url(./assets/app/media/img/error/bg6.jpg);"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "m-error_container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "m-error_subtitle m--font-light"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Oops..."])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "p", [["class", "m-error_description m--font-light"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Looks like something went wrong. "])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" We're working on it "]))], null, null); }
export function View_ErrorPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorPageComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorPageComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorPageComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorPageComponent_4)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorPageComponent_5)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorPageComponent_6)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.errorType === 1); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.errorType === 2); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.errorType === 3); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.errorType === 4); _ck(_v, 7, 0, currVal_3); var currVal_4 = (_co.errorType === 5); _ck(_v, 9, 0, currVal_4); var currVal_5 = (_co.errorType === 6); _ck(_v, 11, 0, currVal_5); }, null); }
export function View_ErrorPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "m-error-page", [], [[8, "className", 0]], null, null, View_ErrorPageComponent_0, RenderType_ErrorPageComponent)), i1.ɵdid(1, 114688, null, 0, i3.ErrorPageComponent, [i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
var ErrorPageComponentNgFactory = i1.ɵccf("m-error-page", i3.ErrorPageComponent, View_ErrorPageComponent_Host_0, { errorType: "errorType" }, {}, []);
export { ErrorPageComponentNgFactory as ErrorPageComponentNgFactory };
