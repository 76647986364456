import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class TokenStorage {
	/**
	 * Obtener el token de acceso
	 * @returns {Observable<string>}
	 */
	public getAccessToken(): Observable<string> {
		const token: string = <string>localStorage.getItem('accessToken');
		return of(token);
	}

	/**
	 * Obtener token de refresco de token
	 * @returns {Observable<string>}
	 */
	public getRefreshToken(): Observable<string> {
		const token: string = <string>localStorage.getItem('refreshToken');
		return of(token);
	}

	/**
	 * Obtener los roles para el usuario en sesión
	 * @returns {Observable<any>}
	 */
	public getUserRoles(): Observable<any> {
		const roles: any = localStorage.getItem('userRoles');
		try {
			return of(JSON.parse(roles));
		} catch (e) {}
	}

	/**
	 * Establecer un nuevo token de acceso
	 * @returns {TokenStorage}
	 */
	public setAccessToken(token: string): TokenStorage {
		localStorage.setItem('accessToken', token);

		return this;
	}

	/**
	 * Establecer el refresco de token
	 * @returns {TokenStorage}
	 */
	public setRefreshToken(token: string): TokenStorage {
		localStorage.setItem('refreshToken', token);

		return this;
	}

	/**
	 * Establecer los roles de usuario
	 * @param roles
	 * @returns {TokenStorage}
	 */
	public setUserRoles(roles: any): any {
		if (roles != null) {
			localStorage.setItem('userRoles', JSON.stringify(roles));
		}

		return this;
	}

	/**
	 * Establecer fecha de expiración del token
	 * @param expiresIn nueva fecha de expiración
	 */
	public setExpiresIn(expiresIn: any): any {
		if (expiresIn != null) {
			localStorage.setItem('expiresIn', expiresIn);
		}

		return this;
	}

	/**
	 * Obtener fecha de expiración para el token
	 */
	public getExpiresIn(): string {
		return localStorage.getItem('expiresIn');
	}

	/**
	 * Limpiar los datos almacenados para sesión
	 */
	public clear() {
		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');
		localStorage.removeItem('userRoles');
		localStorage.removeItem('expiresIn');
	}
}
