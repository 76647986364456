/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../core/pipes/first-letter.pipe";
import * as i2 from "@angular/common";
import * as i3 from "./messenger-in.component";
var styles_MessengerInComponent = [];
var RenderType_MessengerInComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MessengerInComponent, data: {} });
export { RenderType_MessengerInComponent as RenderType_MessengerInComponent };
function View_MessengerInComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "m-messenger__message-pic"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.message.pic, ""); _ck(_v, 1, 0, currVal_0); }); }
function View_MessengerInComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "m-messenger__message-no-pic m--bg-fill-danger"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵppd(3, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), _co.message.username)); _ck(_v, 2, 0, currVal_0); }); }
export function View_MessengerInComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.FirstLetterPipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 11, "div", [["class", "m-messenger__message m-messenger__message--in"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MessengerInComponent_1)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MessengerInComponent_2)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 6, "div", [["class", "m-messenger__message-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "div", [["class", "m-messenger__message-arrow"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 4, "div", [["class", "m-messenger__message-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "div", [["class", "m-messenger__message-username"]], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, [" ", " "])), (_l()(), i0.ɵeld(11, 0, null, null, 1, "div", [["class", "m-messenger__message-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(12, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message.pic; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.message.pic; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.message.username; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.message.text; _ck(_v, 12, 0, currVal_3); }); }
export function View_MessengerInComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "m-messenger-in", [], [[8, "className", 0]], null, null, View_MessengerInComponent_0, RenderType_MessengerInComponent)), i0.ɵdid(1, 114688, null, 0, i3.MessengerInComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
var MessengerInComponentNgFactory = i0.ɵccf("m-messenger-in", i3.MessengerInComponent, View_MessengerInComponent_Host_0, { message: "message" }, {}, []);
export { MessengerInComponentNgFactory as MessengerInComponentNgFactory };
