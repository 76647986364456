import {
	Component,
	OnInit,
	Input,
	Output,
	ViewChild,
	ElementRef,
	EventEmitter,
	ChangeDetectorRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../../../../core/auth/authentication.service';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as objectPath from 'object-path';
import { AuthNoticeService } from '../../../../core/auth/auth-notice.service';
import { SpinnerButtonOptions } from '../../../partials/content/general/spinner-button/button-options.interface';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { ForgotPasswordService } from '../../../../core/services/forgot-password.service';


@Component({
	selector: 'm-code',
	templateUrl: './code.component.html',
	styleUrls: ['./code.component.scss']
})
export class CodeComponent implements OnInit {
	processing = false;
	public model: any = { item: '', token: '' };
	@Input() action: string;
	@Input() email: string;
	@Output() passChange = new EventEmitter();
	loading: boolean;
	@Output() actionChange = new Subject<string>();

	submitted = false;
	// @ViewChild('f') f: NgForm;
	errors: any = [];

	form: FormGroup;


	spinner: SpinnerButtonOptions = {
		active: false,
		spinnerSize: 18,
		raised: true,
		buttonColor: 'primary',
		spinnerColor: 'accent',
		fullWidth: false
	};
	response: any;

	constructor(
		private authService: AuthenticationService,
		public authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private formBuilder: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private forgotService: ForgotPasswordService,
		private cdr: ChangeDetectorRef
	) {

		console.log(this.actionChange);
	}

	ngOnInit() {
		this.activatedRoute.params.subscribe(params => {
			const email_url = params.mail
			this.form = this.formBuilder.group({
				item: [email_url, [Validators.required, Validators.email]],
				token: ['', Validators.required],
			});
		});
		// this.model.item = this.email;
	}
	get f() { return this.form.controls; }


	submit() {

		this.submitted = true;

		if (!this.form.valid) {
			return;
		}

		this.processing = true;

		const values = { ...this.form.value };

		const errorCallback = (e) => {
			this.processing = false;
			console.log('error');
			let errorMsj = '';
			switch (e.status) {
				case 401:
					e.error.errors.messages.forEach(element => {
						errorMsj = element
					});
				break;

				case 422:
					let myText = "";
					let array = Object.keys(e.error.errors).map(key => {
						myText = myText + " " + e.error.errors[key][0];
					});
					for (let index = 0; index < array.length; index++) {
						if (index + 1 == array.length) {
							errorMsj = myText
						}
					}
					break;
				default:
					errorMsj = 'Error conecting to server'
				//catch in te interceptor
			}

			swal('Error', errorMsj, 'error');
			$('body').removeClass('swal2-height-auto');
			this.cdr.detectChanges();
		};

		const successCallback = async (data) => {
			this.processing = false;

			console.log(data.response.messages[0]);

			const cod_pass = data.response.messages[0];


			this.form.reset();
			this.translate.get([
				'GENERAL.SUCCESS',
			]).subscribe(async t => {
				// contraseña generada | tu contraseña temporal es: ****
				// let text_succ = ;
				let msj_success = `${this.translate.instant('GENERAL.SUCCESS_MSJ_TEMP')} <strong>${cod_pass}</strong>`;
				// btn cancelar usar
				swal({
					title: this.translate.instant('GENERAL.SUCCESS_PASS_TEMP'),
					html: msj_success,
					type: 'success',
					showCancelButton: true,
					// confirmButtonColor: '#3085d6',
					// cancelButtonColor: '#d33',
					confirmButtonText: this.translate.instant('GENERAL.BTN_USE'),
					cancelButtonText: this.translate.instant('GENERAL.CANCEL'),
				}).then((result) => {
					if (result.value) {
						this.router.navigateByUrl('/login?email='+values.item+'&pass='+cod_pass);
					}
				})
				$('body').removeClass('swal2-height-auto');
			});
			this.cdr.detectChanges();
		};

		this.forgotService.validateToken(values).subscribe(successCallback, errorCallback);

	}




	forgotPage(event: Event) {
		event.preventDefault();
		this.action = 'forgot-password';
		this.actionChange.next(this.action);
	}

}
