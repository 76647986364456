/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./footer.component";
import * as i3 from "../../../core/services/layout-config.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../core/services/company.service";
var styles_FooterComponent = [];
var RenderType_FooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "m-container m-page__container m-container--full-height"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(3, 0, null, null, 6, "div", [["class", "m-footer__wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 5, "div", [["class", "m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--last"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 3, "span", [["class", "m-footer__copyright"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" 2020 \u00A9 Livent "])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "a", [["class", "m-link"], ["href", "https://www.inmov.com/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Inmov "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "m-container m-page__container m-container--full-height"; var currVal_1 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 2).transform(_co.footerContainerClass$)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_FooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "m-footer", [], [[8, "className", 0]], null, null, View_FooterComponent_0, RenderType_FooterComponent)), i0.ɵdid(1, 114688, null, 0, i2.FooterComponent, [i3.LayoutConfigService, i4.TranslateService, i0.ChangeDetectorRef, i5.CompanyService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
var FooterComponentNgFactory = i0.ɵccf("m-footer", i2.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
