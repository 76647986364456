var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../../../../core/auth/authentication.service';
import { Validators, FormBuilder } from '@angular/forms';
import { AuthNoticeService } from '../../../../core/auth/auth-notice.service';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ForgotPasswordService } from '../../../../core/services/forgot-password.service';
import { LaravelValidationsService } from '../../../../core/services/laravel-validations.service';
/**
 * Componente encargado de la recuperación de contraseña
 */
var ForgotPasswordComponent = /** @class */ (function () {
    /**
     *
     * @param authService Servicio encargado de manejar la autenticación
     * @param authNoticeService Servicio encargado de gestionar las notificaciones
     * @param translate Servicio encargado de manejar la traducción
     * @param formBuilder Servicio encargado de generar los formulario en angular
     * @param router Servicio encargado de gestionar la navegación
     * @param forgotService Servicio encargado de proveer los servicio de recuperación de contraseña
     * @param laravelVal Servicio encargado de gestionar los errores generados por laravel
     * @param cdr Servicio encargado de gestionar los cambios en las vistas
     */
    function ForgotPasswordComponent(authService, authNoticeService, translate, formBuilder, router, forgotService, laravelVal, cdr) {
        this.authService = authService;
        this.authNoticeService = authNoticeService;
        this.translate = translate;
        this.formBuilder = formBuilder;
        this.router = router;
        this.forgotService = forgotService;
        this.laravelVal = laravelVal;
        this.cdr = cdr;
        /**
         * Evento para el cambio de acción ejecutada por la aplicación
         */
        this.actionChange = new Subject();
        /**
         * Evento emitido al momento de que el correo cambia
         */
        this.emailChange = new EventEmitter();
        /**
         * @ignore
         */
        this.loading = false;
        /**
         * @ignore
         */
        this.model = { email: '', method: '' };
        /**
         * Variable que identifica si el formulario de recuperación de contraseña ha sido enviado
         */
        this.submitted = false;
        /**
         * Listado de errores encontrados en el formulario
         */
        this.errors = [];
        /**
         * Variable que identifica si el formulario se esta procesando
         */
        this.processing = false;
        /**
         * Listado de opciones disponibles en el selector de método de recuperación de contraseña
         */
        this.positionsOptions = [
            { id: 'email', text: 'Email', value: 'email' },
            { id: 'sms', text: 'SMS', value: 'sms' },
        ];
        /**
         * Opciones que definen la configuración del spinnerde carga en el botón de envio del formulario
         */
        this.spinner = {
            active: false,
            spinnerSize: 18,
            raised: true,
            buttonColor: 'primary',
            spinnerColor: 'accent',
            fullWidth: false
        };
    }
    /**
     * Método ejecutado luego de que el componente es inicializado por angular
     * se encarga de inicia
     */
    ForgotPasswordComponent.prototype.ngOnInit = function () {
        this.form = this.formBuilder.group({
            item: ['', [Validators.required, Validators.email]],
            method: ['email', Validators.required],
        });
    };
    Object.defineProperty(ForgotPasswordComponent.prototype, "f", {
        /**
         * @ignore
         */
        get: function () { return this.form.controls; },
        enumerable: true,
        configurable: true
    });
    /**
     * Manejador de eventos para el envio del formulario de recuperación de contraseña
     */
    ForgotPasswordComponent.prototype.submit = function () {
        var _this = this;
        this.submitted = true;
        if (!this.form.valid) {
            return;
        }
        this.processing = true;
        var values = __assign({}, this.form.value);
        var errorCallback = function (e) {
            _this.processing = false;
            console.log(e.error.errors);
            _this.laravelVal.alert500(e);
            var errorMsj = '';
            switch (e.status) {
                case 401:
                    e.error.errors.messages.forEach(function (element) {
                        errorMsj = element;
                    });
                    break;
                case 422:
                    var myText_1 = '';
                    var array = Object.keys(e.error.errors).map(function (key) {
                        myText_1 = myText_1 + " " + e.error.errors[key][0];
                    });
                    for (var index = 0; index < array.length; index++) {
                        if (index + 1 == array.length) {
                            errorMsj = myText_1;
                        }
                    }
                    break;
                case 500:
                    errorMsj = e.error.errors.messages[0];
                    // swal(this.translate.instant('ERRORS.ERROR'), , 'error');
                    break;
                default:
                    errorMsj = 'Error conecting to server';
            }
            swal('Error', errorMsj, 'error');
            $('body').removeClass('swal2-height-auto');
            // alert('Error connecting to server please try later')
            _this.cdr.detectChanges();
        };
        var successCallback = function (data) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            var successMsj, myText_2, array, index;
            return __generator(this, function (_a) {
                this.processing = false;
                successMsj = '';
                if (data.errors) {
                    myText_2 = "";
                    array = Object.keys(data.errors).map(function (key) {
                        myText_2 = data.errors[key][0];
                    });
                    for (index = 0; index < array.length; index++) {
                        if (index + 1 == array.length) {
                            successMsj = myText_2;
                        }
                    }
                }
                this.form.reset();
                this.translate.get([
                    'GENERAL.SUCCESS',
                ]).subscribe(function (t) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        swal(t['GENERAL.SUCCESS'], successMsj, 'success');
                        $('body').removeClass('swal2-height-auto');
                        this.router.navigate(['/code/' + values.item]);
                        return [2 /*return*/];
                    });
                }); });
                this.cdr.detectChanges();
                return [2 /*return*/];
            });
        }); };
        this.forgotService.forgotPassword(values).subscribe(successCallback, errorCallback);
    };
    return ForgotPasswordComponent;
}());
export { ForgotPasswordComponent };
