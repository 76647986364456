var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { BehaviorSubject, Subject, from, throwError, of } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';
import moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { TokenStorage } from './token-storage.service';
import { UtilsService } from '../services/utils.service';
import { environment } from '../../../environments/environment';
import { AuthNoticeService } from './auth-notice.service';
import { TranslateService } from '@ngx-translate/core';
import { LaravelValidationsService } from '../services/laravel-validations.service';
/**
 * Servicio encargado de manejar el proceso de autenticación con los servicios del api inmovtech
 */
var AuthenticationService = /** @class */ (function () {
    /**
     *
     * @param http Cliente http de angular
     * @param tokenStorage Servicio encargado de gestionar el almacenamiento de los tokens de sesión
     * @param authNotice Servicio encargado de gestionar las alertas de autenticación
     * @param util Servicio encargado de proveer utilitarios transversales a la aplicación
     * @param translate Servicio encargado de gestionar la traducción
     * @param laravelVal Servicio encargado de gestionar todos los códigos de error que pueden ser generados por el api inmovtech
     */
    function AuthenticationService(http, tokenStorage, authNotice, util, translate, laravelVal) {
        this.http = http;
        this.tokenStorage = tokenStorage;
        this.authNotice = authNotice;
        this.util = util;
        this.translate = translate;
        this.laravelVal = laravelVal;
        /**
         * Url principal del api inmovtech
         */
        this.API_URL = environment.apiUrl;
        /**
         * Ruta a la cual se deben enviar las peticiones de inicio se sesión
         */
        this.API_ENDPOINT_LOGIN = '/auth/login';
        /**
         * Ruta a la cual se deben enviar las peticiones de recuperación de contraseña
         */
        this.API_ENDPOINT_RECOVERY = '/app/recovery/pass';
        /**
         * Ruta a la cual se deben enviar las peticiones de validación del token
         */
        this.API_ENDPOINT_VALIDATE_TOKEN = '/app/validate/token';
        /**
         * Ruta a la cual se deben enviar las peticiones de inicio de sesión con redes sociales
         */
        this.API_GOOGLE_LOGIN = '/auth/login_social';
        /**
         * Ruta a la cual se deben enviar las peticiones para refrescar el token de acceso
         */
        this.API_ENDPOINT_REFRESH = '/refresh';
        /**
         * Ruta a la cual se deben enviar las peticiones de registro de nuevos usuarios
         */
        this.API_ENDPOINT_REGISTER = '/register';
        /**
         * Ruta que permite aceptar los terminos y condiciones
         */
        this.API_AGREE_TERMS = '/api/v1/terms/accept/new';
        /**
         * Evento lanzado al momento que el usuario en sesión cambie
         */
        this.userSubject = new BehaviorSubject(null);
        this.onCredentialUpdated$ = new Subject();
    }
    /**
     * Verificar si el usuario tiene una sesión activa
     * @returns {Observable<boolean>}
     * @memberOf AuthService
     */
    AuthenticationService.prototype.isAuthorized = function () {
        return this.tokenStorage.getAccessToken().pipe(map(function (token) { return !!token; }));
    };
    /**
     * Obterner el token de acceso para el usuario en sesión
     * @returns {Observable<string>}
     */
    AuthenticationService.prototype.getAccessToken = function () {
        return this.tokenStorage.getAccessToken();
    };
    /**
     * Permite obtener los roles que tiene el usuario en sesión
     * @returns {Observable<any>}
     */
    AuthenticationService.prototype.getUserRoles = function () {
        return this.tokenStorage.getUserRoles();
    };
    /**
     * Método encargado de refrescar el token de acceso y validar que este continue siendo válido
     * @returns {Observable<any>}
     */
    AuthenticationService.prototype.refreshToken = function () {
        var _this = this;
        return this.tokenStorage.getRefreshToken().pipe(switchMap(function (refreshToken) {
            return _this.http.get(_this.API_URL + _this.API_ENDPOINT_REFRESH + '?' + _this.util.urlParam(refreshToken));
        }), tap(this.saveAccessData.bind(this)), catchError(function (err) {
            _this.logout();
            return throwError(err);
        }));
    };
    /**
     * Verifica un respuesta fallida para determinar si se debe refrescar el token de acceso
     * @param {Response} response
     * @returns {boolean}
     */
    AuthenticationService.prototype.refreshShouldHappen = function (response) {
        return response.status === 401;
    };
    /**
     * Permite identificar si la peticion se realiza para refrescar el token de acceso
     * @param {string} url
     * @returns {boolean}
     */
    AuthenticationService.prototype.verifyTokenRequest = function (url) {
        return url.endsWith(this.API_ENDPOINT_REFRESH);
    };
    /**
     * Realiza la autenticación con usuario y contraseña del usuario
     * @param {Credential} credential Credenciales con las cuales se hará la autenticación
     * @returns {Observable<any>}
     */
    AuthenticationService.prototype.login = function (credential) {
        var _this = this;
        // Expecting response from API
        return this.http.post(this.API_URL + this.API_ENDPOINT_LOGIN, {
            login: credential.email,
            password: credential.password,
            language: 'EN',
        }, {
            headers: {
                'X-Platform': 'panel'
            }
        }).pipe(map(function (result) {
            _this.userSubject.next(result);
            if (result instanceof Array) {
                return result.pop();
            }
            return result;
        }), tap(this.saveAccessData.bind(this)), catchError(function (ex) {
            if (ex.status === 401) {
                _this.authNotice.setNotice(ex.error.errors.messages[0], 'error');
                return throwError(ex.error.errors.messages[0]);
            }
            _this.authNotice.setNotice(_this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'error');
            return throwError(_this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'));
        }));
    };
    /**
     * Obtener el timezone del usuario
     */
    AuthenticationService.prototype.getUserTimeZone = function () {
        return /\((.*)\)/.exec(new Date().toString())[1];
    };
    /**
     * Obtener el idioma del usuario
     */
    AuthenticationService.prototype.getLang = function () {
        return localStorage.getItem('language');
    };
    /**
     * Funcion de autenticación por medio de google
     * @param credential credenciales con las cuales se realizará la autenticación social con el api inmovtech
     */
    AuthenticationService.prototype.googlelogin = function (credential) {
        var _this = this;
        // Expecting response from API
        return this.http.post(this.API_URL + this.API_GOOGLE_LOGIN, __assign({}, credential, { user_lang: this.getLang(), time_zone: this.getUserTimeZone() })).pipe(map(function (result) {
            if (result instanceof Array) {
                return result.pop();
            }
            return result;
        }), tap(this.saveAccessData.bind(this)), catchError(function (ex) {
            if (ex.code === 401) {
                ex.code = 402;
            }
            _this.laravelVal.alertErrors(ex);
            return of();
        }));
    };
    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    AuthenticationService.prototype.handleError = function (operation, result) {
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            // Let the app keep running by returning an empty result.
            return from(result);
        };
    };
    /**
     * Terminar la sesión para el usuario en sesión
     * @param refresh
     */
    AuthenticationService.prototype.logout = function (refresh) {
        this.tokenStorage.clear();
        if (refresh) {
            location.reload(true);
        }
    };
    /**
     * Remover el token de sesión almacenado en el sistema
     */
    AuthenticationService.prototype.clearToken = function () {
        this.tokenStorage.clear();
    };
    /**
     * Generar unixtimestamp de periodo de expiración
     * @param seconds
     */
    AuthenticationService.prototype.createExpiresIn = function (seconds) {
        return moment().add(seconds, 'seconds').unix();
    };
    /**
     * Almacenar en localstorage todos los datos de acceso para futuros ingresos
     * @private
     * @param {AccessData} data
     */
    AuthenticationService.prototype.saveAccessData = function (accessData) {
        if (typeof accessData !== 'undefined') {
            this.tokenStorage
                .setAccessToken(accessData.accessToken)
                .setRefreshToken(accessData.refreshToken)
                .setExpiresIn(this.createExpiresIn(accessData.expires_in))
                .setUserRoles(['USER']);
            this.onCredentialUpdated$.next(accessData);
        }
    };
    /**
     * Enviar una petición para registrar un nuevo usuario
     * @param {Credential} credential Información con la cual se creará la cuenta
     * @returns {Observable<any>}
     */
    AuthenticationService.prototype.register = function (credential) {
        // dummy token creation
        credential = Object.assign({}, credential, {
            accessToken: 'access-token-' + Math.random(),
            refreshToken: 'access-token-' + Math.random(),
            roles: ['USER'],
        });
        return this.http.post(this.API_URL + this.API_ENDPOINT_REGISTER, credential);
    };
    /**
     * Método ejecutado al momento de que le usuario en sesión acepta los terminos y condiciones
     */
    AuthenticationService.prototype.agreeTermsAndConditions = function () {
        return this.http.get(this.API_URL + this.API_AGREE_TERMS);
    };
    return AuthenticationService;
}());
export { AuthenticationService };
