import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	ChangeDetectorRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../../../../core/auth/authentication.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthNoticeService } from '../../../../core/auth/auth-notice.service';
import { SpinnerButtonOptions } from '../../../partials/content/general/spinner-button/button-options.interface';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ForgotPasswordService } from '../../../../core/services/forgot-password.service';
import { LaravelValidationsService } from '../../../../core/services/laravel-validations.service';

/**
 * Componente encargado de la recuperación de contraseña
 */
@Component({
	selector: 'm-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
	/**
	 * Acción que está siendo ejecutada por la aplicación usado principalmente para
	 * redirigir a la vista correspondiente
	 */
	@Input() action: string;

	/**
	 * Evento para el cambio de acción ejecutada por la aplicación
	 */
	@Output() actionChange = new Subject<string>();

	/**
	 * Evento emitido al momento de que el correo cambia
	 */
	@Output() emailChange = new EventEmitter()

	/**
	 * @ignore
	 */
	public loading = false;

	/**
	 * @ignore
	 */
	public model: any = { email: '', method: '' };

	/**
	 * Variable que identifica si el formulario de recuperación de contraseña ha sido enviado
	 */
	submitted = false;

	/**
	 * Listado de errores encontrados en el formulario
	 */
	errors: any = [];

	/**
	 * Variable que identifica si el formulario se esta procesando
	 */
	processing = false;

	/**
	 * Formulario
	 */
	form: FormGroup;

	/**
	 * Listado de opciones disponibles en el selector de método de recuperación de contraseña
	 */
	positionsOptions: any = [
		{ id: 'email', text: 'Email', value: 'email' },
		{ id: 'sms', text: 'SMS', value: 'sms' },
	];

	/**
	 * Opciones que definen la configuración del spinnerde carga en el botón de envio del formulario
	 */
	spinner: SpinnerButtonOptions = {
		active: false,
		spinnerSize: 18,
		raised: true,
		buttonColor: 'primary',
		spinnerColor: 'accent',
		fullWidth: false
	};

	/**
	 * 
	 * @param authService Servicio encargado de manejar la autenticación
	 * @param authNoticeService Servicio encargado de gestionar las notificaciones
	 * @param translate Servicio encargado de manejar la traducción
	 * @param formBuilder Servicio encargado de generar los formulario en angular
	 * @param router Servicio encargado de gestionar la navegación
	 * @param forgotService Servicio encargado de proveer los servicio de recuperación de contraseña
	 * @param laravelVal Servicio encargado de gestionar los errores generados por laravel
	 * @param cdr Servicio encargado de gestionar los cambios en las vistas
	 */
	constructor(
		private authService: AuthenticationService,
		public authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private formBuilder: FormBuilder,
		private router: Router,
		private forgotService: ForgotPasswordService,
		private laravelVal: LaravelValidationsService,
		private cdr: ChangeDetectorRef,
	) {
	}

	/**
	 * Método ejecutado luego de que el componente es inicializado por angular
	 * se encarga de inicia
	 */
	ngOnInit() {
		this.form = this.formBuilder.group({
			item: ['', [Validators.required, Validators.email]],
			method: ['email', Validators.required],
		});
	}

	/**
	 * @ignore
	 */
	get f() { return this.form.controls; }

	/**
	 * Manejador de eventos para el envio del formulario de recuperación de contraseña
	 */
	submit() {
		this.submitted = true;

		if (!this.form.valid) {
			return;
		}

		this.processing = true;

		const values = { ...this.form.value };

		const errorCallback = (e) => {
			this.processing = false;
			console.log(e.error.errors);
			this.laravelVal.alert500(e);
			let errorMsj = '';
			switch (e.status) {
				case 401:
					e.error.errors.messages.forEach(element => {
						errorMsj = element
					});
				break;

				case 422:
					let myText = '';
					let array = Object.keys(e.error.errors).map(key => {
						myText = myText + " " + e.error.errors[key][0];
					});
					for (let index = 0; index < array.length; index++) {
						if (index + 1 == array.length) {
							errorMsj = myText
						}
					}
				break;
				case 500:
					errorMsj = e.error.errors.messages[0];
					// swal(this.translate.instant('ERRORS.ERROR'), , 'error');

				break;
				default:
					errorMsj = 'Error conecting to server';
				//catch in te interceptor
			}

			swal('Error', errorMsj, 'error');
			$('body').removeClass('swal2-height-auto');

			// alert('Error connecting to server please try later')
			this.cdr.detectChanges();
		};

		const successCallback = async (data) => {
			this.processing = false;
			var successMsj = '';
			if (data.errors) {
				let myText = "";
				let array = Object.keys(data.errors).map(key => {
					myText = data.errors[key][0];
				});
				for (let index = 0; index < array.length; index++) {
					if (index + 1 == array.length) {
						successMsj = myText
					}
				}
			}

			this.form.reset();
			this.translate.get([
				'GENERAL.SUCCESS',
			]).subscribe(async t => {
				swal(t['GENERAL.SUCCESS'], successMsj, 'success');
				$('body').removeClass('swal2-height-auto');
				this.router.navigate(['/code/' + values.item]);
			});
			this.cdr.detectChanges();
			// this.action = 'login';
			// this.actionChange.next(this.action);
		};


		this.forgotService.forgotPassword(values).subscribe(successCallback, errorCallback);
	}
}
