/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../core/pipes/join.pipe";
import * as i4 from "../../../../core/pipes/get-object.pipe";
import * as i5 from "./menu-horizontal.component";
import * as i6 from "../../../../core/services/class-init.service";
import * as i7 from "../../../../core/services/layout/menu-horizontal.service";
import * as i8 from "../../../../core/services/menu-config.service";
import * as i9 from "@angular/router";
var styles_MenuHorizontalComponent = [];
var RenderType_MenuHorizontalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MenuHorizontalComponent, data: {} });
export { RenderType_MenuHorizontalComponent as RenderType_MenuHorizontalComponent };
function View_MenuHorizontalComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 2, null, null, null, null, null, null, null)), i0.ɵdid(1, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(2, { item: 0 }), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _v.parent.context.$implicit); var currVal_1 = _co.m_menu; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_MenuHorizontalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuHorizontalComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; _ck(_v, 2, 0, currVal_0); }, null); }
function View_MenuHorizontalComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "i", [["class", "m-menu__link-icon"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var currVal_0 = "m-menu__link-icon"; var currVal_1 = _v.parent.context.item.icon; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_MenuHorizontalComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "i", [["class", "m-menu__link-bullet m-menu__link-bullet--dot"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [], null, null, null, null, null))], null, null); }
function View_MenuHorizontalComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "i", [["class", "m-menu__link-bullet m-menu__link-bullet--line"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [], null, null, null, null, null))], null, null); }
function View_MenuHorizontalComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "m-menu__item-here"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [["class", "m-menu__link-text"]], null, null, null, null, null)), i0.ɵdid(3, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = _v.parent.context.item.translate; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.context.item.title; _ck(_v, 4, 0, currVal_1); }); }
function View_MenuHorizontalComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "span", [["class", "m-menu__link-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "span", [["class", "m-menu__link-wrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [["class", "m-menu__link-text"]], null, null, null, null, null)), i0.ɵdid(3, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 4, "span", [["class", "m-menu__link-badge"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 3, "span", [["class", "m-badge"]], null, null, null, null, null)), i0.ɵdid(7, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵdid(8, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(9, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.parent.context.item.translate; _ck(_v, 3, 0, currVal_0); var currVal_2 = "m-badge"; var currVal_3 = _v.parent.context.item.badge.type; _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = _v.parent.context.item.badge.translate; _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var currVal_1 = _v.parent.context.item.title; _ck(_v, 4, 0, currVal_1); var currVal_5 = _v.parent.context.item.badge.value; _ck(_v, 9, 0, currVal_5); }); }
function View_MenuHorizontalComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuHorizontalComponent_4)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuHorizontalComponent_5)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuHorizontalComponent_6)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuHorizontalComponent_7)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["m_menu_link_badge", 2]], null, 0, null, View_MenuHorizontalComponent_8))], function (_ck, _v) { var currVal_0 = _v.context.item.icon; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_v.context.parentItem && (_v.context.parentItem.bullet === "dot")); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_v.context.parentItem && (_v.context.parentItem.bullet === "line")); _ck(_v, 5, 0, currVal_2); var currVal_3 = !_v.context.item.badge; var currVal_4 = i0.ɵnov(_v, 8); _ck(_v, 7, 0, currVal_3, currVal_4); }, null); }
function View_MenuHorizontalComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 2, null, null, null, null, null, null, null)), i0.ɵdid(2, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(3, { item: 0, parentItem: 1 }), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit, _v.parent.parent.context.item); var currVal_1 = _co.m_menu; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_MenuHorizontalComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "ul", [["class", "m-menu__inner"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuHorizontalComponent_11)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.item.items; _ck(_v, 3, 0, currVal_0); }, null); }
function View_MenuHorizontalComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "li", [["class", "m-menu__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "h3", [["class", "m-menu__heading m-menu__toggle"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [["class", "m-menu__link-text"]], null, null, null, null, null)), i0.ɵdid(3, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(4, null, [" ", " "])), (_l()(), i0.ɵeld(5, 0, null, null, 0, "i", [["class", "m-menu__ver-arrow la la-angle-right"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MenuHorizontalComponent_10)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.item.heading.translate; _ck(_v, 3, 0, currVal_0); var currVal_2 = _v.context.item.items; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = _v.context.item.heading.title; _ck(_v, 4, 0, currVal_1); }); }
export function View_MenuHorizontalComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i3.JoinPipe, []), i0.ɵpid(0, i4.GetObjectPipe, []), (_l()(), i0.ɵeld(2, 0, null, null, 7, "ul", [["class", "m-menu__nav"]], null, null, null, null, null)), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵppd(5, 2), i0.ɵppd(6, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_MenuHorizontalComponent_1)), i0.ɵdid(8, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["m_menu_item_inner", 2]], null, 0, null, View_MenuHorizontalComponent_3)), (_l()(), i0.ɵand(0, [["m_menu_column", 2]], null, 0, null, View_MenuHorizontalComponent_9))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "m-menu__nav"; var currVal_1 = i0.ɵunv(_v, 3, 1, _ck(_v, 6, 0, i0.ɵnov(_v, 0), i0.ɵunv(_v, 3, 1, _ck(_v, 5, 0, i0.ɵnov(_v, 1), i0.ɵunv(_v, 3, 1, i0.ɵnov(_v, 4).transform(_co.classInitService.onClassesUpdated$)), "header_menu_nav")))); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform(_co.menuHorService.menuList$)); _ck(_v, 8, 0, currVal_2); }, null); }
export function View_MenuHorizontalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "m-menu-horizontal", [], [[8, "className", 0], [8, "id", 0], [1, "mMenuHorizontalOffcanvas", 0], [1, "mMenuHorizontal", 0]], null, null, View_MenuHorizontalComponent_0, RenderType_MenuHorizontalComponent)), i0.ɵdid(1, 4308992, null, 0, i5.MenuHorizontalComponent, [i0.ElementRef, i6.ClassInitService, i7.MenuHorizontalService, i8.MenuConfigService, i9.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).classes; var currVal_1 = i0.ɵnov(_v, 1).id; var currVal_2 = i0.ɵnov(_v, 1).mMenuHorOffcanvas; var currVal_3 = i0.ɵnov(_v, 1).mMenuHorizontal; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
var MenuHorizontalComponentNgFactory = i0.ɵccf("m-menu-horizontal", i5.MenuHorizontalComponent, View_MenuHorizontalComponent_Host_0, {}, {}, []);
export { MenuHorizontalComponentNgFactory as MenuHorizontalComponentNgFactory };
