import {
	Component,
	OnInit,
	Input,
	Output,
	ChangeDetectorRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../../../../core/auth/authentication.service';
import { AuthNoticeService } from '../../../../core/auth/auth-notice.service';
import { SpinnerButtonOptions } from '../../../partials/content/general/spinner-button/button-options.interface';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from '../../../../core/services/company.service';
import { FormGroup, FormControl, Validators, FormBuilder, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { environment } from '../../../../../environments/environment';


declare var gapi: any;

/**
 * @memberof AuthModule
 * Componente para el registro de nuevos usuarios
 */
@Component({
	selector: 'm-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
	/**
	 * Indica si el formulario se esta procesando
	 */
	processing:boolean = false;
	/**
	 * Datos que han sido ingresados por el usuario al formulario
	 */
	public model: any = { email: '' };
	/**
	 * Acción que está siendo ejecutada por la aplicación
	 */
	@Input() action: string;
	/**
	 * Evento ejecutado al momento en que cambia la acción ejecutada por la aplicación
	 */
	@Output() actionChange = new Subject<string>();

	/**
	 * Variable que indica si se esta cargando información del servidor
	 */
	public loading = false;

	/**
	 * Listado de errores encontrados al momento de tratar de enviar el formulario de registro
	 */
	errors: any = [];

	/**
	 * Instancia de FormGroup usada para almacenar la información del registro
	 */
	form: FormGroup;

	/**
	 * Indica si el formulario se ha procesado con anterioridad
	 */
	submitted = false;
	/**
	 * Indica si el registro se esta realizando con redes sociales
	 */
	social = false;

	/**
	 * Configuración del spinner de carga del botón de envio del formulario
	 */
	spinner: SpinnerButtonOptions = {
		active: false,
		spinnerSize: 18,
		raised: true,
		buttonColor: 'primary',
		spinnerColor: 'accent',
		fullWidth: false
	};

	/**
	 * Respuesta generada al momento de autenticar el usuario con su cuenta de google
	 */
	auth2: any;

	/**
	 * Objeto retornado por el servidor con la última versión de los terminos y condiciones
	 * en todos los idiomas disponibles.
	 */
	termsAndPrivacy: any;

	/**
	 * @ignore
	 */
	titleTerms: string;

	/**
	 * @ignore
	 */
	messageTerms: string;

	/**
	 * @ignore
	 */
	titlePrivicy: string;

	/**
	 * @ignore
	 */
	messagePrivicy: string;


	/**
	 * 
	 * @param authService Servicio encargado de gestionar la autenticación
	 * @param authNoticeService Servicio encargado de gestionar las notificaciones mostradas al usuario
	 * @param translate Servicio encargado proveer traducciones
	 * @param companyService Servicio encargado de gestionar los datos de la compañia por medio del api inmovtech
	 * @param router Servicio encargado de gestionar las rutas dentro de la aplicación
	 * @param formBuilder Servicio de angular para la generación de formularios
	 * @param auth Servicio encargado de gestionar la autenticación del usuario de cara al api inmovtech
	 * @param cdr Servicio encargado de la detección de cambios de angular
	 */
	constructor(
		private authService: AuthenticationService,
		public authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private companyService: CompanyService,
		private router: Router,
		private formBuilder: FormBuilder,
		private auth: AuthenticationService,
		private cdr: ChangeDetectorRef,
	) {
	}

	/**
	 * Inicializa el formulario de registro con sus respectivas validaciones 
	 */
	ngOnInit() {
		this.companyService.getTermsAndContidtios().subscribe( ( response: any) => {
			this.termsAndPrivacy = response[0];
		}, (e: any) => {
			console.log('e', e);
		}, () => {
			console.log('Termino');
		});
		this.translate.get('lang', {value: 'lang'}).subscribe((data: any) => {});
		gapi.load('auth2', () => {
			this.auth2 = gapi.auth2.init({
        client_id: environment.googleClientId,
        cookiepolicy: 'single_host_origin',
      });
			this.attachSignin(document.getElementById('customBtn2'));
		});

		this.form = this.formBuilder.group({
			email: ['', Validators.required],
			password: ['', Validators.required],
			first_name: ['', Validators.required],
			last_name: ['', Validators.required],
			company: ['', Validators.required],
			tyc:  new FormControl(false, Validators.requiredTrue),
			language: [this.translate.getBrowserLang(), Validators.required]
			// logo: ''
		});
	}

	/**
	 * @ignore
	 */
	get f() { return this.form.controls; }

	/**
	 * Redirigir a la pagina de inicio de sesión
	 * @param event 
	 */
	loginPage(event: Event) {
		event.preventDefault();
		this.action = 'login';
		this.actionChange.next(this.action);
	}

	/**
	 * Inicializa el plugin de inicio de sesión con google
	 * @param element 
	 */
	attachSignin(element) {
		this.auth2.attachClickHandler(element, {},
			(googleUser) => {
			  const basicProfile = googleUser.getBasicProfile();
			  this.form.patchValue({
			  	email: basicProfile.getEmail(),
			  	first_name: basicProfile.getGivenName(),
					last_name: basicProfile.getFamilyName(),
					password: '123456'
			  });
			  this.social = true;
        this.cdr.detectChanges();
			}, (error) => {
			});
	}

	/**
	 * Manejador de eventos para el envio del formulario de registro
	 */
	onSubmit() {
	this.cdr.detectChanges();
	this.submitted = true;
	if (!this.form.valid) {
		Object.keys(this.form.controls).forEach(field => {
			const control = this.form.get(field);
			control.markAsTouched({ onlySelf: true });
		});
		return;
	}
	this.processing = true;
	const values = { ...this.form.value };
	if (this.social) {
		values.type_register = 'S';
	} else {
		values.type_register = 'M';
	}
    const errorCallback = (e) => {
		let errorMsj = '';
	
		switch (e.status) {
			case 401:
			  e.error.errors.messages.forEach(element => {
					errorMsj = element
			  });
			break;
			case 422:
			
			  let myText = '';
			  let array = Object.keys(e.error.errors).map(key => {
				myText = myText + " " + e.error.errors[key][0];
			  });
			  for (let index = 0; index < array.length; index++) {
				if (index + 1 == array.length) {
					errorMsj = myText
				}
			  }
			  break;
			default:
			  errorMsj = 'Error conecting to server'
			//catch in te interceptor
			}
			this.processing = false;
			this.cdr.detectChanges();
      swal('Error', errorMsj, 'error');
			// alert('Error connecting to server please try later')
			
    };

    const successCallback = async (data) => {

			this.processing = false;

		console.log(data);

		var successMsj = '';
		if(data.errors){
			let myText = "";
			let array = Object.keys(data.errors).map(key => {
			  myText = data.errors[key][0];
			});
			for (let index = 0; index < array.length; index++) {
			  if (index + 1 == array.length) {
				successMsj = myText
			  }
			}
		}else{
			successMsj = "Error conecting to server (S)";
			// return;
		}

		this.form.reset();
		this.translate.get([
      'GENERAL.SUCCESS',
    ]).subscribe(async t => {
			await swal(t['GENERAL.SUCCESS'], successMsj, 'success');
			this.router.navigate(['/login']);
		});
		this.cdr.detectChanges();
		// this.action = 'login';
		// this.actionChange.next(this.action);
	};
    this.companyService.createCompany(values).subscribe(successCallback, errorCallback);
	}


	/**
	 * Se encarga de mostrar los terminos y condiciones o las políticas de privacidad según el caso
	 * @param type 
	 */
	async forgotPasswordPage(type: string) {
		console.log(this.translate.getBrowserLang());
		let lang: any;
		await this.translate.stream('GENERAL').subscribe((res) => {
			lang = res.lang;
		});
		if (lang === 'es') {
			this.titleTerms = 'Términos de servicio';
			this.messageTerms = this.termsAndPrivacy.terms_es;
			this.titlePrivicy = 'Política de privacidad';
			this.messagePrivicy = this.termsAndPrivacy.policy_es.replace('<a', '<a style="color: red;" href');
		}
		if (lang === 'en') {
			this.titleTerms = 'Terms of Service';
			this.messageTerms = this.termsAndPrivacy.terms_en;
			this.titlePrivicy = 'Privacy Policy';
			this.messagePrivicy = this.termsAndPrivacy.policy_en.replace('<a', '<a style="color: red;" href');
		}
		console.log(this.termsAndPrivacy.policy_es.replace('<a', '<a style="color: red;" href'));
		switch (type) {
			case 'terms':
			(<any>$('#myModal')).modal();
			this.cdr.detectChanges();
			$('.modal-a-color-primary a').css('color', '#6a25c6');
			// swal( titleTerms, messageTerms);
			break;
			case 'privacy':
			(<any>$('#myModal2')).modal();
			this.cdr.detectChanges();
			$('.modal-a-color-primary a').css('color', '#6a25c6');
			break;
		}
	}
}
