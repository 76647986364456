<!--begin::Signin-->

<body style="flex: 1" class="m-grid__item m-grid__item--fluid m-grid m-grid--desktop m-grid--ver-desktop m-grid--hor-tablet-and-mobile m-login m-login--6"
 id="m_login">
	<div class="m-grid__item m-grid__item--fluid  m-grid__item--order-tablet-and-mobile-1 m-login__wrapper">
		<!--begin::Body-->
		<!--begin::Signin-->
		<div class="m-login__body">
			<div class="m-login__signin">
				<div class="m-login__title">
					<h3 class="m-login__title"  translate="AUTH.FORGOT.TITLE">Forgotten Password</h3>
					<div class="m-login__desc" style="text-align: justify !important">
						<span translate="AUTH.FORGOT.MESSAGE_1">Enter your code to reset your password:</span>
						<br>
						<br>
						<span translate="AUTH.FORGOT.MESSAGE_2">Enter your code to reset your password:</span>						
						<a style="color: #FA4260" routerLink="/forgot-password"><span translate="AUTH.FORGOT.MESSAGE_3"></span></a>
					</div>
				</div>

				<!-- <m-auth-notice></m-auth-notice> -->

				<!--begin::Form-->
				<form class="m-login__form m-form"  [formGroup]="form" (ngSubmit)="submit()">
					<div class="form-group">
						<!-- <mat-form-field> -->
						<!-- <mat-label>Email</mat-label> -->
						<label translate="AUTH.REGISTER.EMAIL_ADDRESS">Email address</label>
						<input class="form-control m-input m-input--square"  
						type="email" 
						placeholder="Email address" 
						[ngClass]="{ 'is-invalid': submitted && f.item.errors }" 
						formControlName="item"
						 disabled required>
						 <div *ngIf="submitted && f.item.errors" class="invalid-feedback">
								<div *ngIf="f.item.errors.required" translate="INPUT.VALIDATIONS.REQUIRED">Field required</div>
								<div *ngIf="f.item.errors.email" translate="INPUT.VALIDATIONS.EMAIL">Invalid email</div>
							</div>
						<!-- </mat-form-field> -->
					</div>
					<div class="form-group">
						<!-- <mat-form-field> -->
						<!-- <mat-label>Code</mat-label> -->
						<label  translate="AUTH.FORGOT.CODE">Código</label>
						<input class="form-control m-input m-input--square" 
						 type="number" 
						 formControlName="token"
						 [placeholder]="'INPUT.PLACEHOLDERS.CODE' | translate"
						 autocomplete="off" 
						 [ngClass]="{ 'is-invalid': submitted && f.token.errors }" 
						 maxlength="10"
						 required>
						 <div *ngIf="submitted && f.token.errors" class="invalid-feedback">
								<div *ngIf="f.token.errors.required" translate="INPUT.VALIDATIONS.REQUIRED">Field required</div>
								<div *ngIf="f.token.errors.email" translate="INPUT.VALIDATIONS.EMAIL">Invalid email</div>
							</div>
						<!-- </mat-form-field> -->
					</div>
				</form>
				<!--end::Form-->

				<!--begin::Action-->
				<div class="m-login__action m-login__action--fit">
						<button  type="button" _ngcontent-c3="" routerLink="/forgot-password" mat-button="" class="mat-button">
							<span class="mat-button-wrapper" translate="AUTH.GENERAL.BACK_BUTTON">Back</span>
							<div class="mat-button-ripple mat-ripple" matripple=""></div>
							<div class="mat-button-focus-overlay"></div>
						</button>
					<!-- <button mat-button (click)="forgotPage($event)" [disabled]="loading" translate="AUTH.GENERAL.BACK_BUTTON">Back</button> -->
					<!-- <m-spinner-button [options]="spinner" (click)="submit()">{{'AUTH.GENERAL.SUBMIT_BUTTON' | translate}}</m-spinner-button> -->
					
					<button (click)="submit()" color="primary" class="mat-raised-button mat-primary" [disabled]="processing"
						[ngClass]="{'m-loader m-loader--light m-loader--left': processing}" mat-raised-button>
						<span *ngIf="!processing" translate="AUTH.GENERAL.SUBMIT_BUTTON">Send</span>
						<span *ngIf="processing" translate="AUTH.GENERAL.SUBMIT_BUTTON">Send</span>
					</button>
				</div>
				<!--end::Action-->
			</div>
		</div>
		<!--end::Signin-->
	</div>
	<div class="m-grid__item   m-grid__item--order-tablet-and-mobile-2 m-grid m-grid--hor m-login__aside" style="background-image: url(assets/app/media/img/bg/bg-4.jpg); background-size: cover">
		<div class="m-grid__item">
		</div>

		<div class="m-grid__item m-grid__item--fluid m-grid m-grid--ver">
			<div class="m-grid__item m-grid__item--middle">
			</div>
		</div>
	</div>
</body>