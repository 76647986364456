var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { ElementRef, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../../../../../core/services/user.service';
import { environment } from '../../../../../../environments/environment';
var UserProfileComponent = /** @class */ (function () {
    function UserProfileComponent(router, authService, sanitizer, userService, cdr) {
        this.router = router;
        this.authService = authService;
        this.sanitizer = sanitizer;
        this.userService = userService;
        this.cdr = cdr;
        this.user = {};
        // tslint:disable-next-line:max-line-length
        this.classes = 'm-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light';
        this.attrDropdownToggle = 'click';
        this.avatar = './assets/app/media/img/users/user4.jpg';
        this.avatarBg = '';
    }
    UserProfileComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                if (!this.avatarBg) {
                    this.avatarBg = this.sanitizer.bypassSecurityTrustStyle('url(./assets/app/media/img/misc/user_profile_bg.jpg)');
                }
                this.userService.getMe()
                    .subscribe(function (user) {
                    _this.userService.me = user;
                    _this.user = user;
                    if (user.avatar == null) {
                        _this.avatar = "./assets/app/media/img/users/user4.jpg";
                    }
                    else {
                        _this.avatar = environment.assetsDomain + "/storage/" + user.avatar;
                    }
                    if (user.account_status !== 'Active') {
                        switch (user.account_status) {
                            case 'PaymentBlock': {
                                // @ts-ignore
                                window.$.blockUI({ message: _this.translate.instant('ACCOUNT.SUSPENDED') });
                            }
                            case 'PaymentAlert': {
                                // @ts-ignore
                                window.$.blockUI({ message: _this.translate.instant('ACCOUNT.PAYMENT_ALERT') });
                            }
                            case 'DemoAlert': {
                                // @ts-ignore
                                window.$.blockUI({ message: _this.translate.instant('ACCOUNT.DEMO_ALERT') });
                            }
                        }
                    }
                    _this.cdr.detectChanges();
                }, function (ex) {
                    //console.log('ME ++++++++>>>>',ex);
                });
                return [2 /*return*/];
            });
        });
    };
    UserProfileComponent.prototype.exampleMethodParent = function ($event) {
        console.log($event);
    };
    UserProfileComponent.prototype.logout = function () {
        this.authService.logout(true);
    };
    return UserProfileComponent;
}());
export { UserProfileComponent };
